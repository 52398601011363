import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import useJwt from "./auth/jwt/useJwt";

import Home from "./Components/pages/Home";
import Login from "./Components/Login";
import Fa2 from "./Components/fa2";
import ShareCalendar from "./Components/ShareCalendar";
import LoginApple from "./Components/LoginApple";
import Account from "./Components/Account";
import Redirecting from "./Components/Redirecting";
import Paylog from "./Components/Paylog";
import ChangePassContainer from "./Components/AccountSubComp/ChangePasContainer";
import ForgetPassword from "./Components/ForgetPassword";
import ResetPassword from "./Components/ResetPassword";
import DashboardNew from "./Components/DashboardNew";
import AdminPanel from "./Components/AdminPanel/AdminPanel";
import PrivacyPage from "./Components/PrivacyPol/OutDocument.js";
import ProtectedRoute from "./Folder/ProtectedRoute";
import NewRegister from "./Components/NewRegister";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import PriceMain from "./Components/PriceCalcSubComp/PriceMain";
import SubscribeNew from "./Components/SubscribeNew";
import CallbackGoogleRedirect from "./Components/DashboardSubComponent/CallbackGoogleRedirect";
import CallbackMicrosoftRedirect from "./Components/DashboardSubComponent/CallbackMicrosoftRedirect";

const Routes = () => {
  const Jwt = useJwt();

  const isUserLoggedIn = () => {
    return (
      localStorage.getItem("userData") &&
      localStorage.getItem(Jwt.jwt.jwtConfig.storageTokenKeyName)
    );
  };

  return (
    <BrowserRouter>
      <Switch>
        <Route path='/pricingcal' component={PriceMain}></Route>
        <Route path='/admin-panel' component={AdminPanel}></Route>
        <Route exact path='/login' component={Login}></Route>
        <Route exact path='/verify-email' component={Fa2}></Route>
        <Route exact path='/register' component={NewRegister}></Route>
        <Route exact path='/privacyPolice' component={PrivacyPage}></Route>
        <Route path='/dashboard' component={DashboardNew}></Route>
        <Route exact path='/' component={Login}></Route>
        <Route
          exact
          path='/callbackGoogleRedirect'
          component={CallbackGoogleRedirect}
        ></Route>
        <Route
          exact
          path='/callbackMicrosoftRedirect'
          component={CallbackMicrosoftRedirect}
        ></Route>
        {/* <ProtectedRoute 
              path="/" 
              component={DashboardNew} 
              isUserLoggedIn={isUserLoggedIn}
            >
            </ProtectedRoute> */}
        {/* <Route 
              path="/login" 
              component={Login}>
            </Route> */}
        {/* <Route
              path="/"
              component={Login}
              isUserLoggedIn={!isUserLoggedIn}
            /> */}
        {/* <Route path="/dashboard" component={DashboardNew}></Route> */}
        {/* {(props) => < DashboardNew {...props} />} */}
        {/* <ProtectedRoute
              path="/dashboard"
              component={DashboardNew}
              isUserLoggedIn={isUserLoggedIn}
            /> */}
        <Route path='/share/calendar/:token' component={ShareCalendar}></Route>
        <Route path='/loginApple' component={LoginApple}></Route>
        <Route path='/me/account' component={Account}></Route>
        <Route exact path='/pay/plans' component={Paylog}></Route>
        <Route exact path='/subscribe' component={SubscribeNew}></Route>
        <Route path='/redirecting' component={Redirecting}></Route>
        <Route path='/changePassword' component={ChangePassContainer}></Route>
        <Route path='/forgetPassword' component={ForgetPassword}></Route>
        <Route path='/resetPassword/:token' component={ResetPassword}></Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
