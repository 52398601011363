import React from 'react';
import '../../css/popup/popup.css';
import { Link } from 'react-router-dom';

function EmailNotFound(props) {
  return props.trigger ? (
    <>
      <div className='popup'></div>
      <div
        className='container d-flex flex-column justify-content-center align-items-center'
        style={{ height: '100vh' }}
      >
        <div style={{ height: '312px' }} className='innerPopup'>
          <div className='popupLogo'>
            {/* <svg
              width='112'
              height='24'
              viewBox='0 0 112 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M0 0H110C111.105 0 112 0.895431 112 2C112 3.10457 111.105 4 110 4H0V0Z'
                fill='#FFC4AF'
              />
              <path
                d='M0 20H58C59.1046 20 60 20.8954 60 22C60 23.1046 59.1046 24 58 24H0V20Z'
                fill='#F9DCAA'
              />
            </svg> */}
            <button
              className='close-btn1'
              onClick={() => props.setTrigger(false)}
              style={{ border: 'none' }}
            >
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M7.13754 17.9935C6.84334 18.0108 6.554 17.9119 6.33053 17.7175C5.88982 17.269 5.88982 16.5447 6.33053 16.0962L15.9807 6.33372C16.4391 5.89981 17.1583 5.92393 17.5873 6.38764C17.9751 6.80698 17.9977 7.4515 17.6402 7.89757L7.93318 17.7175C7.7126 17.9091 7.4279 18.0078 7.13754 17.9935Z'
                  fill='#0E0E2C'
                />
                <path
                  d='M16.7765 17.9938C16.4784 17.9925 16.1926 17.8728 15.9809 17.6604L6.33069 7.89783C5.92239 7.41549 5.9779 6.68959 6.45469 6.2765C6.88025 5.90783 7.50785 5.90783 7.93337 6.2765L17.6404 16.039C18.0986 16.473 18.1223 17.2007 17.6933 17.6643C17.6762 17.6827 17.6586 17.7006 17.6404 17.7178C17.4027 17.9269 17.0899 18.0269 16.7765 17.9938Z'
                  fill='#0E0E2C'
                />
              </svg>
            </button>
          </div>
          <h3>Email address not found.</h3>
          <h4>Please create an account. </h4>
          <div className='redirectBtn'>
            <Link to='/register'>
              <button className='redSignBtn'>Sign up for SyncroCal</button>
            </Link>
            <Link
              to='/login'
              className='tryBtnContainer'
              onClick={() => props.setTrigger(false)}
            >
              <h5 className='tryBtn'>Try Again</h5>
            </Link>
          </div>
        </div>
      </div>
    </>
  ) : (
    ''
  );
}

export default EmailNotFound;
