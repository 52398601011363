import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import { useHistory } from "react-router-dom";
import { withRouter } from 'react-router';
import urlConstants from "../config";
import Calendar from '@toast-ui/react-calendar';
import { MenuItem } from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import googleMeet from "../css/images/GoogleMeet.svg";
import zoom from "../css/images/Zoom.svg";
import microsoftTeams from "../css/images/MicrosoftTeams.svg";
import Calendly from "../css/images/GoogleMeet.svg";

import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import ButtonGroup from '@material-ui/core/ButtonGroup';
// import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

// import Button from '@material-ui/core/ToggleButton';
import Fab from '@material-ui/core/Fab';

// import NavigationIcon from '@material-ui/icons/NavigationIcon';

// import 'tui-time-picker/dist/tui-time-picker.css';
// import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-calendar/dist/tui-calendar.css';
import myTheme from '../utils/MyTheme';

import { Dropdown } from 'react-bootstrap';

import Spinner from "./Spinner";
import Google from '../Logo/google.png';
import Outlook from '../Logo/outlook.png';
import Apple from '../Logo/apple.png';


import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';

import DatePicker from 'sassy-datepicker';
import '../css/Dashboard.css';
import DropDown from "../Logo/caratdown.png"
import Account from "../css/images/account.png"
import LogOut from "../css/images/logout.png"

import 'bootstrap/dist/css/bootstrap.css';
import Privacy from './DashboardSubComponent/Privacy';

const Story = () => {
//   ref = React.createRef();
    const cal = useRef(null);
    const history = useHistory();


    function yyyymmdd(dateIn) {
      var yyyy = dateIn.getFullYear();
      var mm = dateIn.getMonth()+1; // getMonth() is zero-based
      var dd  = dateIn.getDate();
      return String(10000*yyyy + 100*mm + dd); // Leading zeros for mm and dd
  }

    const viewModeOptions = [
      {
        title: 'Month View',
        img: './Logo/caratdown.png',
        value: 'month',
      },
      {
        title: 'Week View',
        img: './Logo/caratdown.png',
        value: 'week',
      },
      {
        title:  'Day View',
        img: './Logo/caratdown.png',
        value: 'day',
      },
    ];

    const timezonesOptions = [
      // {
      //   timezoneOffset: -300,
      //   displayLabel: 'São Paulo',
      //   tooltip: 'GMT-03:00'
      // },
      // {
      //   timezoneOffset: -800,
      //   displayLabel: 'Los Angeles',
      //   tooltip: 'GMT-08:00'
      // },
      // {
      //   timezoneOffset: -600,
      //   displayLabel: 'UY',
      //   tooltip: 'UYT (Uruguay Time)'
      // },
      {
        timezoneOffset: -300,
        displayLabel: 'São Paulo',
        tooltip: 'GMT-03:00'
      },
      // {
      //   timezoneOffset: -800,
      //   displayLabel: 'Los Angeles',
      //   tooltip: 'GMT-08:00'
      // },
      // {
      //   timezoneOffset: -600,
      //   displayLabel: 'UY',
      //   tooltip: 'UYT (Uruguay Time)'
      // },
      {
        timezoneOffset: 0,
        displayLabel: 'GMT',
        tooltip: 'GMT (GMT Standard Time)'
      }
    ];

    const [view, setView] = useState('week');
    const [dateRange, setDateRange] = useState('');
    const [date, setDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [value, setValue] = useState('Controlled');
    const [settingsSelect, setSettingsSelect] = useState('settings');
    const [open, setOpen] = useState(false);
    const [url, setUrl] = useState('');
    const [serviceType, setServiceType] = useState('');
    const [calendarList, setCalendarList] = useState([]);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [isPopupEditVisible, setIsPopupEditVisible] = useState(false);
    const [emails, setEmails] = useState([]);
    const [currentEmail, setCurrentEmail] = useState('');

    // const start = new Date();
    // const end = new Date(new Date().setMinutes(start.getMinutes() + 60));

    const dateRangePickerRef = useRef(null);





    const [currentEvent, setCurrentEvent] = useState({
      serviceId: '',
      summary: '',
      start: '',
      end: '',
      serviceType: '',
      email: '',
      color: '',
      isAllDay: false,
      category: '',
      eventType: '',
      meetingLink: {
        googleMeet: '',
        zoom: '',
        microsoftTeams: '',
        calendly: ''
      },
      state: ''
    });

    const [events, setEvents] = useState([]);
    const [eventsToSync, setEventsToSync] = useState([]);

    const [me, setMe] = useState(false);
    const [finalList, setFinalList] = useState([]);
    const [isPrivacyVisible, setIsPrivacyVisible] = useState(false);


    const [isCalendarVisible, setIsCalendarVisible] = useState(false);

    const yearString = [
      'Jan',
      'Feb',
      'Mar',
      'April',
      'May',
      'June',
      'July',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

      const [newEvent, setNewEvent] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
          // email: "teste.paulo.sc.2@gmail.com",
          serviceId: "",
          summary: "",
          description: "",
          start:{
            dateTime: "",
            timeZone: "America/Sao_Paulo"
          },
          end: {
            dateTime: "",
            timeZone: "America/Sao_Paulo"
          },
          isAllDay: "",
          category: "",
          eventType: "",
          conferenceData: {
            createRequest: {requestId: "7qxalsvy0e"}
          },
          meetingLink: {
            googleMeet: "",
            zoom: "",
            microsoftTeams: "",
            calendly: ""
          },
          state: "",
          colorId: "",
          calendarId: null,
          serviceType: "",
          location: "",
          atendees: [],

        }
      );
    

    const [updatedEvent, setUpdatedEvent] = useReducer(
      (state, newState) => ({ ...state, ...newState }),
      {
        summary: "",
        start: "",
        end: "",
        isAllDay: "",
        category: "",
        eventType: "",
        conferenceData: {
          createRequest: {requestId: "7qxalsvy0e"}
        },
        meetingLink: {
          googleMeet: "",
          zoom: "",
          microsoftTeams: "",
          calendly: ""
        },
        state: "",
        colorId: "",
        calendarId: "",
        serviceType: "",
        location: "",
        atendees: [],
        body: '',
        state: '',
        dueDateClass: '',
        isVisible: false,
        


      }
    );

    // function InputComponent({ defaultValue, inputRef, ...props }) {
    //   const [value, setValue] = React.useState(() => props.value || defaultValue);
    
    //   const handleChange = event => {
    //     setValue(event.target.value);
    //     if (props.onChange) {
    //       props.onChange(event);
    //     }
    //   };
    
    //   return (
    //     <div>
    //       <div>{value}</div>
    //       <input
    //         // className={classes.input}
    //         ref={inputRef}
    //         {...props}
    //         onChange={handleChange}
    //         value={value}
    //       />
    //     </div>
    //   );
    // }

  // const getCalInstance = useCallback(() => cal.current.getInstance(), []);
//   }

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    let data = { newEvent };
    const response = await axios.post(`${urlConstants.REACT_APP_SERVER_ROOT_URI}/calendars/events/create`, newEvent, {
      headers: { 'Access-Control-Allow-Origin': true }
    });  
    console.log(response);
    wait(1000);
    setIsPopupVisible(false);
    handleStatusChange();
  };
  const handleInput = evt => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setNewEvent({ [name]: newValue });
  };

  // const handleInputCalendar = evt => {
  //   const name = evt.target.name;
  //   const newValue = evt.target.value;
  //   setNewEvent({ [name]: newValue });
  // }

  const handleInputDate = evt => {
    const name = evt.target.name;
    
    const newValue = evt.target.value;
    setNewEvent({ [name]: newValue });
  };

  const setRenderRangeText = useCallback(() => {
    if(me) {

    // const getCalInstance = calendarInstance.current.getInstance();
      const calInstance = cal.current.calendarInst
      // cal.current.calendarInst
      if (!calInstance) {
        setDateRange('');
      }
      const view = calInstance.getViewName();
      const calDate = calInstance.getDate();
      const rangeStart = calInstance.getDateRangeStart();
      const rangeEnd = calInstance.getDateRangeEnd();
      let year = calDate.getFullYear();
      let month = calDate.getMonth() + 1;
      let date = calDate.getDate();
      let dateRangeText = '';
      let endMonth, endDate, start, end;

      switch (view) {
        case 'month':
          dateRangeText = `${yearString[month - 1]} ${year} `;
          break;
        case 'week':
          year = rangeStart.getFullYear();
          month = rangeStart.getMonth();
          date = rangeStart.getDate();
          endMonth = rangeEnd.getMonth();
          endDate = rangeEnd.getDate();

          start = `${yearString[month]} ${date < 10 ? '0' : ''}${date}`;
          end = `${yearString[endMonth]} ${
            endDate < 10 ? '0' : ''
          }${endDate}, ${year}`;

          dateRangeText = `${start} - ${end}`;
          break;
        default:
          dateRangeText = `${yearString[month - 1]} ${date}, ${year} `;
      }

      // this.setState({ dateRange: dateRangeText });
      setDateRange(dateRangeText)
    }
    
  }, []);

  useEffect(() => {
    setRenderRangeText();
  }, [view, setRenderRangeText]);


  async function fetchData() {
    try {
      // if (events == []) {
        // this.onClickSchedule = this.onClickSchedule.bind(this)
        //   this.calendarInst = this.ref.current.getInstance();
        // const addIdentity = await axios.get(`${urlConstants.REACT_APP_SERVER_ROOT_URI}/auth/me`, {
        //   withCredentials: true,
        //   headers: { 'Access-Control-Allow-Origin': true }
        // });
        const response = await axios.get(`${urlConstants.REACT_APP_SERVER_ROOT_URI}/users`, {
          withCredentials: true,
          headers: { 'Access-Control-Allow-Origin': true }
        });
        console.log(response.data)

        if (response.data.err) {
          toast.error('Account adding failed' + response.data.err);
        }

      //   this.setState({ me: response.data });
        setMe(response.data);
      // }
    } catch (err) {
      console.log(err);
    }
  }
//old component did mount
  useEffect(() => {
    
       fetchData().then(() => {
        setView(view);
        setRenderRangeText();
        handleStatusChange();
        console.log(`me: ${me}`)
       })


  }, [])
// }, [view])


//old component did update
  // useEffect(() => {
  //   const fetchData = async (prevProps, prevState) => {
  //     // if (open !== prevState.open) {
  //       const response = await axios.get(`${urlConstants.REACT_APP_SERVER_ROOT_URI}/auth/me`, {
  //         withCredentials: true,
  //       });

  //         if (response.data.err) {
  //           toast.error('Account adding failed' + response.data.err);
  //         }

  //       //   this.setState({ me: response.data });
  //         setMe(response.data);
  //       //   this.handleStatusChange();
  //       // } else if (this.state.events !== prevState.events) {
  //       //   const response = await axios.post(
  //       //     `${urlConstants.REACT_APP_SERVER_ROOT_URI}/create-events`,
  //       //     {
  //       //       events: this.state.events,
  //       //     },
  //       //     {
  //       //       withCredentials: true,
  //       //     }
  //       //   );
  //       //   if (response.data) {
  //       //     console.log(response);
  //       //   }
  //       // }
      
  //   }
  //     fetchData();
  //     handleStatusChange();

  // }, [])
// add new handler for change event colors, selected by radio buttons
  // async handleChangeColor(e) {
  //   console.log(e.target.value)
  //   this.setState({ creatingEvent: { ...this.state.creatingEvent, color: e.target.value } });
  // }

  const onInputChange = (event) => {
    // this.setState({
    //   [event.target.name]: event.target.value
    // });
    setValue(event.target.value);

  };

  const onSelectDateTime = async (e) =>  {
    console.log('Calendar clicked bind', e)

  }

  const onChangeSelect = (ev) => {
    setView(ev.target.value);
  };

  const handleStatusChange = async () => {
    if (me && me.identities) {
      console.log('status changed')
      try {
        const response = await axios.get(
          `${urlConstants.REACT_APP_SERVER_ROOT_URI}/main/calendar`,
          {
            withCredentials: true,
          }
        );
        // this.state.finalList = response.data;
        setFinalList(response.data);

        let list = response.data.map((item) => {
          return item.events.map((ele) => {
            ele = { ...ele, priority: item.priority, serviceId: item.serviceId, colorId: item.colorId };
            return ele;
          });
        });

        let result = list.reduce((acc, it) => [...acc, ...it], []);


        let timeZoneval = moment.tz.guess();

        let eventList = result.map((item) => {
          return {
            id: item.id,
            calendarId: item.serviceId,
            title: item.summary ? item.summary : item.subject,
            summary: item.summary ? item.summary : item.subject,
            start:
              item.start?.date || item?.isAllDay
                ? moment(item.start?.date || item.start.dateTime)
                    .tz(timeZoneval)
                    .toISOString()
                : moment.utc(item.start.dateTime).toISOString(),
            end:
              item.end?.date || item?.isAllDay
                ? moment(item.end?.date || item.end.dateTime)
                    .tz(timeZoneval)
                    .toISOString()
                : moment.utc(item.end.dateTime).toISOString(),
            timeZone: timeZoneval,
            organizer: item.organizer.email,
              // || item.organizer.emailAddress.address,
            category: 'time',
            colorId: item.colorId,
          };
        });

        eventList = eventList.reduce((acc, current) => {
          const x = acc.find((item) => {
            return item.start === current.start && item.end === current.end;
          });

          if (!x) {
            if (
              // current.title !== 'Busy' &&
              current.title !== 'Busy' &&
              current.id?.split('email').length !== 2
            ) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          } else {
            return acc;
          }
        }, []);

        // this.setState({ events: eventList });
        console.log(eventList)
        setEvents(eventList);
        // this.setState({ events: eventList[response.data['serviceId']] });
      } catch (err) {
        console.log(err);
      }
    }
  }
  useEffect(() => {
    handleStatusChange();
  }, [me, open])

  const handleDateChange = () => {
    cal.current.calendarInst.setDate(date);
    // setDate(date)
    setRenderRangeText();
  }

  // const handleEvents = (event) => {
  //   events.push(event);
  //   // state.events.concat(state.currentEvent)
  // }

  // async handleSubmit(e) {
  //   e.preventDefault();

  //   this.props.history.push({
  //     pathName: `/calendar/${e.target.className}`,
  //     state: {
  //       details: e.target.id,
  //     },
  //   });
  // }

  const logoutHandler = (e) => {
    e.preventDefault();
    console.log(e)

    // localStorage.pxoveItem('accessToken');
    // localStorage.pxoveItem('refreshToken');
    // localStorage.pxoveItem('userData');

    // history.push('/login');
    window.location = '/login';
  }

  const onAfterRenderSchedule = (res) => {
    // console.group('onAfterRenderSchedule');
    // // console.log('Schedule Info : ', res.schedule);
    // console.groupEnd();
  }


  


  // const onChangeEmail = (ev) => {
  //   // this.setState({ serviceId: ev.target.value });
  //   this.setState({currentEvent :{
  //     serviceId: ev.target.value
  //     }})
    
  // }
  const handlePopupClose = () => {
    // this.setState({ isPopupVisible: false });
    setIsPopupVisible(false)
    setNewEvent({ start: {
      dateTime: ""
    }, end: {
      dateTime: ""
    }, summary: "", serviceId: "" });
    
  }

  const handleEmails = (e) => {
    // this.setState({ currentEvents: e });
    // setEmail(e)
    setCurrentEvent({
      ...currentEvent,
      serviceId: e
    })
  }

  const onClickDayname = (res) => {
    console.groupEnd();
  }

  const onClickNavi = (event) => {
    if (me) {
      if (event.target.tagName === 'BUTTON') {
        // this.onChange = this.onChange.bind(this)
        const { target } = event;
        let action = target.dataset
          ? target.dataset.action
          : target.getAttribute('data-action');
        action = action.replace('move-', '');

        // this.calendarInst[action]();
        cal.current.calendarInst[action]();
        

        setRenderRangeText();

        // this.setState({ isPopupVisible: true });
        // this.setState({ date: new Date() });
        // setDate(new Date())
        console.log(`this is the date set ${date}`)
      }

    }
  }

  // onClickSchedule(res) {
  //   console.groupEnd();
  // }
  // const onClickSchedule = async (e) => {
  //   // const state = this.calendarInst
  //   // this.onChange = this.onChange.bind(this)
  //   const { calendarId, id } = e.schedule;
  //   const el = cal.current.calendarInst.getElement(id, calendarId);

  //   console.log(e, el.getBoundingClientRect());
  //   console.log(e)
  //   console.log(e.schedule)
  //   // console.log(this)
  //   // console.log(e)
  //   // this.calendarInst = this.ref.current.getInstance(id, calendarId);
  //   // this.setState({ isPopupVisible: true });
  //   console.log('The event was clicked')
  //   // const willModify = console.log(`title: ${'title'}\n when: ${(new Date(e.schedule.start))} \nWill you update schedule?`);

  //   // if (willModify) { // step1-1. open edit popup */ 
  //   //     e.schedule.title = prompt('Schedule', e.schedule.title);
  //   //     this.calendarInst.updateSchedule(e.schedule.id, e.schedule.calendarId, e.schedule);
  //   // }

  //   // isPopupVisible(true);
  //       // const el = this.calendarInst.getElement(id, calendarId);
  //   // return(
  //   //   <div className='my-hidden-modal'>This is my div</div>    
  //   // )
  //   // return(
  //   //   ShowMyModal()
  //   // )

  //     // console.log(e, this.calendarInst.getBoundingClientRect());
    
  // }
  const onClickSchedule = useCallback((e) => {
    if (me) {
      const { calendarId, id } = e.schedule;
      const el = cal.current.calendarInst.getElement(id, calendarId);

      console.log(e, el.getBoundingClientRect());
    }
  }, []);

  const onClickTimezonesCollapseBtn = (timezonesCollapsed) => {
    if (me) {
      console.groupEnd();

      const theme = {};
      if (timezonesCollapsed) {
        theme['week.daygridLeft.width'] = '100px';
        theme['week.timegridLeft.width'] = '100px';
        theme['week.timegridLeft.margin-left'] = '30px';

      }
      //  else {
      //   theme['week.daygridLeft.width'] = '200px';
      //   theme['week.timegridLeft.width'] = '200px';
      // }

      // this.calendarInst.setTheme(theme);
      cal.current.calendarInst.setTheme(theme)
    }
  }

  let start;
  let end;

  const onBeforeCreateSchedule = useCallback((event) => {
    if (me) {
      console.log(new Date(event.start).toISOString(), new Date(event.end).toISOString());
      start = new Date(event.start).toISOString();
      end = new Date(event.end).toISOString();
      newEvent.start.dateTime = start;
      newEvent.end.dateTime = end;
      // const newSchedule = {
      //   id: String(Math.random()),
      //   title: event.title,
      //   calendarId: event.calendarId,
      //   category: event.isAllDay ? "allday" : "time",
      //   attendees: event.attendees,
      //   isVisible: true,
      //   start: event.start,
      //   end: event.end,
      //   isAllDay: event.isAllDay,
      //   dueDateClass: "",
      //   location: event.location,
      //   // raw: {
      //   //   class: event.raw["class"]
      //   // },
      //   state: event.state,
      //   body: event.body,
      // };
      //   // cal.current.calendarInst.createSchedules([newSchedule]);
      //   // setNewEvent(newSchedule)
      //   // events.push(newSchedule)

      // // const event = scheduleData as IEventWithCreationPopup;
      // // const { calendar } = scheduleData;
      // // const {newEvent} = this.state.currentEvent;
      // // this.state.currentEvent = scheduleData;
      // // const guideElements = event.guide.clearGuideElements;
      setIsPopupVisible(true)
      // setNewEvent(newSchedule)
    }

  }, [me])


  // const handleCreateSchedule = async (event) => {
  //   console.log(event)
  //   console.log(`current event target : ${newEvent}`)
  //   console.log(`current event target title : ${newEvent.title}`)
  //   console.log(`current event target start : ${newEvent.start.toString()}`)
  //   console.log(`current event target end : ${newEvent.end.toString()}`)



  //   const newSchedule = {
  //     ...newEvent,
  //     id: newEvent.id ? newEvent.id : String(Math.random()),
  //     title: newEvent.title ? newEvent.title : '',
  //     calendarId: newEvent.calendarId,
  //     category: newEvent.isAllDay ? "allday" : "time",
  //     attendees: newEvent.attendees ? newEvent.attendees : [],
  //     isVisible: true,
  //     start: newEvent.start ? newEvent.start.toString() : '',
  //     end: newEvent.end ? newEvent.end.toString() : '',
  //     isAllDay: newEvent.isAllDay ? newEvent.isAllDay : false,
  //     dueDateClass: "",
  //     location: newEvent.location ? newEvent.location : "",
  //     // raw: {
  //     //   class: event.raw["class"]
  //     // },
  //     state: newEvent.state ? newEvent.state : "Busy",
  //     body: newEvent.body ? newEvent.body : "",
  //   };
  //     await cal.current.calendarInst.createSchedules(newSchedule);
  //     events.push(newSchedule)
  //     setIsPopupVisible(false)
  //     // this.state.events.concat(schedule)

  // }

  // const onBeforeUpdateSchedule = useCallback((event) => {
  //   if (me) {
  //     console.log(event)
  //     const { schedule, changes } = event;

  //     if (changes) {
  //       // call api
  //       const result = true;
  //       if (result) {
  //         return cal.current.updateSchedule(schedule, changes);
  //       }
  //     }
  
  //     setIsPopupVisible(false)
  //     setNewEvent(event);
  //     // const { changes } = event;

      
  //     // console.log(this.ref)
  //     // cal.current.calendarInst.updateSchedule(schedule.id, schedule.calendarId, changes);
  //   }
  // }, [me])
  const onBeforeUpdateSchedule = async (event) => {
    if (me) {
      console.log(event)
      const { schedule, changes } = event;

      const newSchedule = {
        id: schedule.id,
        summary: schedule.summary,
        calendarId: schedule.calendarId,
        category: schedule.isAllDay ? "allday" : "time",
        attendees: schedule.attendees,
        isVisible: true,
        start: schedule.start,
        end: schedule.end,
        isAllDay: schedule.isAllDay,
        dueDateClass: "",
        location: schedule.location,
        // raw: {
        //   class: event.raw["class"]
        // },
        state: schedule.state,
        body: schedule.body,
      };
      setNewEvent(newSchedule)
      setIsPopupVisible(true)
      // handleUpdateSchedule(event)


      if (changes) {
        const updateSchedule = {
          // id: changes.id ? changes.id : schedule.id,
          summary: changes.summary ? changes.summary : schedule.summary,
          calendarId: changes.calendarId ? changes.calendarId : schedule.calendarId,
          category:  schedule.isAllDay ? "allday" : "time",
          attendees: changes.atendees ? changes.atendees : schedule.attendees,
          isVisible: true,
          start: changes.start ? changes.start : schedule.start,
          end: changes.end ? changes.end : schedule.end,
          isAllDay: changes.isAllDay? changes.isAllDay : schedule.isAllDay,
          dueDateClass: "",
          location: changes.location? changes.location : schedule.location,
          // raw: {
          //   class: event.raw["class"]
          // },
          state: changes.state ? changes.state : schedule.state,
          body: changes.body? changes.body : schedule.body,
        };
        await setUpdatedEvent(updateSchedule);

        handleUpdateSchedule(schedule, updateSchedule)


        // call api
        // const result = true;
        // if (result) {
        //   return cal.current.calendarInst.updateSchedule(schedule, changes);
        // }
          // return cal.current.calendarInst.updateSchedule(schedule, updateSchedule);
      }
  
      // const { changes } = event;

      
      // console.log(this.ref)
      // cal.current.calendarInst.updateSchedule(schedule.id, schedule.calendarId, changes);
    }
  }

  async function handleUpdateSchedule(originalEvent, updateEvent) {
    // call api
    const result = true;

    if (result) {
      const { schedule } = newEvent;
      const logChanges = originalEvent;
      console.log(`This is original event : ${originalEvent}`)

      // way 1: library not support
      // update api fail with attendees
      // childRef.current.updateSchedule(schedule, updateEvent)

      // way 2: stupid
      // await cal.current.calendarInst.deleteSchedule(schedule);

      const updatedEventVar = {
        ...updatedEvent,
        summary: updatedEvent.summary,
        calendarId: updatedEvent.calendarId,
        category: updatedEvent.isAllDay ? "allday" : "time",
        attendees: updatedEvent.attendees,
        isVisible: true,
        start: updatedEvent.start,
        end: updatedEvent.end,

        isAllDay: updatedEvent.isAllDay,
        dueDateClass: "",
        location: updatedEvent.location,
        // raw: {
        //   class: event.raw["class"]
        // },
        state: updatedEvent.state,
        body: updatedEvent.body
      };
      

      // await cal.current.calendarInst.createSchedule(newSchedule);
      // cal.current.calendarInst.updateSchedule(originalEvent, updatedEventVar);
      cal.current.calendarInst.updateSchedule(originalEvent, updatedEventVar);

      setIsPopupVisible(false);
    }
  }

  const onBeforeDeleteSchedule = useCallback((res) => {
    if (me) {
      console.log('Schedule Info : ', res);
      const { id, calendarId } = res.schedule;
      // getCalInstance().deleteSchedule(id, calendarId);
      cal.current.calendarInst.deleteSchedule(id, calendarId);
      console.log('Event deleted')
    }
  },[me])
  // const onChangeEvent = (e) => {
  //   console.log(e.target.name)
  //   console.log(e.target.value)

  //   this.setState({[e.target.name]: e.target.value });
  // }

  const onChangeHandler = (dateVal) => {
    if (me) {// this.setState({ date: dateVal }, () => {
    //   this.handleDateChange();
    // });
      setDate(dateVal);
      handleDateChange();
    }
  }

  const settingsHandler = (e) => {
    if (me) {
      e.preventDefault();
      setServiceType(e.currentTarget.id);
      // this.setState({ serviceType: e.currentTarget.id });
      setOpen(true);
    }
    // this.setState({ open: true });
  }

  const updateOpen = () => {
    if (me) {// this.setState({
    //   open: !this.state.open,
    // });
      setOpen(!open)
    }
  };

  const accountHandler = () => {
    history.push('/me/account');
  };

  const onChangeSettingsSelect = async (ev) => {
    setSettingsSelect(ev.target.value);
    switch (ev.target.value) {
      case 'logOut':
        await logoutHandler();
        break;
      case 'myAccount':
        await accountHandler();
        break;
      // case 'settings':
      //   break;
      default:
        break;
    }
  }

  const pushToDashboard = () => {
    history.push('/dashboard');
  };
  
  const checkStatus = async (toastId) => {
    if (me.identities) {
      const response = await axios.post(
        `${urlConstants.REACT_APP_SERVER_ROOT_URI}/synced`,
          {
          withCredentials: true,
          // 'Access-Control-Allow-Origin': true
        }
      ).then(async(res) => {
        console.log(res)
        if (res.data.status == 200) {
          // toast.dismiss();
          // toast.success("Events are now sync!");
          // toast.success('Events are now sync!', {
          //   id: toastId
          // })
          // toast.update(toastId, {render: "Events are now sync!", type: "success", isLoading: false});
          toast.dismiss(toastId);
          await wait(1000)
          // toast.dismiss(toastId);
          const toastSuccess = toast.success("Events are now synced!");
          await wait(10000)
          toast.dismiss(toastSuccess);


          // setNotFinished(false);
        } 
        if (res.data.status == 202) {
          // toast.loading("Back end received the request. Events being synced. We will let you know once it is finished!");
          // toast.success("Back end received the request. Events being synced. We will let you know once it is finished!");
          await wait(10000);
          checkStatus();
        }
      }).catch((err) => {
        console.log(err)
        // toast.error('Sync events function failed' + err);

      })
    }
    // if (notFinished) {
    //   await wait(1000);
    //   checkStatus();
    // }
  }

  const wait = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const syncEvents = async () => {
     
      if (me.identities) {
        console.log(events)
        // toast.loading("Events being synced. We will let you know once it is finished!");

        const response = await axios.post(
          `${urlConstants.REACT_APP_SERVER_ROOT_URI}/calendars/sync`,
          // JSON.stringify({
            {
              events: events
            },
            // {
            //   headers: {
            //     'Access-Control-Allow-Origin': `${urlConstants.REACT_APP_SERVER_ROOT_URI}`,
            //   }
            // },
            {
            withCredentials: true,
            // 'Access-Control-Allow-Origin': true
          })
        // ).then(async (res) => {
        //   console.log(res)
        //   const toastId = toast.loading("Your request is being processed. Please note that other actions may be temporarily unavailable while the update is in progress. We appreciate your patience and will notify you as soon as the process is complete. Thank you!");

        //   // if (res.data.status == 202) {
        //   //   // toast.loading("Back end received the request. Events being synced. We will let you know once it is finished!");
        //   //   // setNotFinished(true);
            
        //   //   await wait(10000).then(() => checkStatus(toastId));
        //   //   // setTimeout(() => {
        //   //   //   checkStatus();
        //   //   // }
        //   //   // , 5000);


        //   // }
        // }).catch((err) => {
        //   console.log(err)
        //   // toast.error('Sync events function failed' + err);

        // })

      }
  };

  const dateNow = new Date(); // Creating a new date object with the current date and time
  const year = dateNow.getFullYear(); // Getting current year from the created Date object
  const monthWithOffset = dateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
  const month = // Setting current Month number from current Date object
    monthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 to adjust for date input.
      ? `0${monthWithOffset}`
      : monthWithOffset;
  const date1 =
    dateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
      ? `0${dateNow.getUTCDate()}`
      : dateNow.getUTCDate();

  const materialDateInput = `${year}-${month}-${date1}`; // combining to format for defaultValue or value attribute of material <TextField>


//   const updateEvents = async () => {
     
//     if (me) {
//       // axios.post(`${urlConstants.REACT_APP_SERVER_ROOT_URI}/create-events`, async (req, res, next) => {
//       //   // const events = await cal.current.calendarInst.getAllSchedules();
//       //   req.header('Access-Control-Allow-Origin',`${urlConstants.REACT_APP_SERVER_ROOT_URI}`);
//       //   req.send({
//       //     events: events
//       //   },
//       //   {
//       //     withCredentials: true,
//       //   }
//       //   )
//       // })
//       console.log(events)
//       // toast.loading("Events being synced. We will let you know once it is finished!");

//       const response = await axios.post(
//         `${urlConstants.REACT_APP_SERVER_ROOT_URI}/update`,
//         // JSON.stringify({
//           {
//             events: events
//           },
//           // {
//           //   headers: {
//           //     'Access-Control-Allow-Origin': `${urlConstants.REACT_APP_SERVER_ROOT_URI}`,
//           //   }
//           // },
//           {
//           withCredentials: true,
//           // 'Access-Control-Allow-Origin': true
//         }
//       ).then((res) => {
//         console.log(res)
//         // if (res.status == 200) {
//         //   toast.dismiss();
//         //   toast.success("Events are now sync!");
//         // } 
//         if (res.status == 202) {
//           // toast.loading("Back end received the request. Events being synced. We will let you know once it is finished!");
//           toast.success("Back end received the request. Events being synced. We will let you know once it is finished!");
//           // setNotFinished(true);
          
//           wait(10000).then(() => checkStatus());
//           // setTimeout(() => {
//           //   checkStatus();
//           // }
//           // , 5000);


//         }
//       }).catch((err) => {
//         console.log(err)
//         // toast.error('Sync events function failed' + err);

//       })

//     }
// };



    
    const popupVisible = isPopupVisible
    // const { classes } = this.props;

    return (
      <>
      {me ? (

        <>
        {events == [] ? <Spinner /> : null}
          <div className='App'>
            <div className='header' id='header'>
              <div className='header_main'>
                <div className='header_comp1'>
                  <div onClick={pushToDashboard} className='logo'>
                    <svg
                      width='158'
                      height='42'
                      viewBox='0 0 158 42'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g
                        clipPath='url(#clip0_1724_2573)'
                        filter='url(#filter0_d_1724_2573)'
                      >
                        <path
                          d='M119.767 4.77591C121.698 4.77591 123.455 5.87126 124.377 7.76987C124.766 8.57312 125.588 9.06968 126.467 9.06968H126.683C128.44 9.06968 129.564 7.18568 128.772 5.60838C127.245 2.57061 124.089 0.482147 119.738 0.482147C113.024 0.482147 108.831 5.38931 108.831 13.4365C108.831 21.6005 113.528 25.865 119.681 25.865C123.873 25.865 127.014 23.7327 128.7 20.2714C129.463 18.7087 128.311 16.8686 126.582 16.8686C125.645 16.8686 124.795 17.4235 124.435 18.2998C123.571 20.3737 121.943 21.5274 119.551 21.5274C115.733 21.5274 113.831 18.4167 113.831 13.1444C113.802 7.95973 115.704 4.77591 119.767 4.77591Z'
                          fill='white' />
                        <path
                          d='M138.958 6.96661C135.198 6.96661 133.08 8.12038 132.071 9.98978C131.293 11.421 132.331 13.159 133.944 13.159H134.06C134.866 13.159 135.587 12.677 135.961 11.9468C136.408 11.0705 137.258 10.5448 138.756 10.5448C140.557 10.5448 141.681 11.2312 141.681 12.3703C141.681 13.5825 141.047 14.0499 138.756 14.3712C134.132 15.0138 130.775 15.5834 130.775 20.6658C130.775 23.5283 132.604 25.4999 136.566 25.4999C138.901 25.4999 140.73 24.682 141.825 23.4553V23.4699C141.883 24.3461 142.618 25.0326 143.497 25.0326H144.707C145.629 25.0326 146.378 24.2731 146.378 23.3384V13.9038C146.364 9.46401 144.462 6.96661 138.958 6.96661ZM141.739 18.3436C141.71 20.4175 139.938 22.17 137.575 22.17C136.134 22.17 135.356 21.5274 135.356 20.1984C135.356 18.6941 136.206 17.9493 138.814 17.4381C140.514 17.1168 141.321 16.8686 141.782 16.4012L141.739 18.3436Z'
                          fill='white' />
                        <path
                          d='M151.709 0.978699C150.441 0.978699 149.418 2.01563 149.418 3.30084V22.7104C149.418 23.9956 150.441 25.0326 151.709 25.0326C152.977 25.0326 154 23.9956 154 22.7104V3.31544C154 2.03023 152.977 0.978699 151.709 0.978699Z'
                          fill='white' />
                        <path
                          d='M19.1858 8.39787C18.2637 8.39787 17.4425 7.81368 17.0823 6.95201C16.4628 5.49154 15.0652 4.77591 12.7599 4.77591C10.008 4.77591 8.55285 5.78364 8.55285 7.5654C8.55285 9.06968 9.57581 9.93136 12.198 10.574C14.8491 11.2166 17.4281 11.8592 19.157 12.6478C21.2029 13.5825 22.831 15.043 22.831 18.4167C22.831 23.6451 18.9409 25.9819 13.4659 25.9819C8.98508 25.9819 5.55602 24.4192 4.18727 21.2354C3.53892 19.7165 4.64833 18.0223 6.27641 18.0223H6.49253C7.40022 18.0223 8.22147 18.5627 8.56726 19.4098C9.23002 21.0163 11.0166 21.9656 13.5091 21.9656C16.4051 21.9656 18.0764 20.7096 18.0764 18.5919C18.0764 17.1899 17.3704 16.226 14.4312 15.5834C11.5353 14.9554 9.66225 14.5318 7.99094 13.7724C5.48398 12.6332 4.07201 10.9099 4.07201 7.90131C4.07201 3.63675 6.93917 0.584381 12.6591 0.584381C17.0247 0.584381 19.9927 2.35154 21.3182 5.09722C22.053 6.6161 20.9436 8.39787 19.2723 8.39787H19.1858V8.39787Z'
                          fill='white' />
                        <path
                          d='M35.654 22.8419C35.02 24.4192 33.1038 25.3539 30.957 25.3539C27.3118 25.3539 24.5887 22.8419 24.5887 18.9716V9.72689C24.5887 8.51471 25.5541 7.53619 26.7499 7.53619H27.0381C28.2339 7.53619 29.1993 8.51471 29.1993 9.72689V18.2998C29.1993 20.4905 30.1934 21.6005 32.1385 21.6005C34.2276 21.6005 35.6684 20.1984 35.6684 18.2998V9.72689C35.6684 8.51471 36.6337 7.53619 37.8296 7.53619H38.1177C39.3136 7.53619 40.2789 8.51471 40.2789 9.72689V25.646C40.2789 30.6261 36.749 33.5325 32.2825 33.5325C28.3492 33.5325 25.9143 31.8383 25.0066 28.9758C24.5599 27.5738 25.6261 26.1571 27.0669 26.1571C27.9602 26.1571 28.7958 26.6975 29.1128 27.5592C29.5595 28.8006 30.6977 29.5308 32.4986 29.5308C34.1556 29.5308 35.6828 28.0557 35.6828 25.7336V22.8419H35.654Z'
                          fill='white' />
                        <path
                          d='M56.07 25.164C54.7877 25.164 53.7503 24.1125 53.7503 22.8127V14.488C53.7503 11.7715 52.9723 11.0121 50.7823 11.0121C48.3762 11.0121 47.2091 12.3703 47.2091 15.0576V22.8273C47.2091 24.1125 46.1862 25.1494 44.9183 25.1494C43.6504 25.1494 42.6274 24.1125 42.6274 22.8273V9.7561C42.6274 8.52931 43.6072 7.5362 44.8174 7.5362C46.0277 7.5362 47.0074 8.52931 47.0074 9.7561V10.1212C48.0736 8.14959 49.8314 7.06885 52.6265 7.06885C55.9547 7.06885 58.3896 9.1135 58.3896 12.9399V22.8273C58.3896 24.1125 57.3523 25.164 56.07 25.164Z'
                          fill='white' />
                        <path
                          d='M73.4746 18.7964C75.1892 18.7964 76.2409 20.7242 75.29 22.1701C73.878 24.3315 71.4143 25.6314 68.5183 25.6314C63.7781 25.6314 60.522 22.4475 60.522 16.1383C60.522 9.82913 63.7781 7.06885 68.648 7.06885C71.8033 7.06885 74.2526 8.33945 75.5493 10.5301C76.4138 11.9906 75.3765 13.8454 73.6907 13.8454H73.4602C72.639 13.8454 71.9042 13.3635 71.5296 12.6186C70.9821 11.5379 69.9159 10.9391 68.6192 10.9391C66.6741 10.9391 65.0893 12.1951 65.0893 16.0215C65.0893 19.8625 66.6741 21.7173 68.4895 21.7173C69.8583 21.7173 70.9965 21.1185 71.544 20.0086C71.9042 19.2783 72.639 18.7964 73.4746 18.7964Z'
                          fill='white' />
                        <path
                          d='M78.1283 22.8419V9.65387C78.1283 8.4855 79.0648 7.5362 80.2174 7.5362H80.3615C81.5141 7.5362 82.4506 8.4855 82.4506 9.65387C83.9635 7.28792 85.2602 7.34634 86.7154 7.06885H86.9603C87.6519 7.06885 88.1994 7.62382 88.1994 8.32485V10.4717C88.1994 11.1728 87.6375 11.7277 86.9459 11.7277H86.9315C84.1075 11.7277 82.7244 13.159 82.7244 15.9923V22.8419C82.7244 24.1271 81.7014 25.164 80.4336 25.164C79.1513 25.164 78.1283 24.1271 78.1283 22.8419Z'
                          fill='white' />
                        <path
                          d='M88.4587 16.3574C88.4587 10.7638 91.8878 7.06885 97.406 7.06885C102.852 7.06885 106.238 10.72 106.238 16.3574C106.238 21.951 102.809 25.646 97.406 25.646C91.7869 25.6314 88.4587 21.8341 88.4587 16.3574ZM101.613 16.3574C101.613 12.7793 100.201 11.0121 97.406 11.0121C94.6109 11.0121 93.1989 12.7647 93.1989 16.3574C93.1989 19.9501 94.6109 21.7319 97.406 21.7319C100.201 21.7319 101.613 19.9355 101.613 16.3574Z'
                          fill='white' />
                      </g>
                      <defs>
                        <filter
                          id='filter0_d_1724_2573'
                          x='0'
                          y='0.482147'
                          width='158'
                          height='41.0357'
                          filterUnits='userSpaceOnUse'
                          colorInterpolationFilters='sRGB'
                        >
                          <feFlood floodOpacity='0' result='BackgroundImageFix' />
                          <feColorMatrix
                            in='SourceAlpha'
                            type='matrix'
                            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0'
                            result='hardAlpha' />
                          <feOffset dy='4' />
                          <feGaussianBlur stdDeviation='2' />
                          <feComposite in2='hardAlpha' operator='out' />
                          <feColorMatrix
                            type='matrix'
                            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
                          <feBlend
                            mode='normal'
                            in2='BackgroundImageFix'
                            result='effect1_dropShadow_1724_2573' />
                          <feBlend
                            mode='normal'
                            in='SourceGraphic'
                            in2='effect1_dropShadow_1724_2573'
                            result='shape' />
                        </filter>
                        <clipPath id='clip0_1724_2573'>
                          <rect
                            width='150'
                            height='33.0357'
                            fill='white'
                            transform='translate(4 0.482147)' />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className='header_comp1_inner'>
                    <div className='render-range'>{dateRange}</div>

                    <div className='cw-btn'>
                      <button
                        type='button'
                        data-action='move-prev'
                        className='arrow-left'
                        onClick={onClickNavi}
                      >
                        <svg
                          width='16'
                          height='16'
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M9.5 3L4.5 7.71698L9.5 13'
                            stroke='white'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round' />
                        </svg>
                      </button>

                      <button
                        type='button'
                        className='today-btn'
                        data-action='move-today'
                        onClick={onClickNavi}
                      >
                        {view === undefined
                          ? 'Current Week'
                          : view === 'day'
                            ? 'Today'
                            : `Current ${view.charAt(0).toUpperCase() + view.slice(1)}`}
                      </button>

                      <button
                        type='button'
                        data-action='move-next'
                        className='arrow-right'
                        onClick={onClickNavi}
                      >
                        <svg
                          width='16'
                          height='16'
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M6.5 13L11.5 8.28302L6.5 3'
                            stroke='white'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round' />
                        </svg>
                      </button>
                    </div>
                    <div className='select-cont'>
                      <div className='select-label'>
                        <select
                          value={view}
                          onChange={onChangeSelect}
                          className='view-select-cust'
                        >
                          {viewModeOptions.map((option, index) => (
                            <option
                              value={option.value}
                              key={index}
                              className='view-option-cust'
                            >
                              {option.title}
                            </option>
                          ))}
                        </select>
                        <img src={DropDown} alt='cartdown' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='frames'>
                  <svg
                    width='204'
                    height='96'
                    viewBox='0 0 204 96'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <g opacity='0.25' clipPath='url(#clip0_1568_2549)'>
                      <path
                        d='M93.559 59.6236L110.494 -12.3285L108.501 -12.8581L91.5661 59.094C85.5041 58.0592 79.5222 61.7818 77.9072 67.8596C76.2022 74.276 80.0217 80.8591 86.4389 82.5643C92.8561 84.2696 99.4396 80.4508 101.145 74.0343C102.785 67.942 99.4106 61.6905 93.559 59.6236Z'
                        fill='white' />
                      <path
                        d='M177.108 27.764C173.102 26.6996 169.014 27.7895 166.121 30.3275L121.096 -16.1336L119.309 -15.0297L164.684 31.8016C163.639 33.0815 162.852 34.6003 162.402 36.2941C160.697 42.7106 164.516 49.2937 170.933 50.9989C177.351 52.7041 183.934 48.8853 185.639 42.4688C187.344 36.0523 183.525 29.4692 177.108 27.764Z'
                        fill='white' />
                      <path
                        d='M170.766 -1.52718C174.103 4.22358 181.457 6.17768 187.208 2.84174C192.959 -0.494195 194.913 -7.84725 191.576 -13.598C188.24 -19.3488 180.886 -21.3029 175.135 -17.9669C172.31 -16.3282 170.426 -13.7354 169.588 -10.822L133.097 -18.8329L132.568 -16.8402L169.198 -8.79222C168.957 -6.36015 169.454 -3.81714 170.766 -1.52718Z'
                        fill='white' />
                      <path
                        d='M138.024 58.98L116.969 -17.555L115.203 -16.2108L136.036 59.4757C135.327 59.7353 134.627 60.0401 133.946 60.4351C128.195 63.7711 126.241 71.1241 129.578 76.8749C132.915 82.6256 140.268 84.5797 146.02 81.2438C151.771 77.9079 153.725 70.5548 150.388 64.8041C147.792 60.3596 142.823 58.1856 138.024 58.98Z'
                        fill='white' />
                      <path
                        d='M97.2984 -14.2963L52.5082 36.2563C48.7517 33.21 43.3383 32.625 38.8989 35.2001C33.1477 38.536 31.1939 45.8891 34.5304 51.6398C37.867 57.3906 45.2209 59.3447 50.972 56.0087C56.7232 52.6728 58.677 45.3197 55.3405 39.569C54.9453 38.888 54.4892 38.2761 54.0025 37.6987L98.7622 -12.8193L97.2984 -14.2963Z'
                        fill='white' />
                      <path
                        d='M82.6002 -12.4319L83.2649 -14.7727L42.3606 -2.5586C41.0888 -6.20333 38.0815 -9.17851 34.0757 -10.2429C27.6585 -11.9481 21.0697 -8.10944 19.3647 -1.69296C17.6597 4.72352 21.4792 11.3066 27.8964 13.0118C34.3136 14.717 40.8971 10.8983 42.6021 4.48178C43.0522 2.78799 43.1224 1.07859 42.8513 -0.550838L82.6002 -12.4319Z'
                        fill='white' />
                    </g>
                    <defs>
                      <clipPath id='clip0_1568_2549'>
                        <rect
                          width='172.039'
                          height='123.463'
                          fill='white'
                          transform='translate(39.8853 -63.0527) rotate(18.5054)' />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className='sync-btn-cont'>
                  <button
                    className='sync-btn'
                    type='button'
                    onClick={syncEvents}
                  >
                    Sync
                  </button>
                </div>
                {/* <div className='acc-btn-cont'> */}

                {/* <div className='select-label'>
                        <select
                          value={view}
                          onChange={onChangeSelect}
                          className='view-select-cust'
                        >
                            <option
                              value={'myAccount'}
                              key={1}
                              className='view-option-cust'
                              onClick={logoutHandler}
                            >
                              Log Out
                            </option>
                                                        <option
                              value={'myAccount'}
                              key={1}
                              className='view-option-cust'
                              onClick={logoutHandler}
                            >
                              Log Out
                            </option>
                          
                        </select>
                        <img src={DropDown} alt='cartdown' />
                </div> */}
                  <Dropdown className='ddownsettings' drop='down'>
                    <Dropdown.Toggle
                      variant='success'
                      className='dtoggleSettings'
                      id='dropdown-basic-button'
                    >
                      <h1>Settings</h1>
                      <img src={DropDown} alt='cartdown' style={{paddingLeft: '9px'}} />

                    </Dropdown.Toggle>
                    <Dropdown.Menu className='dropdownMenuSettings'>
                        <Dropdown.Item 
                          value='myAccount'
                          key={1}
                          className='view-option-cust'
                          onClick={accountHandler}
                          style={{ justifyContent: 'space-between' } }

                        >
                         <img src={Account} alt='cartdown' />

                          My Account
                        </Dropdown.Item>
                        <Dropdown.Item 
                          value='logOut'
                          key={2}
                          className='view-option-cust'
                          onClick={logoutHandler}
                          style={{ justifyContent: 'space-between' } }

                        >
                          <img src={LogOut} alt='cartdown' />

                          Log Out
                        </Dropdown.Item>
                      
                      {/* <Dropdown.Item
                        className='div'
                        id='div'
                        href='https://accounts.google.com/o/oauth2/v2/auth?prompt=consent&access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.events%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.acls%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.calendars%20&response_type=code&client_id=1081956027778-lar61d4g87ibrqplapgc2vm40lr4g0fn.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Ftestbackend.syncrocal.com%2Fauth%2Fgoogle'
                      > */}
                        {/* href='https://accounts.google.com/o/oauth2/v2/auth?prompt=consent&access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.events%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar&response_type=code&client_id=1081956027778-lar61d4g87ibrqplapgc2vm40lr4g0fn.apps.googleusercontent.com&redirect_uri=http%3A%2F%2Flocalhost%3A4000%2Fauth%2Fgoogle' */}
                        {/* " + ${urlConstants.REACT_APP_SERVER_ROOT_URI} + " */}


                        {/* https://login.microsoftonline.com/common/oauth2/v2.0/authorize?&scope=Calendars.ReadWrite email offline_access openid profile User.Read&response_type=code&response_mode=query&state=developer&redirect_uri=http://localhost:4000/auth/callback&client_id=fedc0551-d436-457d-af8d-cb870433b0ad */}

                     
                    </Dropdown.Menu>
                  </Dropdown>
                  {/* <select
                    value={settingsSelect}
                    onChange={onChangeSettingsSelect}
                    className='acc-btn'
                  >
                    {settingsOptions.map((option, index) => (
                      <option 
                        value={option.value}
                        key={index}
                        className='view-option-cust'
                      >
                        {option.title}
                      </option>
                    ))}
                  </select>
                  Settings
                  <img src={DropDown} alt='cartdown' />
                   */}
                  {/* <button
                    className='account-btn'
                    type='button'
                    onClick={accountHandler}
                  >
                    Settings
                  </button> */}
                {/* </div> */}
              </div>
            </div>

            <div className='body-section'>
              <div className='body-section-left'>
                <svg
                  className='dashDsgn'
                  width='112'
                  height='24'
                  viewBox='0 0 112 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M0 0H110C111.105 0 112 0.895431 112 2C112 3.10457 111.105 4 110 4H0V0Z'
                    fill='#FFC4AF' />
                  <path
                    d='M0 20H58C59.1046 20 60 20.8954 60 22C60 23.1046 59.1046 24 58 24H0V20Z'
                    fill='#F9DCAA' />
                </svg>
                <DatePicker
                  selected={date}
                  onChange={(dateVal) => {
                    onChangeHandler(dateVal);
                    console.log(dateVal)
                    // setDate(dateVal)
                    // handleDateChange();
                    //   this.setState({ date: dateVal }, () => {
                    //     this.handleDateChange();
                    //   });
                  }}
                  ref={cal} 
                />
                <div className='accountList'>
                  <div className='account-List-section'>
                    <h5 className='heading'>My Synced Calendars</h5>
                    <button
                      className='settings'
                      id='google'
                      onClick={settingsHandler}
                    >
                      <div className='set-div'></div>
                    </button>
                    <div className='account-list-section-services'>
                      {me?.identities.length ? (
                        <>
                          {me?.identities.find(
                            (val) => val.serviceType === 'google'
                          ) ? (
                            <div className='google-section'>
                              <div className='google-section-heading'>
                                <div className='h5'>Google Calendar</div>
                                {/* <button
                                  className='settings'
                                  id='google'
                                  onClick={settingsHandler}
                                >
                                  <div className='set-div'></div>
                                </button> */}
                              </div>
                              <ul className='list'>
                                {me?.identities.map(
                                  (element, index) => {
                                    return element.serviceType === 'google' ? (
                                      <>
                                        <div className='emails'>
                                          <svg
                                            width='20'
                                            height='20'
                                            viewBox='0 0 20 20'
                                            fill='none'
                                            xmlns='http://www.w3.org/2000/svg'
                                          >
                                            <path
                                              d='M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z'
                                              fill='#1AACA9' />
                                            <path
                                              d='M10 14.5C5.42014 14.5 3.14952 10.3933 3.05513 10.2186C2.98163 10.0826 2.98163 9.91731 3.05513 9.7813C3.14952 9.6067 5.42014 5.5 10 5.5C14.5799 5.5 16.8505 9.6067 16.9449 9.78141C17.0184 9.91742 17.0184 10.0827 16.9449 10.2187C16.8505 10.3933 14.5799 14.5 10 14.5ZM3.95233 9.99932C4.47273 10.8106 6.51597 13.6 10 13.6C13.4951 13.6 15.5292 10.8128 16.0477 10.0007C15.5273 9.18944 13.484 6.4 10 6.4C6.50492 6.4 4.47077 9.18719 3.95233 9.99932ZM10 12.7C8.55253 12.7 7.375 11.4888 7.375 10C7.375 8.51117 8.55253 7.3 10 7.3C11.4475 7.3 12.625 8.51117 12.625 10C12.625 11.4888 11.4475 12.7 10 12.7ZM10 8.2C9.03509 8.2 8.25 9.00752 8.25 10C8.25 10.9925 9.03509 11.8 10 11.8C10.9649 11.8 11.75 10.9925 11.75 10C11.75 9.00752 10.9649 8.2 10 8.2Z'
                                              fill='white' />
                                          </svg>
                                          {element.email}
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    );
                                  }
                                )}
                              </ul>
                            </div>
                          ) : (
                            <></>
                          )}
                          {me?.identities.find(
                            (val) => val.serviceType === 'apple'
                          ) ? (
                            <div className='appleSection'>
                              <div className='apple-section-heading'>
                                <div className='h5'>Apple Calendar</div>
                                {/* <div
                                  className='settings'
                                  id='apple'
                                  onClick={settingsHandler}
                                >
                                  <div className='set-div'></div>
                                </div> */}
                              </div>
                              <div className='list'>
                                {me !== []
                                  ? me?.identities.map(
                                    (element, index) => {
                                      return element.serviceType === 'apple' ? (
                                        <>
                                          <div className='emails'>
                                            <svg
                                              width='20'
                                              height='20'
                                              viewBox='0 0 20 20'
                                              fill='none'
                                              xmlns='http://www.w3.org/2000/svg'
                                            >
                                              <path
                                                d='M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z'
                                                fill='#F46745' />
                                              <path
                                                d='M10 14.5C5.42014 14.5 3.14952 10.3933 3.05513 10.2186C2.98163 10.0826 2.98163 9.91731 3.05513 9.7813C3.14952 9.6067 5.42014 5.5 10 5.5C14.5799 5.5 16.8505 9.6067 16.9449 9.78141C17.0184 9.91742 17.0184 10.0827 16.9449 10.2187C16.8505 10.3933 14.5799 14.5 10 14.5ZM3.95233 9.99932C4.47273 10.8106 6.51597 13.6 10 13.6C13.4951 13.6 15.5292 10.8128 16.0477 10.0007C15.5273 9.18944 13.484 6.4 10 6.4C6.50492 6.4 4.47077 9.18719 3.95233 9.99932ZM10 12.7C8.55253 12.7 7.375 11.4888 7.375 10C7.375 8.51117 8.55253 7.3 10 7.3C11.4475 7.3 12.625 8.51117 12.625 10C12.625 11.4888 11.4475 12.7 10 12.7ZM10 8.2C9.03509 8.2 8.25 9.00752 8.25 10C8.25 10.9925 9.03509 11.8 10 11.8C10.9649 11.8 11.75 10.9925 11.75 10C11.75 9.00752 10.9649 8.2 10 8.2Z'
                                                fill='white' />
                                            </svg>

                                            {element.email}
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      );
                                    }
                                  )
                                  : ''}

                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                          {me?.identities.find(
                            (val) => val.serviceType === 'microsoft'
                          ) ? (
                            <div className='outlook-section'>
                              <div className='outlook-section-heading'>
                                <div className='h5'>Outlook Calendar</div>
                                {/* <div
                                  className='settings'
                                  id='microsoft'
                                  onClick={settingsHandler}
                                >
                                  <div className='set-div'></div>
                                </div> */}
                              </div>
                              <ul className='list'>
                                {me
                                  ? me?.identities.map(
                                    (element, index) => {
                                      return element.serviceType ===
                                        'microsoft' ? (
                                        <>
                                          <div className='emails'>
                                            <svg
                                              width='20'
                                              height='20'
                                              viewBox='0 0 20 20'
                                              fill='none'
                                              xmlns='http://www.w3.org/2000/svg'
                                            >
                                              <path
                                                d='M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z'
                                                fill='#6389B0' />
                                              <path
                                                d='M10 14.5C5.42014 14.5 3.14952 10.3933 3.05513 10.2186C2.98163 10.0826 2.98163 9.91731 3.05513 9.7813C3.14952 9.6067 5.42014 5.5 10 5.5C14.5799 5.5 16.8505 9.6067 16.9449 9.78141C17.0184 9.91742 17.0184 10.0827 16.9449 10.2187C16.8505 10.3933 14.5799 14.5 10 14.5ZM3.95233 9.99932C4.47273 10.8106 6.51597 13.6 10 13.6C13.4951 13.6 15.5292 10.8128 16.0477 10.0007C15.5273 9.18944 13.484 6.4 10 6.4C6.50492 6.4 4.47077 9.18719 3.95233 9.99932ZM10 12.7C8.55253 12.7 7.375 11.4888 7.375 10C7.375 8.51117 8.55253 7.3 10 7.3C11.4475 7.3 12.625 8.51117 12.625 10C12.625 11.4888 11.4475 12.7 10 12.7ZM10 8.2C9.03509 8.2 8.25 9.00752 8.25 10C8.25 10.9925 9.03509 11.8 10 11.8C10.9649 11.8 11.75 10.9925 11.75 10C11.75 9.00752 10.9649 8.2 10 8.2Z'
                                                fill='white' />
                                            </svg>
                                            {element.email}
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      );
                                    }
                                  )
                                  : ''}
                              </ul>
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <>
                          <p>You have no synced calendars.</p>
                        </>
                      )}
                    </div>
                  </div>
                  <Dropdown className='ddown' drop='down'>
                    <Dropdown.Toggle
                      variant='success'
                      className='dtoggle'
                      id='dropdown-basic-button'
                    >
                      <h1>Add Calendar</h1>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='dropdownMenu'>
                      <Dropdown.Item
                        className='div'
                        id='div'
                        href='https://accounts.google.com/o/oauth2/v2/auth?prompt=consent&access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.events%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.acls%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.calendars%20&response_type=code&client_id=1081956027778-lar61d4g87ibrqplapgc2vm40lr4g0fn.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fbackend.syncrocal.com%2Fauth%2Fgoogle'
                      >
                        {/* href='https://accounts.google.com/o/oauth2/v2/auth?prompt=consent&access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.events%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar&response_type=code&client_id=1081956027778-lar61d4g87ibrqplapgc2vm40lr4g0fn.apps.googleusercontent.com&redirect_uri=http%3A%2F%2Flocalhost%3A4000%2Fauth%2Fgoogle' */}
                        {/* " + ${urlConstants.REACT_APP_SERVER_ROOT_URI} + " */}
                        <div className='googleCalendar calendar'>
                          <span>
                            <img src={Google} alt='google' />
                          </span>
                          <span className='optionTitle'> Google Calendar</span>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item className='div' id='div' href='/loginApple'>
                        <div className='appleCalendar calendar'>
                          <span>
                            <img src={Apple} alt='apple' />
                          </span>
                          <span className='optionTitle'> Apple Calendar</span>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item
                        className='div'
                        id='div'
                        href='https://login.microsoftonline.com/common/oauth2/v2.0/authorize?&scope=Calendars.ReadWrite%20offline_access%20User.Read&response_type=code&response_mode=query&state=developer&redirect_uri=https%3A%2F%2Fbackend.syncrocal.com%2Fauth%2Fmicrosoft&client_id=a0a67abe-b993-4945-8ae1-c8b8270abb46&prompt=login'
                      >
                        {/* https://login.microsoftonline.com/common/oauth2/v2.0/authorize?&scope=Calendars.ReadWrite email offline_access openid profile User.Read&response_type=code&response_mode=query&state=developer&redirect_uri=http://localhost:4000/auth/callback&client_id=fedc0551-d436-457d-af8d-cb870433b0ad */}
                        <div className='outlookCalendar calendar'>
                          <span>
                            <img src={Outlook} alt='outlook' />
                          </span>
                          <span className='optionTitle'> Outlook Calendar</span>
                        </div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
                <Calendar
                  // onSelectDateTime={{handleSelectDateTime}}
                  // onClick={this.onBeforeCreateSchedule.bind(this)}
                  usageStatistics={false}
                  // template={}
                  calendars={me?.identities.map((element) => {
                    return {
                      id: element.serviceId,
                      name: element.email,
                      color: '#000',
                      bgColor: element.colorId ? element.colorId : '#a3a3a3',
                      dragBgColor: element.colorId ? element.colorId : '#a3a3a3',
                      borderColor: element.colorId ? element.colorId : '#a3a3a3',
                    };
                  })
                  }
                  // calendars={[
                  //   {
                  //     id: '0',
                  //     name: 'Private',
                  //     bgColor: '#DFE5EE',
                  //     borderColor: '#DFE5EE',
                  //   },
                  //   {
                  //     id: '1',
                  //     name: 'Company',
                  //     bgColor: '#D8EEEC',
                  //     borderColor: '#D8EEEC',
                  //   },
                  // ]}
                  disableDblClick={true}
                  useCreationPopup={false}
                  useDetailPopup={true}
                  // height='1200px'
                  // fullHeight={true}
                  // height="100%"
                  // height='50%'
                  // width='100%'
                  // maxWidth='1135px'
                  // maxHeight="876px"
                  // minWidth='1135px'
                  // minHeight="876px"
                  // minWidth='1120px'
                  // position=''
                  isReadOnly={false}
                  month={{
                    startDayOfWeek: 0,
                  }}
                  schedules={events}
                  scheduleView={['time']}
                  taskView={false}
                  theme={myTheme}
                  view={view}
                  onSelectDateTime={onSelectDateTime}
                  onAfterRenderSchedule={onAfterRenderSchedule}
                  onBeforeDeleteSchedule={onBeforeDeleteSchedule}
                  onClickDayname={onClickDayname}
                  onClickSchedule={onClickSchedule}
                  onClickTimezonesCollapseBtn={onClickTimezonesCollapseBtn}
                  onBeforeUpdateSchedule={onBeforeUpdateSchedule}
                  onBeforeCreateSchedule={onBeforeCreateSchedule}
                  timezones= {timezonesOptions}
                  timezones= {timezonesOptions}
                  week={{
                    showTimezoneCollapseButton: false,
                    timezonesCollapsed: true,
                    // timeGridLeft: '72px'
                    showTimezoneCollapseButton: true,
                    timezonesCollapsed: true,
                    timeGridLeft: '72px'
                  }}
                  ref={cal} 
                />

              {/* hidden create events modal */}
              {popupVisible ? (
                <Dialog
                  className={'my-hidden-modal'}
                  // fullWidth
                  maxWidth="false"
                  open={true}
                >
                  <svg
                    className='dashDsgn'
                    width='112'
                    height='24'
                    viewBox='0 0 112 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M0 0H110C111.105 0 112 0.895431 112 2C112 3.10457 111.105 4 110 4H0V0Z'
                      fill='#FFC4AF' />
                    <path
                      d='M0 20H58C59.1046 20 60 20.8954 60 22C60 23.1046 59.1046 24 58 24H0V20Z'
                      fill='#F9DCAA' />
                  </svg>
                
                  <IconButton
                    edge="start"
                    align="right"
                    color="secondary"
                    aria-label="Close"
                    style={{
                      position: 'absolute',
                      top: 10,
                      right: 16
                    }}
                    onClick={handlePopupClose}
                  >
                    <CloseIcon />
                  </IconButton>
                  <DialogContent className={'my-hidden-modal-body'} >
                      <Grid container direction="column">
                        <Grid container direction="row" className={'my-hidden-modal-body-header'}>
                          {/* <FormControl> */}
                            <InputLabel id="demo-simple-select-label">Select calendar</InputLabel>
                            <div className="select-hover">
                              <select
                                // className="select-hover"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="serviceId"
                                value={newEvent.serviceId}
                                onChange={handleInput}

                                // defaultValue={me?.response.identities[0].serviceId}
                                // {me?.response.identities.find(

                                //   (element) => {
                                //     return element.email;
                                //   }
                                // )}
                                label="Select Calendar"
                                // inputProps={{ 'aria-label': 'Without label' }}
                                // onSelect={this.onChangeEmail.bind(this)}
                                // onChange={handleInput}
                              >
                                {me ? 
                                  me.identities.map((element, index) => {
                                    
                                          {/* <MenuItem disabled value="">
                                            <em>{element.serviceType}</em>
                                          </MenuItem> */}
                                          {/* <MenuItem className="menu-item-select" value={element.email}>{element.email}</MenuItem> */}
                                      return (
                                        <option
                                          className="menu-item-select"
                                          value={element.serviceId}
                                          key={index}
                                          // onSelect={handleInput}
                                        >
                                          {element.email}
                                        </option>
                                      )
                                        {/* <MenuItem className="menu-item-select" value={element.serviceId} >{element.email}</MenuItem> */}
                                  })
                                  : 
                                  ''
                                }

                              </select>
                            </div>
                                                            {/* {emails.map(
                                  (element, index) => {
                                    return (
                                      <>
                                        <MenuItem disabled value="">
                                          <em>{element.serviceType}</em>
                                        </MenuItem>
                                        <MenuItem className="menu-item-select" value={element.email}>{element.email}</MenuItem>
                                      </>
                                    )
                                  }
                                )} */}
                          <ButtonGroup
                            color="primary"
                            // value={alignment}
                            exclusive
                            // onChange={handleChange}
                            aria-label="Platform"
                          >
                            <Button value={newEvent.eventType} style={{ backgroundColor: 'rgba(234, 246, 245, 1)' }} onClick={newEvent}>
                              <span style={{ color: 'rgba(0, 133, 129, 1)' }}>Event</span>
                            </Button>
                            <Button value={newEvent.eventType} onClick={onInputChange}>
                              <span style={{ color: 'rgba(0, 133, 129, 1)' }}>Task</span>
                            </Button>
                          </ButtonGroup>

                        </Grid>
                        <Grid
                          container
                          direction="column"
                          className={'my-hidden-modal-main-content'}
                          spacing={1}
                        >
                          <Grid>
                            <TextField
                              id="standard-basic"
                              label="Add Title"
                              name='summary'
                              variant="standard"
                              // defaultValue={newEvent.title}
                              value={newEvent.summary}
                              onChange={handleInput} 
                            />
                          </Grid>

                          <Grid container direction="row" className="my-grid-dates" item xs={10}>
                            Starts:
                            {/* <DateRangePicker
                              ref={cal}
                              name='start'
                              date={new Date()}
                              start={start}
                              end={end}
                              format="yyyy/MM/dd HH:mm"
                              timePicker={{
                                layoutType: "tab",
                                inputType: "spinbox"
                              }}
                              onChange={(e) => {
                                // console.log(e[0], e[1])
                                setStart(e[0]);
                                setEnd(e[1]);
                              }}
                              // language="ko"
                            />  */}
                             <TextField
                              id="date"
                              className='my-grid-dates-select'
                              name='start'
                              show={false}
                              // label="Start"
                              type="datetime-local"
                              // inputFormat="yyyy/MM/dd HH:mm"
                              // inputFormat="yyyy/MM/dd HH:mm"
                              // defaultValue={new Date()}
                              // onInput={this.handleDateCurrentEventChangeStart.bind(this)}
                              // value={newEvent.start}
                              value={moment(newEvent.start.dateTime).format('YYYY-MM-DDTHH:mm')}
                              // defaultValue={newEvent.start}
                              // defaultValue={new Date(new Date().setHours(newEvent.start.getHours()))}

                              // value={currentEvent.start}
                              onChange={handleInputDate} 
                              // onChange={
                              //   (e) => {
                              //     setNewEvent({ [name]: newValue });
                              //   }

                              // }
                              InputLabelProps={{
                                shrink: true,
                              }} 
                            />
                          </Grid>
                          <Grid container direction="row" className="my-grid-dates" item xs={10}>
                            Ends:
                            {/* <DateRangePicker
                              ref={cal}
                              date={new Date()}
                              start={start}
                              end={end}
                              format="yyyy/MM/dd HH:mm"
                              timePicker={{
                                layoutType: "tab",
                                inputType: "spinbox"
                              }}
                              onChange={(e) => {
                                // console.log(e[0], e[1])
                                setStart(e[0]);
                                setEnd(e[1]);
                              }}
                              // language="ko"
                            /> */}
                            {/* <TextField
                              id="date"
                              className='my-grid-dates-select'
                              name='end'
                              show={false}
                              // label="Start"
                              type="datetime-local"
                              // defaultValue={new Date()}
                              // onInput={this.handleDateCurrentEventChangeEnd.bind(this)}
                              value={newEvent.end}
                              defaultValue={newEvent.start}
                              onChange={handleInputDate} 

                              // value={currentEvent.end}
                              // onChange={onInputChange}
                              // onInput={(event) => {
                              //   this.setState({currentEvent : {
                              //     end: event.target.value
                              //     }
                              //   })   
                              // }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              sx={{
                                color: 'green'
                              }} /> */}
                            <TextField
                              id="date"
                              className='my-grid-dates-select'
                              name='end'
                              show={false}
                              // label="Start"
                              type="datetime-local"
                              // inputFormat="yyyy/MM/dd HH:mm"

                              // defaultValue={new Date()}
                              // onInput={this.handleDateCurrentEventChangeStart.bind(this)}
                              value={moment(newEvent.end.dateTime).format('YYYY-MM-DDTHH:mm')}
                              // defaultValue={newEvent.start}
                              // defaultValue={new Date(new Date().setHours(newEvent.start.getHours()))}

                              // value={currentEvent.start}
                              onChange={handleInputDate} 
                              InputLabelProps={{
                                shrink: true,
                              }} 
                            />
                              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
      
    
                                <DateTimePicker 
                                  label="End"
                                  // value={newEvent.end}
                                  onChange={handleInputDate}
                                  name='end'
                                />
                              </LocalizationProvider> */}
                              {/* <TimePicker label="Basic time picker" /> */}
                          </Grid>
                          <Grid>
                            <RadioGroup
                              aria-labelledby="demo-form-control-label-placement"
                              name="position"
                              defaultValue="top"
                            >
                              <FormControlLabel
                                // value={currentEvent.isAllDay}
                                name='isAllDay'
                                defaultValue={newEvent.isAllDay}
                                value={true}
                                onChange={handleInput} 
                                style={{ color: 'black' }}

                                // className={classes.root} 
                                control={<Radio />}
                                label={<span style={{ color: 'black' }}>All Day Event</span>}
                                // onClick={this.handleEventIsAllDay.bind(this)} 
                                // onChange={onInputChange}
                              />
                            </RadioGroup>
                          </Grid>
                          <Grid container direction="column" className="my-grid-meeting" item xs={17}>
                            <Grid className="my-grid-meeting-input">
                              Add Meeting:
                              <TextField
                                // fullWidth
                                margin="normal"
                                variant="standard"
                                label="Add Location"
                                id="add-location"
                                className='add-location'
                                name='location'
                                defaultValue={newEvent.location}
                                onChange={handleInput} 

                                // value={currentEvent.meetingLink}
                                // onChange={onInputChange} 
                              />
                            </Grid>
                            <Grid container direction="row" spacing={2} className="my-grid-meeting-buttons" item xs={17}>
                              <Grid item m>
                                <Chip
                                  variant="outlined"
                                  // size="medium"
                                  style={{ width: '132px', height: '32px' }}
                                  // avatar={<Avatar>G</Avatar>}
                                  avatar={<img
                                    src={googleMeet}
                                    alt="googleMeet" />}
                                  label={<span style={{ color: 'black' }}>Google Meet</span>}
                                  // value={currentEvent.meetingLink.googleMeet}
                                  className='meetingLink'
                                  name='meetingLink.googleMeet'
                                  defaultValue={newEvent.meetingLink.googleMeet}
                                  value={
                                    <button
                                      onClick={() => {
                                        newEvent.meetingLink.googleMeet = 'https://meet.google.com/lookup/1234567890'
                                        console.log(newEvent.meetingLink.googleMeet)
                                      }}
                                    ></button>
                                  }
                                  onChange={handleInput} 

                                  //onChange={onInputChange} 
                                />
                              </Grid>
                              <Grid item m>
                                <Chip
                                  variant="outlined"
                                  // size="small"
                                  style={{ width: '132px', height: '32px' }}
                                  // avatar={<Avatar>Z</Avatar>}
                                  avatar={<img
                                    src={zoom}
                                    alt="Zoom" />}
                                  label={<span style={{ color: 'black' }}>Zoom</span>}
                                  // value={currentEvent.meetingLink.zoom}
                                  className='meetingLink'
                                  name='meetingLink.zoom'
                                  defaultValue={newEvent.meetingLink.zoom}
                                  onChange={handleInput} 

                                  // onChange={onInputChange} 
                                />
                              </Grid>
                              <Grid item m>
                                <Chip
                                  variant="outlined"
                                  // size="medium"
                                  style={{ width: '132px', height: '32px' }}
                                  // avatar={<Avatar>M</Avatar>}
                                  avatar={<img
                                    src={microsoftTeams}
                                    alt="MicrosoftTeams" />}
                                  label={<span style={{ color: 'black', overflow: 'visible' }}>Microsoft Teams</span>}
                                  // value={currentEvent.meetingLink.microsoftTeams}
                                  className='meetingLink'
                                  name='meetingLink.microsoftTeams'
                                  defaultValue={newEvent.meetingLink.microsoftTeams}
                                  onChange={handleInput} 

                                  // onChange={onInputChange} 
                                />                                  
                              </Grid>
                              {/* <Grid item m>
                                <Chip
                                  variant="outlined"
                                  // size="big"
                                  style={{ width: '132px', height: '32px' }}
                                  avatar={<Avatar>C</Avatar>}
                                  label={<span style={{ color: 'black' }}>Calendly</span>}
                                  // value={currentEvent.meetingLink.calendly}
                                  className='meetingLink'
                                  name='meetingLink.calendly'
                                  defaultValue={newEvent.meetingLink.calendly}
                                  onChange={handleInput} 

                                  // onChange={onInputChange} 
                                />                                  
                              </Grid> */}
                            </Grid>
                          </Grid>
                          {/* <Grid container className={"my-grid-privacy"} spacing={2} direction="row" item xs={17}>
                            <span style={{ color: 'black' }}>Privacy Settings:</span>

                            <Grid container className={"my-grid-privacy-select"} spacing={1} direction="column" item xs={7}>
                              <RadioGroup
                                aria-labelledby="demo-form-control-label-placement"
                                name="position"
                                defaultValue="Only Me"

                              >
                                <label style={{ display: 'flex', alignItems: 'center', }}>
                                  <input
                                    type="radio"
                                    // value={this.state.currentEvent.}
                                    onClick={onInputChange}
                                    value="private"
                                    className={'my-input-colorscheme'}
                                    style={{ margin: '5px', color: 'rgba(104, 110, 139, 1)', width: '18px', height: '18px', border: '1px solid #686E8B', borderRadius: '100px' }}>

                                  </input>
                                  <span style={{ color: 'black' }}>Only Me </span>
                                </label>
                                <label style={{ display: 'flex', alignItems: 'center' }}>
                                  <input type="radio" onClick={onInputChange} value="onlyMe" className={'my-input-colorscheme'} style={{ margin: '5px', color: 'rgba(104, 110, 139, 1)', width: '18px', height: '18px', border: '1px solid #686E8B', borderRadius: '100px' }}></input>
                                  <span style={{ color: 'black' }}>Public (Everyone can see) </span>
                                </label>
                                <label style={{ display: 'flex', alignItems: 'center' }}>
                                  <input type="radio" onClick={onInputChange} value="onlyMe" className={'my-input-colorscheme'} style={{ margin: '5px', color: 'rgba(104, 110, 139, 1)', width: '18px', height: '18px', border: '1px solid #686E8B', borderRadius: '100px' }}></input>
                                  <span style={{ color: 'black' }}>Share with certain People </span>
                                </label>
                              */}
                                {/* <FormControlLabel value="IsAllDay" control={<Radio />} label={<span style={{color: 'black'}}>Only Me</span>} onClick={this.handleEventIsAllDay.bind(this)}>  </FormControlLabel>
                                  <FormControlLabel value="IsAllDay" control={<Radio />} label={<span style={{color: 'black'}}>Public (Everyone can see) </span>} onClick={this.handleEventIsAllDay.bind(this)} />
                                  <FormControlLabel  value="IsAllDay" control={<Radio style={{borderColor: 'green'}} />} label={<span style={{color: 'black'}}>Share with certain people</span>} onClick={this.handleEventIsAllDay.bind(this)} /> 
                                */}

                              {/* </RadioGroup> */}
                            <Grid>
                              <Fab
                                variant="extended"
                                size="medium"
                                // color="white"
                                style={{ marginTop: '10px', width: '132px', height: '32px', display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: 'rgba(255, 255, 255, 1)' }}
                                aria-label="add"
                                className={'my-fab'}
                                name='atendees'
                                // defaultChecked={newEvent.atendees}
                                defaultValue={newEvent.atendees}
                                onChange={handleInput} 

                              >
                                {/* <NavigationIcon className={'my-navigation-icon'} /> */}
                                <span style={{ fontFamily: 'Hind', fontStyle: 'normal', fontSize: '16px', fontWeight: '500', color: 'black' }}> Add Person</span>
                                {/* <Avatar style={{width: '25px',height: '25px',fontSize: '10px'}}>+</Avatar> */}

                              </Fab>
                            </Grid> 

                          {/* <Grid container direction="row" className={"my-color-scheme-container"} item xs={22}>
                            <span style={{ paddingRight: '10px', color: 'black' }}>Color Scheme:</span>

                            <input type="radio" value="dark-green" className={'my-input-colorscheme'} style={{ margin: '5px', color: 'rgba(104, 110, 139, 1)', width: '18px', height: '18px', border: '1px solid #686E8B', borderRadius: '100px' }}></input>
                            <input type="radio" value="light-green" className={'my-input-colorscheme'} style={{ margin: '5px', color: 'rgba(104, 110, 139, 1)', width: '18px', height: '18px', border: '1px solid #686E8B', borderRadius: '100px' }}></input>
                            <input type="radio" value="yellow" className={'my-input-colorscheme'} style={{ margin: '5px', color: 'rgba(104, 110, 139, 1)', width: '18px', height: '18px', border: '1px solid #686E8B', borderRadius: '100px' }}></input>
                            <input type="radio" value="orange" className={'my-input-colorscheme'} style={{ margin: '5px', color: 'rgba(104, 110, 139, 1)', width: '18px', height: '18px', border: '1px solid #686E8B', borderRadius: '100px' }}></input>
                            <input type="radio" value="red" className={'my-input-colorscheme'} style={{ margin: '5px', color: 'rgba(104, 110, 139, 1)', width: '18px', height: '18px', border: '1px solid #686E8B', borderRadius: '100px' }}></input>
                            <input type="radio" value="light-pink" className={'my-input-colorscheme'} style={{ margin: '5px', color: 'rgba(104, 110, 139, 1)', width: '18px', height: '18px', border: '1px solid #686E8B', borderRadius: '100px' }}></input>
                            <input type="radio" value="pink" className={'my-input-colorscheme'} style={{ margin: '5px', color: 'rgba(104, 110, 139, 1)', width: '18px', height: '18px', border: '1px solid #686E8B', borderRadius: '100px' }}></input>
                            <input type="radio" value="light-purple" className={'my-input-colorscheme'} style={{ margin: '5px', color: 'rgba(104, 110, 139, 1)', width: '18px', height: '18px', border: '1px solid #686E8B', borderRadius: '100px' }}></input>
                            <input type="radio" value="purple" className={'my-input-colorscheme'} style={{ margin: '5px', color: 'rgba(104, 110, 139, 1)', width: '18px', height: '18px', border: '1px solid #686E8B', borderRadius: '100px' }}></input>
                            <input type="radio" value="magenta" className={'my-input-colorscheme'} style={{ margin: '5px', color: 'rgba(104, 110, 139, 1)', width: '18px', height: '18px', border: '1px solid #686E8B', borderRadius: '100px' }}></input>
                            <input type="radio" value="blue" className={'my-input-colorscheme'} style={{ margin: '5px', color: 'rgba(104, 110, 139, 1)', width: '18px', height: '18px', border: '1px solid #686E8B', borderRadius: '100px' }}></input>
                            <input type="radio" value="light-blue" className={'my-input-colorscheme'} style={{ margin: '5px', color: 'rgba(104, 110, 139, 1)', width: '18px', height: '18px', border: '1px solid #686E8B', borderRadius: '100px' }}></input>
                            <input type="radio" value="grey" className={'my-input-colorscheme'} style={{ margin: '5px', color: 'rgba(104, 110, 139, 1)', width: '18px', height: '18px', border: '1px solid #686E8B', borderRadius: '100px' }}></input>

                            {/* </RadioGroup> */}
                          {/* </Grid> */} 
                          <Grid item xs={2}>
                            <Button type="submit" className="save-button" onClick={(e) => {
                                          // events.push(newEvent);
                                          handleSubmit(e)
                                          
                                      }} >
                              {/* <Button onClick={this.handleCreateSchedule.bind} */}
                              <span style={{ fontFamily: 'Hind', fontStyle: 'normal', fontSize: '16px', fontWeight: '500', color: 'black' }}>Save</span>
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    
                  </DialogContent>
                </Dialog>
              ) : isPopupEditVisible ? (
                <Dialog
                className={'my-hidden-modal'}
                // fullWidth
                maxWidth="false"
                open={true}
              >
                <svg
                  className='dashDsgn'
                  width='112'
                  height='24'
                  viewBox='0 0 112 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M0 0H110C111.105 0 112 0.895431 112 2C112 3.10457 111.105 4 110 4H0V0Z'
                    fill='#FFC4AF' />
                  <path
                    d='M0 20H58C59.1046 20 60 20.8954 60 22C60 23.1046 59.1046 24 58 24H0V20Z'
                    fill='#F9DCAA' />
                </svg>
              
                <IconButton
                  edge="start"
                  align="right"
                  color="secondary"
                  aria-label="Close"
                  style={{
                    position: 'absolute',
                    top: 10,
                    right: 16
                  }}
                  onClick={handlePopupClose}
                >
                  <CloseIcon />
                </IconButton>
                <DialogContent className={'my-hidden-modal-body'} >
                  <form id="myform" onSubmit={handleUpdateSchedule}>
                    <Grid container direction="column">
                      <Grid container direction="row" className={'my-hidden-modal-body-header'}>
                        {/* <FormControl> */}
                          <InputLabel id="demo-simple-select-label">Event Calendar</InputLabel>
                          <Select
                            className="select-hover"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="serviceId"
                            value={newEvent.serviceId}
                            // value={me?.response.identities.find(

                            //   (element) => {
                            //     return element.email;
                            //   }
                            // )}
                            label="Select Calendar"
                            inputProps={{ 'aria-label': 'Without label' }}
                            // onSelect={this.onChangeEmail.bind(this)}
                            onChange={handleInput}
                            sx={{
                              color: "white",
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgb(0, 255, 64)',
                              },
                              '.MuiSvgIcon-root ': {
                                fill: "white !important",
                              }
                            }}
                          >
                            {me ?
                              me?.identities.map(
                                (element, index) => {
                                  return (
                                    <>
                                      {/* <MenuItem disabled value="">
                                        <em>{element.serviceType}</em>
                                      </MenuItem> */}
                                      {/* <MenuItem className="menu-item-select" value={element.email}>{element.email}</MenuItem> */}
                                      <MenuItem className="menu-item-select" value={element.serviceId} >{element.email}</MenuItem>
                                    </>
                                    //onClick={setCalendar}
                                  );
                                })
                              : ''}
                            {/* {emails.map(
                              (element, index) => {
                                return (
                                  <>
                                    <MenuItem disabled value="">
                                      <em>{element.serviceType}</em>
                                    </MenuItem>
                                    <MenuItem className="menu-item-select" value={element.email}>{element.email}</MenuItem>
                                  </>
                                )
                              }
                            )} */}
                          </Select>
                        <ButtonGroup
                          color="primary"
                          // value={alignment}
                          exclusive
                          // onChange={handleChange}
                          aria-label="Platform"
                        >
                          <Button value={newEvent.eventType} style={{ backgroundColor: 'rgba(234, 246, 245, 1)' }} onClick={newEvent}>
                            <span style={{ color: 'rgba(0, 133, 129, 1)' }}>Event</span>
                          </Button>
                          <Button value={newEvent.eventType} onClick={onInputChange}>
                            <span style={{ color: 'rgba(0, 133, 129, 1)' }}>Task</span>
                          </Button>
                        </ButtonGroup>

                      </Grid>
                      <Grid
                        container
                        direction="column"
                        className={'my-hidden-modal-main-content'}
                        spacing={1}
                      >
                        <Grid>
                          <TextField
                            id="standard-basic"
                            label="Add Title"
                            name='summary'
                            variant="standard"
                            defaultValue=""
                            value={newEvent.summary}
                            onChange={handleInput} 
                          />
                        </Grid>

                        <Grid container direction="row" className="my-grid-dates" item xs={10}>
                          Starts:
                          <TextField
                            id="date"
                            className='my-grid-dates-select'
                            name='start'
                            // label="Start"
                            type="datetime-local"
                            // defaultValue={new Date()}
                            // onInput={this.handleDateCurrentEventChangeStart.bind(this)}
                            defaultValue={newEvent.start.dateTime}
                            // value={newEvent.start}
                            onChange={handleInput} 
                            InputLabelProps={{
                              shrink: true,
                            }} 
                          />
                        </Grid>
                        <Grid container direction="row" className="my-grid-dates" item xs={10}>
                          Ends:
                          <TextField
                            id="date"
                            className='my-grid-dates-select'
                            name='end'
                            show={false}
                            // label="Start"
                            type="datetime-local"
                            // defaultValue={new Date()}
                            // onInput={this.handleDateCurrentEventChangeEnd.bind(this)}
                            defaultValue={newEvent.end.dateTime}
                            onChange={handleInput} 

                            // value={currentEvent.end}
                            // onChange={onInputChange}
                            // onInput={(event) => {
                            //   this.setState({currentEvent : {
                            //     end: event.target.value
                            //     }
                            //   })   
                            // }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            sx={{
                              color: 'green'
                            }} />
                        </Grid>
                        <Grid>
                          <RadioGroup
                            aria-labelledby="demo-form-control-label-placement"
                            name="position"
                            defaultValue="top"
                          >
                            <FormControlLabel
                              // value={currentEvent.isAllDay}
                              name='isAllDay'
                              defaultValue={newEvent.isAllDay}
                              value={true}
                              onChange={handleInput} 

                              // className={classes.root} 
                              control={<Radio />}
                              label={<span style={{ color: 'black' }}>All Day Event</span>}
                              // onClick={this.handleEventIsAllDay.bind(this)} 
                              // onChange={onInputChange}
                            />
                          </RadioGroup>
                        </Grid>
                        <Grid container direction="column" className="my-grid-meeting" item xs={17}>
                          <Grid className="my-grid-meeting-input">
                            Add Meeting:
                            <TextField
                              // fullWidth
                              margin="normal"
                              variant="standard"
                              label="Add Location"
                              id="add-location"
                              className='add-location'
                              name='location'
                              defaultValue={newEvent.location}
                              onChange={handleInput} 

                              // value={currentEvent.meetingLink}
                              // onChange={onInputChange} 
                            />
                          </Grid>
                          <Grid container direction="row" spacing={2} className="my-grid-meeting-buttons" item xs={17}>
                            <Grid item m>
                              <Chip
                                variant="outlined"
                                // size="medium"
                                style={{ width: '132px', height: '32px' }}
                                // avatar={<Avatar>G</Avatar>}
                                avatar={<img
                                  src={googleMeet}
                                  alt="googleMeet" />}
                                label={<span style={{ color: 'black' }}>Google Meet</span>}
                                // value={currentEvent.meetingLink.googleMeet}
                                className='meetingLink'
                                name='meetingLink.googleMeet'
                                defaultValue={newEvent.meetingLink.googleMeet}
                                onChange={handleInput} 

                                //onChange={onInputChange} 
                              />
                            </Grid>
                            <Grid item m>
                              <Chip
                                variant="outlined"
                                // size="small"
                                style={{ width: '132px', height: '32px' }}
                                // avatar={<Avatar>Z</Avatar>}
                                avatar={<img
                                  src={zoom}
                                  alt="Zoom" />}
                                label={<span style={{ color: 'black' }}>Zoom</span>}
                                // value={currentEvent.meetingLink.zoom}
                                className='meetingLink'
                                name='meetingLink.zoom'
                                defaultValue={newEvent.meetingLink.zoom}
                                onChange={handleInput} 

                                // onChange={onInputChange} 
                              />
                            </Grid>
                            <Grid item m>
                              <Chip
                                variant="outlined"
                                // size="medium"
                                style={{ width: '132px', height: '32px' }}
                                // avatar={<Avatar>M</Avatar>}
                                avatar={<img
                                  src={microsoftTeams}
                                  alt="MicrosoftTeams" />}
                                label={<span style={{ color: 'black' }}>Micrsoft Teams</span>}
                                // value={currentEvent.meetingLink.microsoftTeams}
                                className='meetingLink'
                                name='meetingLink.microsoftTeams'
                                defaultValue={newEvent.meetingLink.microsoftTeams}
                                onChange={handleInput}
                                onClick={
                                  () => {
                                    console.log('microsoftTeams')
                                  }
                                } 

                                // onChange={onInputChange} 
                              />                                  
                            </Grid>
                            <Grid item m>
                              <Chip
                                variant="outlined"
                                // size="big"
                                style={{ width: '132px', height: '32px' }}
                                avatar={<Avatar>C</Avatar>}
                                label={<span style={{ color: 'black' }}>Calendly</span>}
                                // value={currentEvent.meetingLink.calendly}
                                className='meetingLink'
                                name='meetingLink.calendly'
                                defaultValue={newEvent.meetingLink.calendly}
                                onChange={handleInput} 

                                // onChange={onInputChange} 
                              />                                  
                            </Grid>
                          </Grid>
                        </Grid>
                          <Grid>
                            <Fab
                              variant="extended"
                              size="medium"
                              // color="white"
                              style={{ marginTop: '10px', width: '132px', height: '32px', display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: 'rgba(255, 255, 255, 1)' }}
                              aria-label="add"
                              className={'my-fab'}
                              name='atendees'
                              // defaultChecked={newEvent.atendees}
                              defaultValue={newEvent.atendees}
                              onChange={handleInput} 

                            >
                              {/* <NavigationIcon className={'my-navigation-icon'} /> */}
                              <span style={{ fontFamily: 'Hind', fontStyle: 'normal', fontSize: '16px', fontWeight: '500', color: 'black' }}> Add Person</span>
                              {/* <Avatar style={{width: '25px',height: '25px',fontSize: '10px'}}>+</Avatar> */}

                            </Fab>
                          </Grid> 
                        <Grid item xs={2}>
                          <Button type="submit" >
                            {/* <Button onClick={this.handleCreateSchedule.bind} */}
                            <span style={{ fontFamily: 'Hind', fontStyle: 'normal', fontSize: '16px', fontWeight: '500', color: 'black' }}>Save</span>
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </DialogContent>
              </Dialog>
              ) : null}
            </div>
          </div>
          <Privacy
              open={open}
              events={events}
              serviceType={serviceType}
              setOpen={updateOpen}
              // handleStatusChange={handleStatusChange}
              fetchData={fetchData}
              me={me} 
          />
        </>
      ) : null}
      </>
    );
  
}
export default withRouter(Story);
// export default withStyles(styles)(Story);
