import React from "react";
import "../../css/Accounts/ChangePasContainer.css";
import Navbar from "./Navbar";

import axios from "axios";
import { toast } from "react-toastify";
import urlConstants from "../../config";

function ChangePassContainer() {
  const changePasswordHandler = async (e) => {
    e.preventDefault();

    let passwordVal = e.target.newPassword.value;
    let cnfPasswordVal = e.target.cnfnewPassword.value;

    if (passwordVal === cnfPasswordVal) {
      let response = await axios.post(
        `${urlConstants.REACT_APP_SERVER_ROOT_URI}/users/update-password`,
        {
          password: passwordVal,
        },
        {
          withCredentials: true,
        }
      );
      
      console.log(response);

      if (response.status === 200) {
        toast.success(response.data.message);
        e.target.newPassword.value = '';
        e.target.cnfnewPassword.value = '';
      } else {
        toast.error(response.data.message);
      }
    } else {
      toast.error("Password didn't Match");
    }
  };

  return (
    <div>
      <Navbar backTo="account" />
      <div className="svg-box">
          <svg
            width="112"
            height="24"
            viewBox="0 0 112 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 0H110C111.105 0 112 0.895431 112 2V2C112 3.10457 111.105 4 110 4H0V0Z"
              fill="#FFC4AF"
            />
            <path
              d="M0 20H58C59.1046 20 60 20.8954 60 22V22C60 23.1046 59.1046 24 58 24H0V20Z"
              fill="#F9DCAA"
            />
          </svg>
        </div>

      <div className="main-content">
        <div className="content-sub-head">
          <h1>Change password</h1>
          <form className="content" onSubmit={changePasswordHandler}>
            <div className="new-password-cont">
              <label htmlFor="password">
                New Password <span>*</span>
              </label>
              <input
                type="password"
                required
                name="newPassword"
                placeholder="Enter new password"
              />
            </div>
            <div className="new-password-cont1">
              <label htmlFor="password">
                Confirm New Password <span>*</span>
              </label>
              <input
                type="password"
                required
                name="cnfnewPassword"
                placeholder="Enter new password"
              />
            </div>
            <div className="updatePass-btn-cont">
              <button className="updatePass-btn" type="submit">
                Update Password
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ChangePassContainer;
