import React from "react";

const PrivacyPage = () => {
    return (
            <div>
                                <div style="position:absolute;top:1.11in;left:1.09in;width:2.32in;line-height:0.32in;"><span style="font-style:normal;font-weight:bold;font-size:24pt;font-family:Times New Roman;color:#000000">Privacy Policy</span><span style="font-style:normal;font-weight:bold;font-size:24pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:1.72in;left:1.09in;width:6.66in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Alchemist Growth Enterprise's Privacy Policy describes how Alchemist and its subsidiary,</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:1.94in;left:1.09in;width:4.71in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">SyncroCal, collects, uses, and shares your personal information.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.36in;left:1.09in;width:6.92in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">This Privacy Policy explains your choices surrounding how we use your personal information,</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.56in;left:1.09in;width:7.08in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">which include how you can object to certain uses of the information and how you can access and</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.78in;left:1.09in;width:2.02in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">update certain information.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:3.20in;left:1.09in;width:6.81in;line-height:0.16in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Here are some important definitions to help you understand our terms and this Privacy</span><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:3.41in;left:1.09in;width:0.58in;line-height:0.17in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Policy:</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:3.82in;left:1.09in;width:6.90in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Alchemist Growth Enterprises, LLC and our relevant affiliates are referred to as "</span><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Alchemist</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">,"</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.04in;left:1.09in;width:1.72in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">"</span><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">we</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">," "</span><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">us</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">," and "</span><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">our</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">."</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.46in;left:1.09in;width:6.75in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Our public websites, including </span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#0000ff">www.alchemistgrowth.com</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> are referred to as the "</span><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Website</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">."</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.88in;left:1.09in;width:6.66in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Our online software-</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">as</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">-service platform including any related APIs provided by Alchemist</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.08in;left:1.09in;width:6.79in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Growth Enterprises together with all related mobile and desktop applications are collectively</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.30in;left:1.09in;width:2.16in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">referred to as the "</span><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Services</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">."</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.72in;left:1.09in;width:6.52in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Content submitted, posted or modified by users in the Service is organized into separated</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.93in;left:1.09in;width:3.43in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">sections that are referred to as "</span><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Workspaces</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">."</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.36in;left:1.09in;width:4.05in;line-height:0.24in;"><span style="font-style:normal;font-weight:bold;font-size:18pt;font-family:Times New Roman;color:#000000">1. Personal Information we collect</span><span style="font-style:normal;font-weight:bold;font-size:18pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.87in;left:1.09in;width:6.50in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">We collect personal information when you provide it to us, when you use our Website or</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.08in;left:1.09in;width:5.67in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Services, and when other sources provide it to us, as further described below.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.51in;left:1.09in;width:2.68in;line-height:0.17in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">A. Information You Provide to Us</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.92in;left:1.09in;width:6.58in;line-height:0.17in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Account Creation:</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> When you create an account or otherwise use the Services, we collect</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.13in;left:1.09in;width:7.12in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">information such as your name, email address, password, role within your team or enterprise, and</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.35in;left:1.09in;width:1.89in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">an optional profile photo.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.76in;left:1.09in;width:6.64in;line-height:0.17in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Your Communications with Us:</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> We collect personal information from you such as email</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.97in;left:1.09in;width:6.83in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">address, phone number, or mailing address when you request information about our Services,</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:9.18in;left:1.09in;width:6.84in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">request customer or technical support, or otherwise communicate with us. We also collect the</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:9.39in;left:1.09in;width:6.89in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">contents of messages or attachments that you may send to us, as well as other information you</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:9.60in;left:1.09in;width:5.42in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">choose to provide, and that may be associated with your communications. </span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:10.02in;left:1.09in;width:6.61in;line-height:0.17in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Payment Information:</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> When you purchase a subscription to the Services, we will collect</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:10.23in;left:1.09in;width:6.91in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">payment information allowing you to pay us. We use third-party payment providers to process</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:10.44in;left:1.09in;width:6.28in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">payments on the Services. We may receive information associated with your payment</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:1.09in;left:1.05in;"><a name="Privacy_Policy"><span style="font-style:normal;font-weight:normal;font-size:5pt;font-family:Times New Roman;color:#000000">&nbsp</span></a></div>
<div style="position:absolute;top:6.35in;left:1.05in;"><a name="1._Personal_Information_we_collect"><span style="font-style:normal;font-weight:normal;font-size:5pt;font-family:Times New Roman;color:#000000">&nbsp</span></a></div>  
<div style="position:absolute;top:1.09in;left:1.09in;width:6.77in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">information, such as billing address and transaction information, but we do not directly store</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:1.30in;left:1.09in;width:2.78in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">payment information on the Services.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:1.72in;left:1.09in;width:7.03in;line-height:0.17in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Surveys:</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> We may contact you to participate in surveys. If you decide to participate, you may be</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:1.94in;left:1.09in;width:5.73in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">asked to provide certain information which may include personal information.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.36in;left:1.09in;width:6.48in;line-height:0.17in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Interactive Features:</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> We may offer interactive features such as forums, blogs, chat and</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.56in;left:1.09in;width:6.78in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">messaging services, and social media pages. We and others who use our Website or Services</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.77in;left:1.09in;width:7.11in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">may collect the information you submit or make available through these interactive features. Any</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.98in;left:1.09in;width:6.91in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">content you provide via the public sections of these features will be considered "public" and is</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:3.19in;left:1.09in;width:7.00in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">not subject to the privacy protections referenced herein. By using these interactive features, you</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:3.40in;left:1.09in;width:6.67in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">understand that the personal information provided by you may be viewed and used by third</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:3.61in;left:1.09in;width:2.26in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">parties for their own purposes.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.03in;left:1.09in;width:7.00in;line-height:0.17in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Conferences, Trade Shows, and Other Events:</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> We may attend conferences, trade shows, and</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.24in;left:1.09in;width:7.11in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">other events where we collect personal information from individuals who interact with or express</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.45in;left:1.09in;width:1.96in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">an interest in the Services.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.88in;left:1.09in;width:3.13in;line-height:0.17in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">B. Information Collected Automatically</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.29in;left:1.09in;width:7.10in;line-height:0.17in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Automatic Data Collection:</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> We keep track of certain information about you when you visit and</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.50in;left:1.09in;width:6.96in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">interact with our Website or Services. This information may include your Internet protocol (IP)</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.71in;left:1.09in;width:6.91in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">address, user settings, MAC address, cookie identifiers, mobile carrier, mobile advertising and</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.92in;left:1.09in;width:6.48in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">other unique identifiers, details about your browser, operating system or device, location</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.13in;left:1.09in;width:7.02in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">information (including inferred location based off of your IP address), Internet service provider,</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.34in;left:1.09in;width:7.08in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">pages that you visit before, during and after using the Website or Services, information about the</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.55in;left:1.09in;width:6.95in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">links you click, information about how you interact with the Website or Services, including the</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.76in;left:1.09in;width:7.01in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">frequency and duration of your activities, and other information about how you use the Website</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.97in;left:1.09in;width:6.39in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">or Services. Information we collect may be associated with accounts and other devices. </span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.39in;left:1.09in;width:6.87in;line-height:0.17in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Cookies, Pixel Tags/Web Beacons, and Analytics Information:</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> We, as well as third parties</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.59in;left:1.09in;width:6.93in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">that provide content, advertising, or other functionality on the Services, may use cookies, pixel</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.80in;left:1.09in;width:7.04in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">tags, local storage, and other technologies ("Technologies") to automatically collect information</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.01in;left:1.09in;width:6.79in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">through the Website or Services. Technologies are essentially small data files placed on your</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.22in;left:1.09in;width:7.05in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">devices that allow us and our partners to record certain pieces of information whenever you visit</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.43in;left:1.09in;width:2.20in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">or interact with our Services. </span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.86in;left:1.09in;width:2.81in;line-height:0.17in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">C. Information from Other Sources</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:9.27in;left:1.09in;width:7.08in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">We may obtain information about you from other sources, including through third-party services</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:9.48in;left:1.09in;width:6.71in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">and organizations. For example, if you access our Website or Services through a third-party</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:9.69in;left:1.09in;width:6.56in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">application, such as a social networking site or a third-party login service, we may collect</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:9.90in;left:1.09in;width:6.58in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">information about you from that third party that you have made available via your privacy</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:10.11in;left:1.09in;width:0.65in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">settings.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:1.09in;left:1.09in;width:6.72in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Should you choose to use the Google Contacts feature within our Services, we will have the</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:1.30in;left:1.09in;width:6.98in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">ability to view your contacts via the Google People API. The sole use of this data is to populate</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:1.51in;left:1.09in;width:6.48in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">the auto-completion of your contacts when sending invitation emails. Alchemist Growth</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:1.72in;left:1.09in;width:4.07in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Enterprises will not use this data for any other purpose.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.15in;left:1.09in;width:3.80in;line-height:0.24in;"><span style="font-style:normal;font-weight:bold;font-size:18pt;font-family:Times New Roman;color:#000000">2. How we use your information</span><span style="font-style:normal;font-weight:bold;font-size:18pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.67in;left:1.09in;width:7.01in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">We do not sell your personal information. We do not share your personal information with third</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.88in;left:1.09in;width:4.44in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">parties for them to market or advertise their products to you.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:3.30in;left:1.09in;width:6.03in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">We use your personal information for a variety of business purposes, including to:</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:3.73in;left:1.09in;width:4.40in;line-height:0.17in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Provide the Services or Requested Information, such as:</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.15in;left:1.36in;width:2.66in;line-height:0.14in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Symbol;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.14in;left:1.36in;width:2.66in;line-height:0.17in;"><div style="position:relative; left:0.27in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Fulfilling our contract with you;</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div></div>
<div style="position:absolute;top:4.36in;left:1.36in;width:5.69in;line-height:0.14in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Symbol;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.35in;left:1.36in;width:5.69in;line-height:0.17in;"><div style="position:relative; left:0.27in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Identifying and communicating with you, including providing newsletters</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div></div>
<div style="position:absolute;top:4.35in;left:7.05in;width:0.85in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">and market</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.35in;left:7.87in;width:0.27in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">ing</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.56in;left:1.64in;width:0.76in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">materials;</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.78in;left:1.36in;width:2.39in;line-height:0.14in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Symbol;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.77in;left:1.36in;width:2.39in;line-height:0.17in;"><div style="position:relative; left:0.27in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Managing your information;</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div></div>
<div style="position:absolute;top:4.99in;left:1.36in;width:2.22in;line-height:0.14in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Symbol;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.98in;left:1.36in;width:2.22in;line-height:0.17in;"><div style="position:relative; left:0.27in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Processing your payments</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div></div>
<div style="position:absolute;top:4.98in;left:3.59in;width:1.06in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">and otherwise</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.98in;left:4.65in;width:1.78in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">servicing your purchase</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.98in;left:6.43in;width:0.55in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">orders;</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.19in;left:1.36in;width:2.15in;line-height:0.14in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Symbol;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.18in;left:1.36in;width:2.15in;line-height:0.17in;"><div style="position:relative; left:0.27in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Responding to questions,</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div></div>
<div style="position:absolute;top:5.18in;left:3.52in;width:0.84in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">comments,</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.18in;left:4.35in;width:0.72in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">and other</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.18in;left:5.08in;width:0.69in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">requests;</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.40in;left:1.36in;width:1.54in;line-height:0.14in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Symbol;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.40in;left:1.36in;width:1.54in;line-height:0.17in;"><div style="position:relative; left:0.27in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Providing access</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div></div>
<div style="position:absolute;top:5.40in;left:2.91in;width:1.19in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">to certain areas,</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.40in;left:4.10in;width:1.12in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">functionalities,</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.40in;left:5.23in;width:0.93in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">and features</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.40in;left:6.16in;width:0.19in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">of</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.40in;left:6.35in;width:1.00in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">our Services;</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.40in;left:7.35in;width:0.30in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">and</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.62in;left:1.36in;width:0.54in;line-height:0.14in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Symbol;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.61in;left:1.36in;width:0.54in;line-height:0.17in;"><div style="position:relative; left:0.27in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">An</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div></div>
<div style="position:absolute;top:5.61in;left:1.86in;width:1.26in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">swering requests</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.61in;left:3.12in;width:0.26in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">for</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.61in;left:3.38in;width:0.71in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">customer</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.61in;left:4.09in;width:0.89in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">or technical</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.61in;left:4.98in;width:0.64in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">support.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.03in;left:1.09in;width:4.80in;line-height:0.17in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Serve Administrative and Communication Purposes, such as:</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.46in;left:1.36in;width:1.74in;line-height:0.14in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Symbol;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.45in;left:1.36in;width:1.74in;line-height:0.17in;"><div style="position:relative; left:0.27in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Pursuing legitimate</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div></div>
<div style="position:absolute;top:6.45in;left:3.10in;width:0.69in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">interests,</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.45in;left:3.80in;width:0.57in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">such as</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.45in;left:4.37in;width:0.46in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">direct</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.45in;left:4.83in;width:2.77in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">marketing, research and development</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.65in;left:1.64in;width:6.46in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">(including marketing research), network and information security, and fraud prevention;</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.87in;left:1.36in;width:3.56in;line-height:0.14in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Symbol;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.87in;left:1.36in;width:3.56in;line-height:0.17in;"><div style="position:relative; left:0.27in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Sending communications about new product</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div></div>
<div style="position:absolute;top:6.87in;left:4.93in;width:0.66in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">features,</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.87in;left:5.59in;width:0.91in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">promotions,</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.87in;left:6.51in;width:0.20in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">or</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.87in;left:6.71in;width:0.42in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">other</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.87in;left:7.13in;width:0.41in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">news</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.08in;left:1.36in;width:1.68in;line-height:0.14in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Symbol;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.07in;left:1.36in;width:1.68in;line-height:0.17in;"><div style="position:relative; left:0.27in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Measuring interest</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div></div>
<div style="position:absolute;top:7.07in;left:3.04in;width:1.23in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">and engagement</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.07in;left:4.28in;width:1.18in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">in our Services,</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.07in;left:5.45in;width:2.51in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">including analyzing your usage of</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.28in;left:1.64in;width:0.98in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">the Services;</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.50in;left:1.36in;width:2.19in;line-height:0.14in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Symbol;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.49in;left:1.36in;width:2.19in;line-height:0.17in;"><div style="position:relative; left:0.27in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Developing new products</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div></div>
<div style="position:absolute;top:7.49in;left:3.55in;width:0.93in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">and services</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.49in;left:4.49in;width:1.38in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">and improving the</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.49in;left:5.86in;width:0.15in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">S</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.49in;left:5.97in;width:0.61in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">ervices;</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.71in;left:1.36in;width:1.57in;line-height:0.14in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Symbol;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.70in;left:1.36in;width:1.57in;line-height:0.17in;"><div style="position:relative; left:0.27in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Ensuring internal</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div></div>
<div style="position:absolute;top:7.70in;left:2.93in;width:1.11in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">quality control</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.70in;left:4.05in;width:0.84in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">and safety;</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.92in;left:1.36in;width:3.19in;line-height:0.14in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Symbol;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.91in;left:1.36in;width:3.19in;line-height:0.17in;"><div style="position:relative; left:0.27in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Authenticating and verifying individual</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div></div>
<div style="position:absolute;top:7.91in;left:4.56in;width:0.76in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">identities;</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.13in;left:1.36in;width:1.24in;line-height:0.14in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Symbol;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.12in;left:1.36in;width:1.24in;line-height:0.17in;"><div style="position:relative; left:0.27in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Carrying out</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div></div>
<div style="position:absolute;top:8.12in;left:2.61in;width:0.52in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">audits;</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.34in;left:1.36in;width:4.36in;line-height:0.14in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Symbol;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.33in;left:1.36in;width:4.36in;line-height:0.17in;"><div style="position:relative; left:0.27in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Communicating with you about your account, activities</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div></div>
<div style="position:absolute;top:8.33in;left:5.72in;width:1.18in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">on our Services</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.33in;left:6.90in;width:0.90in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">and Privacy</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.54in;left:1.64in;width:1.19in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Policy changes;</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.75in;left:1.36in;width:2.58in;line-height:0.14in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Symbol;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.75in;left:1.36in;width:2.58in;line-height:0.17in;"><div style="position:relative; left:0.27in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Preventing and prosecuting pot</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div></div>
<div style="position:absolute;top:8.75in;left:3.91in;width:1.58in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">entially prohibited or</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.75in;left:5.49in;width:0.49in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">illegal</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.75in;left:5.98in;width:0.75in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">activities;</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.97in;left:1.36in;width:2.25in;line-height:0.14in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Symbol;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.96in;left:1.36in;width:2.25in;line-height:0.17in;"><div style="position:relative; left:0.27in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Enforcing our agreements;</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div></div>
<div style="position:absolute;top:8.96in;left:3.62in;width:0.31in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">and</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:9.18in;left:1.36in;width:2.19in;line-height:0.14in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Symbol;color:#000000"> </span><br/></div>
<div style="position:absolute;top:9.17in;left:1.36in;width:2.19in;line-height:0.17in;"><div style="position:relative; left:0.27in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Complying with our legal</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div></div>
<div style="position:absolute;top:9.17in;left:3.55in;width:0.85in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">obligations</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:9.59in;left:1.09in;width:6.97in;line-height:0.17in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Managing Workspaces for Organizations:</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> If you register a SyncroCal account with an email</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:9.80in;left:1.09in;width:6.98in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">address provisioned by your organization, SyncroCal may share information about you and any</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:10.00in;left:1.09in;width:7.04in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Workspaces owned or managed by you with your organization.  Such information could include</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:10.22in;left:1.09in;width:6.89in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">your name, email address, and profile picture, as well as the Workspace name, Workspace ID,</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:10.42in;left:1.09in;width:6.64in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">membership size, creation date of your Workspace, email address, and content within your</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:10.64in;left:1.09in;width:2.66in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Workspace with your organization. </span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.15in;left:1.05in;"><a name="2._How_we_use_your_information"><span style="font-style:normal;font-weight:normal;font-size:5pt;font-family:Times New Roman;color:#000000">&nbsp</span></a></div>
<div style="position:absolute;top:1.09in;left:1.09in;width:6.92in;line-height:0.17in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Marketing of Products and Services:</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> We may use personal information to tailor and provide</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:1.30in;left:1.09in;width:7.08in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">you with content and advertisements. If you have any questions about our marketing practices or</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:1.51in;left:1.09in;width:7.02in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">if you would like to opt out of the use of your personal information for marketing purposes, you</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:1.72in;left:1.09in;width:2.52in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">may contact us as set forth below.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.14in;left:1.09in;width:6.97in;line-height:0.17in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Consent:</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> We may use personal information for other purposes that are clearly disclosed to you</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.36in;left:1.09in;width:4.89in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">at the time you provide personal information or with your consent.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.77in;left:1.09in;width:6.96in;line-height:0.17in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">De</span><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">-identified and Aggregated Information Use:</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> We may use personal information and other</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.98in;left:1.09in;width:6.58in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">data about you to create de-identified and/or aggregated information. De-identified and/or</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:3.19in;left:1.09in;width:6.32in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">aggregated information is not personal information, and we may use and disclose such</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:3.40in;left:1.09in;width:6.95in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">information in a number of ways, including research, internal analysis, analytics, and any other</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:3.61in;left:1.09in;width:2.16in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">legally permissible purposes.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.03in;left:1.09in;width:6.71in;line-height:0.17in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Process Information on Behalf of Our Customers:</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> Our customers may choose to use our</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.24in;left:1.09in;width:6.74in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Services to process certain data of their own, which may contain personal information. Such</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.45in;left:1.09in;width:6.46in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">personal information that is processed by us on behalf of our customers, and our privacy</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.66in;left:1.09in;width:6.80in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">practices will be governed by the contracts that we have in place with our customers, not this</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.87in;left:1.09in;width:1.15in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Privacy Policy.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.29in;left:1.09in;width:7.03in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">If you have any questions or concerns about how such personal information is handled or would</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.50in;left:1.09in;width:7.06in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">like to exercise your rights, you should contact the person or entity (i.e., the data controller) who</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.71in;left:1.09in;width:6.96in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">has contracted with us to use the Service to process this information. Our customers control the</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.92in;left:1.09in;width:6.81in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">personal information in these cases and determine the security settings within the account, its</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.12in;left:1.09in;width:7.13in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">access controls and credentials. We will, however, provide assistance to our customers to address</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.34in;left:1.09in;width:6.19in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">any concerns you may have, in accordance with the terms of our contract with them. </span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.76in;left:1.09in;width:6.65in;line-height:0.17in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">How We Use Automatic Collection Technologies:</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> Our uses of Technologies fall into the</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.97in;left:1.09in;width:2.17in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">following general categories:</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.39in;left:1.36in;width:2.16in;line-height:0.14in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Symbol;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.39in;left:1.36in;width:2.16in;line-height:0.17in;"><div style="position:relative; left:0.27in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Operationally Necessary;</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div></div>
<div style="position:absolute;top:7.60in;left:1.36in;width:0.42in;line-height:0.14in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Symbol;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.59in;left:1.36in;width:0.42in;line-height:0.17in;"><div style="position:relative; left:0.27in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">P</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div></div>
<div style="position:absolute;top:7.59in;left:1.74in;width:0.87in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">erformance</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.59in;left:2.61in;width:0.65in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Related;</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.81in;left:1.36in;width:2.24in;line-height:0.14in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Symbol;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.80in;left:1.36in;width:2.24in;line-height:0.17in;"><div style="position:relative; left:0.27in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Functionality Related; and</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div></div>
<div style="position:absolute;top:8.02in;left:1.36in;width:1.38in;line-height:0.14in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Symbol;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.01in;left:1.36in;width:1.38in;line-height:0.17in;"><div style="position:relative; left:0.27in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Advertising or</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div></div>
<div style="position:absolute;top:8.01in;left:2.74in;width:1.40in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Targeting Related.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.43in;left:1.09in;width:6.92in;line-height:0.17in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Cross-Device Tracking:</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> Your browsing activity may be tracked across different websites and</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.64in;left:1.09in;width:7.07in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">different devices or apps. For example, we may attempt to match your browsing activity on your</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.85in;left:1.09in;width:6.83in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">mobile device with your browsing activity on your laptop. To do this our technology partners</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:9.06in;left:1.09in;width:6.76in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">may share data, such as your browsing patterns, geo-location and device identifiers, and will</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:9.27in;left:1.09in;width:6.78in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">match the information of the browser and devices that appear to be used by the same person.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:9.71in;left:1.09in;width:5.42in;line-height:0.24in;"><span style="font-style:normal;font-weight:bold;font-size:18pt;font-family:Times New Roman;color:#000000">3. Disclosing your information to third parties</span><span style="font-style:normal;font-weight:bold;font-size:18pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:10.22in;left:1.09in;width:6.37in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">We may share your personal information with the following categories of third parties: </span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:9.70in;left:1.05in;"><a name="3._Disclosing_your_information_to_third_parties"><span style="font-style:normal;font-weight:normal;font-size:5pt;font-family:Times New Roman;color:#000000">&nbsp</span></a></div>
<div style="position:absolute;top:1.09in;left:1.09in;width:7.09in;line-height:0.17in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Service Providers:</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> We may share any personal information we collect about you with our third-</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:1.30in;left:1.09in;width:6.49in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">party service providers. The categories of service providers to whom we entrust personal</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:1.51in;left:1.09in;width:6.93in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">information include service providers for: (i) the provision of the Services; (ii) the provision of</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:1.72in;left:1.09in;width:6.83in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">information, products, and other services you have requested; (iii) marketing and advertising;</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:1.93in;left:1.09in;width:7.09in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">(iv) payment and transaction processing; (v) customer service activities; and (vi) the provision of</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.14in;left:1.09in;width:1.74in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">IT and related services.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.56in;left:1.09in;width:6.90in;line-height:0.17in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Business Partners:</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> We may provide personal information to business partners to provide you</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.77in;left:1.09in;width:6.69in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">with a product or service you have requested. We may also provide personal information to</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.98in;left:1.09in;width:4.88in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">business partners with whom we jointly offer products or services. </span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:3.40in;left:1.09in;width:5.44in;line-height:0.17in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Affiliates:</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> We may share personal information with our affiliated entities.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:3.82in;left:1.09in;width:6.82in;line-height:0.17in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Advertising Partners:</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> We do not share your information, including personal information, to</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.03in;left:1.09in;width:6.74in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">advertise any third party's products or services via the Services. We may use and share your</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.24in;left:1.09in;width:7.01in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">personal information with third-party advertising partners to market our own Services and grow</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.45in;left:1.09in;width:7.02in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">our Services' user base, such as to provide targeted marketing about our own Services via third-</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.66in;left:1.09in;width:6.94in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">party services. If you prefer not to share your personal information with third-party advertising</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.87in;left:1.09in;width:3.57in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">partners, you may follow the instructions below.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.29in;left:1.09in;width:7.08in;line-height:0.16in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">We may share your personal information with other third parties, including other users, in</span><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.50in;left:1.09in;width:2.26in;line-height:0.17in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">the following circumstances:</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.92in;left:1.09in;width:6.33in;line-height:0.17in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Workspaces Accessible by Other Users:</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> When you submit personal information in a</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.13in;left:1.09in;width:6.97in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">workspace that can be accessed by others, such personal information may be displayed to other</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.34in;left:1.09in;width:6.72in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">users in the same or connected workspaces. For example, your personal information may be</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.55in;left:1.09in;width:6.40in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">included in your notes or reminders in a workspace which can be viewed by other users</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.76in;left:1.09in;width:7.08in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">collaborating with you in that workspace. Further, your email address or photo may be displayed</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.97in;left:1.09in;width:6.19in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">with your workspace profile to other users collaborating with you in that workspace.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.39in;left:1.09in;width:7.10in;line-height:0.17in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Organizational Workspaces:</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> We allow organizations  </span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">to</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> access the profile information of users</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.59in;left:1.09in;width:6.52in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">within their workspace, including to allow such organization to validate that such user  is</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.81in;left:1.09in;width:4.44in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">actually authorized by such organization to use the Services.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.23in;left:1.09in;width:6.26in;line-height:0.17in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Share Content with Friends or Colleagues:</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> Our Services may allow you to provide</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.43in;left:1.09in;width:6.80in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">information about your friends, and may allow you to forward or share certain content with a</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.65in;left:1.09in;width:3.49in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">friend or colleague, such as an invitation email.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:9.07in;left:1.09in;width:6.90in;line-height:0.17in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Disclosures to Protect Us or Others:</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> We may access, preserve, and disclose any information</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:9.27in;left:1.09in;width:6.60in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">we store in association with you to external parties if we, in good faith, believe doing so is</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:9.48in;left:1.09in;width:6.78in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">required or appropriate to: (i) comply with law enforcement or national security requests and</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:9.69in;left:1.09in;width:7.10in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">legal process, such as a court order or subpoena; (ii) protect your, our, or others' rights, property,</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:9.90in;left:1.09in;width:7.12in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">or safety; (iii) enforce our policies or contracts; (iv) collect amounts owed to us; or (v) assist with</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:10.11in;left:1.09in;width:5.16in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">an investigation and prosecution of suspected or actual illegal activity. </span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:1.09in;left:1.09in;width:6.78in;line-height:0.17in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Disclosure in the Event of Merger, Sale, or Other Asset Transfer:</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> If we are involved in a</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:1.30in;left:1.09in;width:7.00in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">merger, acquisition, financing due diligence, reorganization, bankruptcy, receivership, purchase</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:1.51in;left:1.09in;width:6.96in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">or sale of assets, or transition of service to another provider, then your information may be sold</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:1.72in;left:1.09in;width:5.81in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">or transferred as part of such a transaction, as permitted by law and/or contract.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.15in;left:1.09in;width:3.72in;line-height:0.24in;"><span style="font-style:normal;font-weight:bold;font-size:18pt;font-family:Times New Roman;color:#000000">4. International Data Transfers</span><span style="font-style:normal;font-weight:bold;font-size:18pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.67in;left:1.09in;width:6.64in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">All information processed by us may be transferred, processed, and stored anywhere in the</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.88in;left:1.09in;width:6.84in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">world, including but not limited to, the United States or other countries, which may have data</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:3.09in;left:1.09in;width:6.95in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">protection laws that are different from the laws where you live. We endeavor to safeguard your</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:3.30in;left:1.09in;width:4.72in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">information consistent with the requirements of applicable laws. </span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:3.73in;left:1.09in;width:1.92in;line-height:0.24in;"><span style="font-style:normal;font-weight:bold;font-size:18pt;font-family:Times New Roman;color:#000000">5. Your Choices</span><span style="font-style:normal;font-weight:bold;font-size:18pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.24in;left:1.09in;width:6.44in;line-height:0.17in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">General:</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> You may have the right to object to or opt out of certain uses of your personal</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.45in;left:1.09in;width:7.09in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">information. Where you have consented to the processing of your personal information, you may</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.66in;left:1.09in;width:6.96in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">withdraw that consent at any time by contacting us as described below. Even if you opt out, we</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.87in;left:1.09in;width:7.00in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">may still collect and use n</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">on</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">-personal information regarding your activities on our Services and</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.08in;left:1.09in;width:3.23in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">for other legal purposes as described above.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.50in;left:1.09in;width:6.71in;line-height:0.17in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Email Communications:</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> If you receive an email from us and do not want to receive future</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.71in;left:1.09in;width:6.91in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">emails from us, you can use the unsubscribe link found at the bottom of the email to opt out of</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.92in;left:1.09in;width:6.47in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">receiving future emails. Note that you will continue to receive transaction-related emails</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.13in;left:1.09in;width:6.34in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">regarding products or services you have requested. We may also send you certain non-</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.34in;left:1.09in;width:6.97in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">promotional communications regarding us and our Services, and you will not be able to opt out</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.55in;left:1.09in;width:7.09in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">of those communications (e.g., communications regarding the Services or updates to this Privacy</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.76in;left:1.09in;width:0.62in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Policy).</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.17in;left:1.09in;width:6.91in;line-height:0.17in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Mobile Devices:</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> We may send you push notifications through our Apps. You may at any time</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.39in;left:1.09in;width:6.92in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">opt out from receiving these types of communications by changing the settings on your mobile</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.59in;left:1.09in;width:7.10in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">device. With your consent, we may also collect precise location information if you use our Apps.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.81in;left:1.09in;width:6.07in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">You may opt out of this collection by changing the settings on your mobile device.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.23in;left:1.09in;width:6.90in;line-height:0.17in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">"Do Not Track":</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> Your browser may offer you a "Do Not Track" option, which allows you to</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.43in;left:1.09in;width:6.63in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">signal to operators of websites and web applications and Service that you do not wish such</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.64in;left:1.09in;width:6.93in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">operators to track certain of your online activities over time and/or across different websites. If</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.85in;left:1.09in;width:7.08in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">your browser is set to "Do Not Track", Alchemist Growth Enterprises, Inc. will attempt to honor</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:9.06in;left:1.09in;width:6.90in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">this functionality. However, our third party service providers may use their own cookies, pixel</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:9.27in;left:1.09in;width:7.04in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">tags, web beacons or other storage technology to collect and store Log Data or information from</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:9.48in;left:1.09in;width:6.95in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">elsewhere on the internet, and we do not have access to, nor control over, a third parties' use of</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:9.69in;left:1.09in;width:2.87in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">cookies or other tracking technologies.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:10.12in;left:1.09in;width:2.73in;line-height:0.24in;"><span style="font-style:normal;font-weight:bold;font-size:18pt;font-family:Times New Roman;color:#000000">6. Your Privacy Rights</span><span style="font-style:normal;font-weight:bold;font-size:18pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.15in;left:1.05in;"><a name="4._International_Data_Transfers"><span style="font-style:normal;font-weight:normal;font-size:5pt;font-family:Times New Roman;color:#000000">&nbsp</span></a></div>
<div style="position:absolute;top:3.73in;left:1.05in;"><a name="5._Your_Choices"><span style="font-style:normal;font-weight:normal;font-size:5pt;font-family:Times New Roman;color:#000000">&nbsp</span></a></div>
<div style="position:absolute;top:10.11in;left:1.05in;"><a name="6._Your_Privacy_Rights"><span style="font-style:normal;font-weight:normal;font-size:5pt;font-family:Times New Roman;color:#000000">&nbsp</span></a></div>
<div style="position:absolute;top:1.09in;left:1.09in;width:6.92in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Depending upon your location and in accordance with applicable laws, you may have the right</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:1.30in;left:1.09in;width:0.24in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">to:</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:1.73in;left:1.36in;width:0.83in;line-height:0.14in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Symbol;color:#000000"> </span><br/></div>
<div style="position:absolute;top:1.72in;left:1.36in;width:0.83in;line-height:0.16in;"><div style="position:relative; left:0.27in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Access</span><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div></div>
<div style="position:absolute;top:1.72in;left:2.20in;width:0.66in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">personal</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:1.72in;left:2.86in;width:2.44in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">information about you consistent</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:1.72in;left:5.30in;width:0.76in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">with legal</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:1.72in;left:6.06in;width:1.04in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">requirements.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:1.72in;left:7.11in;width:0.88in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">In addition,</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:1.93in;left:1.64in;width:5.90in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">you may have the right in some cases to receive or have your electronic personal</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.14in;left:1.64in;width:2.98in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">information transferred to another party.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.36in;left:1.36in;width:0.94in;line-height:0.14in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Symbol;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.35in;left:1.36in;width:0.94in;line-height:0.16in;"><div style="position:relative; left:0.27in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Request</span><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div></div>
<div style="position:absolute;top:2.35in;left:2.31in;width:0.89in;line-height:0.16in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Correction</span><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.35in;left:3.20in;width:0.19in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">of</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.35in;left:3.40in;width:1.03in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">your personal</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.35in;left:4.44in;width:1.39in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">information where</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.35in;left:5.83in;width:0.14in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">it</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.35in;left:5.98in;width:0.16in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">is</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.35in;left:6.14in;width:0.79in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">inaccurate</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.35in;left:6.94in;width:1.09in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">or incomplete.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.57in;left:1.36in;width:0.94in;line-height:0.14in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Symbol;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.56in;left:1.36in;width:0.94in;line-height:0.16in;"><div style="position:relative; left:0.27in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Request</span><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div></div>
<div style="position:absolute;top:2.56in;left:2.31in;width:0.69in;line-height:0.16in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Deletion</span><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.56in;left:3.00in;width:0.19in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">of</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.56in;left:3.20in;width:1.03in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">your personal</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.56in;left:4.24in;width:0.95in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">information,</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.56in;left:5.18in;width:0.56in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">subject</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.56in;left:5.74in;width:1.54in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">to certain exceptions</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.56in;left:7.29in;width:0.80in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">prescribed</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.77in;left:1.64in;width:0.58in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">by law.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.99in;left:1.36in;width:0.94in;line-height:0.14in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Symbol;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.98in;left:1.36in;width:0.94in;line-height:0.16in;"><div style="position:relative; left:0.27in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Request</span><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div></div>
<div style="position:absolute;top:2.98in;left:2.31in;width:1.69in;line-height:0.16in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Restriction or Object</span><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.98in;left:4.00in;width:1.07in;line-height:0.16in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">to Processing</span><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.98in;left:5.07in;width:0.19in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">of</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.98in;left:5.27in;width:0.38in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">your</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.98in;left:5.65in;width:0.65in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">personal</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.98in;left:6.31in;width:0.94in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">information.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:3.20in;left:1.36in;width:0.83in;line-height:0.14in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Symbol;color:#000000"> </span><br/></div>
<div style="position:absolute;top:3.19in;left:1.36in;width:0.83in;line-height:0.16in;"><div style="position:relative; left:0.27in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Not be</span><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div></div>
<div style="position:absolute;top:3.19in;left:2.20in;width:1.78in;line-height:0.16in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Discriminated Against</span><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:3.19in;left:3.98in;width:0.69in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">by us for</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:3.19in;left:4.67in;width:2.26in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">exercising your privacy rights.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:3.61in;left:1.09in;width:6.83in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">If you would like to exercise any of these rights, please contact us as set forth below. We will</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:3.82in;left:1.09in;width:6.99in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">process such requests in accordance with applicable laws. To protect your privacy, we will take</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.03in;left:1.09in;width:6.80in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">steps to verify your identity before fulfilling your request, such as by requiring you to submit</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.24in;left:1.09in;width:2.25in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">your request via your account.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.67in;left:1.09in;width:2.11in;line-height:0.24in;"><span style="font-style:normal;font-weight:bold;font-size:18pt;font-family:Times New Roman;color:#000000">7. Data Retention</span><span style="font-style:normal;font-weight:bold;font-size:18pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.18in;left:1.09in;width:6.92in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">We store the personal information we receive as described in this Privacy Policy for as long as</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.39in;left:1.09in;width:6.99in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">you use our Services or as necessary to fulfill the purpose(s) for which it was collected, provide</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.60in;left:1.09in;width:6.49in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">our Services, resolve disputes, establish legal defenses, conduct audits, pursue legitimate</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.81in;left:1.09in;width:5.68in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">business purposes, enforce our agreements, and comply with applicable laws.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.25in;left:1.09in;width:3.71in;line-height:0.24in;"><span style="font-style:normal;font-weight:bold;font-size:18pt;font-family:Times New Roman;color:#000000">8. Security of your Information</span><span style="font-style:normal;font-weight:bold;font-size:18pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.76in;left:1.09in;width:6.74in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">We take steps to ensure that your information is treated securely and in accordance with this</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.97in;left:1.09in;width:6.91in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Privacy Policy. Unfortunately, no system is 100% secure, and we cannot ensure or warrant the</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.17in;left:1.09in;width:7.00in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">security of any information you provide to us. To the fullest extent permitted by applicable law,</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.39in;left:1.09in;width:3.96in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">we do not accept liability for unauthorized disclosure.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.81in;left:1.09in;width:6.26in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">By using the Services or providing personal information to us, you agree that we may</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.01in;left:1.09in;width:6.66in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">communicate with you electronically regarding security, privacy, and administrative issues</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.23in;left:1.09in;width:7.04in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">relating to your use of the Services. If we learn of a security system's breach, we may attempt to</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.43in;left:1.09in;width:6.95in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">notify you electronically by posting a notice on the Services, by mail or by sending an email to</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.65in;left:1.09in;width:0.36in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">you.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:9.08in;left:1.09in;width:4.43in;line-height:0.24in;"><span style="font-style:normal;font-weight:bold;font-size:18pt;font-family:Times New Roman;color:#000000">9. Third-Party Websites/Applications</span><span style="font-style:normal;font-weight:bold;font-size:18pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:9.59in;left:1.09in;width:6.85in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">The Services may contain links to other websites/applications and other websites/applications</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:9.80in;left:1.09in;width:6.82in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">may reference or link to our Services. These third-party services are not controlled by us. We</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:10.01in;left:1.09in;width:7.05in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">encourage our users to read the privacy policies of each website and application with which they</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:10.22in;left:1.09in;width:7.05in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">interact. We do not endorse, screen or approve, and are not responsible for, the privacy practices</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:10.43in;left:1.09in;width:7.04in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">or content of such other websites or applications. Visiting these other websites or applications is</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:10.64in;left:1.09in;width:1.28in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">at your own risk.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.67in;left:1.05in;"><a name="7._Data_Retention"><span style="font-style:normal;font-weight:normal;font-size:5pt;font-family:Times New Roman;color:#000000">&nbsp</span></a></div>
<div style="position:absolute;top:6.24in;left:1.05in;"><a name="8._Security_of_your_Information"><span style="font-style:normal;font-weight:normal;font-size:5pt;font-family:Times New Roman;color:#000000">&nbsp</span></a></div>
<div style="position:absolute;top:9.06in;left:1.05in;"><a name="9._Third-Party_Websites/Applications"><span style="font-style:normal;font-weight:normal;font-size:5pt;font-family:Times New Roman;color:#000000">&nbsp</span></a></div>
<div style="position:absolute;top:1.11in;left:1.09in;width:3.20in;line-height:0.24in;"><span style="font-style:normal;font-weight:bold;font-size:18pt;font-family:Times New Roman;color:#000000">10. Children&apos;s Information</span><span style="font-style:normal;font-weight:bold;font-size:18pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:1.62in;left:1.09in;width:7.10in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">The Services are not directed to children under 13 (or other age as required by local law), and we</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:1.83in;left:1.09in;width:6.80in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">do not knowingly collect personal information from children. If you learn that your child has</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.04in;left:1.09in;width:6.75in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">provided us with personal information without your consent, you may contact us as set forth</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.25in;left:1.09in;width:7.12in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">below. If we learn that we have collected a child's personal information in violation of applicable</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.46in;left:1.09in;width:6.86in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">law, we will promptly take steps to delete such information and terminate the child's account.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.89in;left:1.09in;width:3.11in;line-height:0.24in;"><span style="font-style:normal;font-weight:bold;font-size:18pt;font-family:Times New Roman;color:#000000">11. Supervisory Authority</span><span style="font-style:normal;font-weight:bold;font-size:18pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:3.40in;left:1.09in;width:6.63in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">If you are located in the European Economic Area or the UK, you have the right to lodge a</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:3.61in;left:1.09in;width:6.25in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">complaint with a supervisory authority if you believe our processing of your personal</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:3.82in;left:1.09in;width:2.66in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">information violates applicable law.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.26in;left:1.09in;width:3.45in;line-height:0.24in;"><span style="font-style:normal;font-weight:bold;font-size:18pt;font-family:Times New Roman;color:#000000">12. California Privacy Notice</span><span style="font-style:normal;font-weight:bold;font-size:18pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.77in;left:1.09in;width:6.54in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">This Privacy Notice applies to California consumers and supplements the Privacy Policy. </span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.19in;left:1.09in;width:7.11in;line-height:0.17in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">California Consumer Privacy Act (CCPA):</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> In the preceding 12 months, we have not disclosed</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.40in;left:1.09in;width:6.92in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">your personal information to any third party in a manner that would be considered a sale under</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.61in;left:1.09in;width:0.83in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">the CCPA.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.04in;left:1.09in;width:2.90in;line-height:0.17in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Information We Collect and Disclose</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.45in;left:1.09in;width:6.90in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">For purposes of the CCPA, we have collected the following categories of consumers' personal</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.66in;left:1.09in;width:6.91in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">information, and disclosed the personal information to the following categories of third parties</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.87in;left:1.09in;width:3.32in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">for a business purpose, in the last 12 months:</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.33in;left:3.41in;width:0.76in;line-height:0.16in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Category</span><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.33in;left:6.94in;width:0.77in;line-height:0.16in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Disclosed</span><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.68in;left:1.12in;width:0.84in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Identifiers.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.58in;left:6.46in;width:1.37in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Service providers;</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.79in;left:6.46in;width:1.53in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">advertising partners.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.04in;left:1.12in;width:1.89in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Commercial information.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.04in;left:6.46in;width:1.37in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Service providers.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.40in;left:1.12in;width:3.27in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Internet or other electronic network activity.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.30in;left:6.46in;width:1.37in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Service providers;</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.51in;left:6.46in;width:1.53in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">advertising partners.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.87in;left:1.12in;width:1.33in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Geolocation data.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.76in;left:6.46in;width:1.37in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Service providers;</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.97in;left:6.46in;width:1.53in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">advertising partners.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:9.33in;left:1.12in;width:1.02in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Sensory data.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:9.22in;left:6.46in;width:1.37in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Service providers;</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:9.43in;left:6.46in;width:0.88in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">other users.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:9.69in;left:1.12in;width:3.60in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Professional or employment-related information.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:9.69in;left:6.46in;width:1.37in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Service providers.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:9.94in;left:1.12in;width:5.00in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Inferences drawn from other personal information to create a profile</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:10.15in;left:1.12in;width:1.37in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">about a consumer.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:9.94in;left:6.46in;width:1.37in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Service providers;</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:10.15in;left:6.46in;width:1.53in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">advertising partners.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:1.09in;left:1.05in;"><a name="10._Children_s_Information"><span style="font-style:normal;font-weight:normal;font-size:5pt;font-family:Times New Roman;color:#000000">&nbsp</span></a></div>
<div style="position:absolute;top:2.88in;left:1.05in;"><a name="11._Supervisory_Authority"><span style="font-style:normal;font-weight:normal;font-size:5pt;font-family:Times New Roman;color:#000000">&nbsp</span></a></div>
<div style="position:absolute;top:4.24in;left:1.05in;"><a name="12._California_Privacy_Notice"><span style="font-style:normal;font-weight:normal;font-size:5pt;font-family:Times New Roman;color:#000000">&nbsp</span></a></div>
<div style="position:absolute;top:1.13in;left:3.41in;width:0.76in;line-height:0.16in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Category</span><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:1.13in;left:6.94in;width:0.77in;line-height:0.16in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Disclosed</span><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:1.38in;left:1.12in;width:4.83in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Personal information categories listed in the categories above, but</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:1.59in;left:1.12in;width:5.26in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">references in the California Customer Records statute (Cal. Civ. Code §</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:1.80in;left:1.12in;width:0.94in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">1798.80(e)).</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:1.49in;left:6.46in;width:1.37in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Service providers;</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:1.70in;left:6.46in;width:1.53in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">advertising partners.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.27in;left:1.09in;width:4.07in;line-height:0.24in;"><span style="font-style:normal;font-weight:bold;font-size:18pt;font-family:Times New Roman;color:#000000">13. Changes to Our Privacy Policy</span><span style="font-style:normal;font-weight:bold;font-size:18pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.78in;left:1.09in;width:6.64in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">We may revise this Privacy Policy from time to time in our sole discretion. If there are any</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.99in;left:1.09in;width:6.88in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">material changes to this Privacy Policy, we will notify you as required by applicable law. You</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:3.20in;left:1.09in;width:7.10in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">understand and agree that you will be deemed to have accepted the updated Privacy Policy if you</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:3.41in;left:1.09in;width:5.11in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">continue to use the Services after the new Privacy Policy takes effect.</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:3.84in;left:1.09in;width:0.94in;line-height:0.17in;"><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">Co</span><span style="font-style:normal;font-weight:bold;font-size:12pt;font-family:Times New Roman;color:#000000">ntact us:</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.25in;left:1.09in;width:7.10in;line-height:0.17in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">If you have any questions about our privacy practices or this Privacy Policy, please contact us at:</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.46in;left:1.09in;width:2.12in;line-height:0.17in;"><a href="mailto:info@alchemistgrowth.com"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#0000ff">info@alchemistgrowth.com</span></a>
<a href="mailto:info@alchemistgrowth.com"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span></a>
<span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000"> </span><br/></div>
<a href="mailto:info@alchemistgrowth.com"></a>
<div style="position:absolute;top:4.89in;left:1.09in;width:2.41in;line-height:0.21in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Times New Roman;color:#000000">Last updated: January 25, 2022. </span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.05in;left:1.05in;"><a name="13._Changes_to_Our_Privacy_Policy"><span style="font-style:normal;font-weight:normal;font-size:5pt;font-family:Times New Roman;color:#000000">&nbsp</span></a></div>                                          

            </div>
    )
}
export default PrivacyPage;

