import React from "react";
import { useState, useEffect } from "react";
import urlConstants from "../config";


import ChangePayment from "./AccountSubComp/ChangePayment";
import CancelSubs from "./AccountSubComp/CancelSubs";
import Navbar from "./AccountSubComp/Navbar";

import axios from "axios";
import { useHistory } from "react-router-dom";

import "../css/Accounts/Account.css";


let Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function MainContainer() {
  const [buttonPopup, setButtonPopup] = useState(false);
  const [buttonPopup1, setButtonPopup1] = useState(false);

  const [personalInfo, setPersonalInfo] = useState({});
  const [subsInfo, setSubsInfo] = useState({});

  const history = useHistory();

  const changePassword = () => {
    history.push("/changePassword");
  };
  useEffect(() => {
    async function fetchData() {
      const response2 = await axios.get(
        `${urlConstants.REACT_APP_SERVER_ROOT_URI}/users/subscriptions`,
        {
          withCredentials: true,
        }
      );

      if (response2.data.subscriptions.data[0].status === "active") {
        setSubsInfo(response2.data.subscriptions.data[0]);
      } else if (response2.data.subscriptions.data[0].status === "canceled") {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("userData");

        history.push("/login");
      }
    }

    fetchData();
  }, [buttonPopup1, buttonPopup, history]);

  useEffect(() => {
    async function fetchMe() {
      const response = await axios.get(`${urlConstants.REACT_APP_SERVER_ROOT_URI}/users`, {
        withCredentials: true,
      });

      setPersonalInfo(response.data);
    }
    fetchMe();
  }, [buttonPopup, buttonPopup1]);

  return (
    <section>
      <Navbar backTo="dashboard" />
      <div className="main-container">
        <div className="svg-box">
          <svg
            width="112"
            height="24"
            viewBox="0 0 112 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 0H110C111.105 0 112 0.895431 112 2V2C112 3.10457 111.105 4 110 4H0V0Z"
              fill="#FFC4AF"
            />
            <path
              d="M0 20H58C59.1046 20 60 20.8954 60 22V22C60 23.1046 59.1046 24 58 24H0V20Z"
              fill="#F9DCAA"
            />
          </svg>
        </div>
        <div className="account-cont">
            <h1>Account Settings</h1>
          <div className="row" id="pageRow">
            <div className="col-12 d-flex justify-content-center align-items-center">
              <div className="acc-box">
                <div className="box-heading">
                {/* <h1>{"Joao Pedro"}</h1> */}
                  <h2>{personalInfo.firstName}{" "}{personalInfo.lastName} </h2>
                  <h3>
                    Member since{" "}
                    {
                      Months[
                        new Date(
                          subsInfo?.current_period_start * 1000
                        ).getUTCMonth()
                      ]
                    }{" "}
                    {new Date(
                      subsInfo?.current_period_start * 1000
                    ).getUTCDate()}
                    ,{" "}
                    {new Date(
                      subsInfo?.current_period_start * 1000
                    ).getUTCFullYear()}
                  </h3>{" "}
                  {/* {`${date}`} */}
                </div>
                <div className="box-creds">
                  <div className="credentials">
                    <div className="email-cont">
                      <label htmlFor="email">Email address:</label>
                      <input
                        type="email"
                        required
                        value={personalInfo.email}
                        // value={"purcinellisoftwares@gmail.com"}
                        disabled
                      />
                    </div>
                    <div className="password-cont">
                      <label htmlFor="password">Password:</label>
                      <div className="password-btn-cont">
                        <input
                          type="password"
                          value={`********************`}
                          disabled
                          required
                        />
                        <button
                          className="password-btn"
                          onClick={changePassword}
                        >
                          Change password
                        </button>
                      </div>
                    </div>
                    <div className="plan-cont">
                    <label htmlFor="text">Plan type:</label>
                      <div className="plan-box">
                        <div>
                          <h3>
                            {subsInfo?.plan?.interval === "year"
                              ? "Annual Plan"
                              : "Monthly Plan"}
                          </h3>
                          <p>
                            Your subscription will renew on{" "}
                            {
                              Months[
                                new Date(
                                  subsInfo?.current_period_end * 1000
                                ).getUTCMonth()
                              ]
                            }{" "}
                            {new Date(
                              subsInfo?.current_period_end * 1000
                            ).getUTCDate()}
                            ,{" "}
                            {new Date(
                              subsInfo?.current_period_end * 1000
                            ).getUTCFullYear()}
                          </p>
                          <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
                            fill="#1AACA9"
                          />
                          <path
                            d="M21.7212 11.9613C21.3499 11.5896 20.747 11.5898 20.3752 11.9613L14.3174 18.0193L11.625 15.327C11.2533 14.9552 10.6506 14.9552 10.2788 15.327C9.90706 15.6987 9.90706 16.3014 10.2788 16.6731L13.6442 20.0385C13.83 20.2243 14.0736 20.3174 14.3172 20.3174C14.5608 20.3174 14.8046 20.2245 14.9904 20.0385L21.7212 13.3075C22.0929 12.936 22.0929 12.3331 21.7212 11.9613Z"
                            fill="white"
                          />
                        </svg>
                        </div>
                      </div>
                    </div>
                    <div className="credit-cont">
                      <label htmlFor="text">
                        Credit card associated on file:
                      </label>
                      <div className="credit-btn-cont">
                        <input
                          type="text"
                          required
                          value={
                            subsInfo?.default_payment_method?.card.last4
                              ? `XXXX-XXXX-XXXX-${subsInfo?.default_payment_method?.card.last4}`
                              : `XXXX-XXXX-XXXX-XXXX`
                          }
                          disabled
                        />
                        <button
                          className="credit-btn"
                          onClick={() => setButtonPopup(true)}
                        >
                          Change payment
                        </button>
                      </div>
                      <ChangePayment
                        trigger={buttonPopup}
                        setTrigger={setButtonPopup}
                        subsInfo={subsInfo}
                      ></ChangePayment>
                    </div>
                  </div>
                </div>
                <div className="box-footer">
                  <div id="h1-main">
                    <h1 onClick={() => setButtonPopup1(true)}>
                      Cancel My subscription
                    </h1>
                  </div>{" "}
                  <CancelSubs
                    trigger={buttonPopup1}
                    setTrigger={setButtonPopup1}
                    subsInfo={subsInfo}
                  ></CancelSubs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainContainer;
