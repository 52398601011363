import React from "react";
import spinner from "../Logo/Spinner-1s-200px.gif";
import '../css/spinner.css'

const Spinner = () => {
  return (
    <div className="fp-container">
      <img src={spinner} alt="Loading....." className="fp-loader" />
    </div>
  );
};

export default Spinner;
