import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import urlConstants from "../config";


import Spinner from "react-bootstrap/Spinner";

const Redirect = () => {
  const [subsInfo, setSubsInfo] = useState({});
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      let result = await axios.get(`${urlConstants.REACT_APP_SERVER_ROOT_URI}/subscriptions`, {
        withCredentials: true,
      });
      if (result) {
        setSubsInfo(result.data.subscription);
      } else {
        history.push("/pay/plans");
      }
    }
    
    fetchData();


  }, []);

  useEffect(async () => {
    async function fetchDataHistory() {
      await subsInfo;
      if (subsInfo) {
        if (subsInfo.status === "active") {
          history.push("/dashboard");
        } else {
          history.push("/pay/plans");
        }
      }
    }
    fetchDataHistory();
  }, [subsInfo]);

  return (
    <div>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};

export default Redirect;