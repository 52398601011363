import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  handle2fa,
  handleErrorMessageOTP,
  handleResend2fa,
} from "../redux/actions/auth";
import { toast } from "react-toastify";

import "../css/fa2.css";
import { useEffect } from "react";

const Fa2 = () => {
  const [otp, setOtp] = useState(new Array(4).fill(""));

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const data = useSelector((state) => {
    return state;
  });

  useEffect(() => {
    if (data.user2faReducer.error) {
      toast.error(data.user2faReducer.error);
    }

    dispatch(handleErrorMessageOTP());
  }, [data.user2faReducer.error, dispatch]);

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  return (
    <>
      <div className="container d-flex justify-content-center align-items-center otp_container">
        <div className="row otp_row_1">
          <div className="col text-center px-0">
            <div className="dsgn">
              <svg
                width="112"
                height="4"
                viewBox="0 0 112 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 0H110C111.105 0 112 0.895431 112 2C112 3.10457 111.105 4 110 4H0V0Z"
                  fill="#FFC4AF"
                />
              </svg>
              <br />
              <svg
                width="60"
                height="4"
                viewBox="0 0 60 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 0H58C59.1046 0 60 0.895431 60 2C60 3.10457 59.1046 4 58 4H0V0Z"
                  fill="#F9DCAA"
                />
              </svg>
            </div>
            <h2 className="py-4 wel_head">Welcome to SyncroCal!</h2>
            <p className="pb-3 mx-3" id="codePara">
              Please enter the 4-digit code that was sent to your email.
            </p>

            {otp.map((data, index) => {
              return (
                <input
                  className="otp-field"
                  type="text"
                  name="otp"
                  maxLength="1"
                  key={index}
                  value={data}
                  onChange={(e) => handleChange(e.target, index)}
                  onFocus={(e) => e.target.select()}
                  style={{ borderBottom: "4px" }}
                />
              );
            })}

            <p className="btn_collection">
              <button
                className="btn btn-primary me-2"
                onClick={(e) => {
                  console.log(location.state.data);
                  dispatch(handleResend2fa(location.state.data));
                  toast.success("Resend OTP send successfully!!");
                }}
              >
                Resend Code
              </button>
              <button
                className="btn btn-primary ms-3"
                onClick={(e) => {
                  dispatch(
                    handle2fa(data.userRegisterReducer.data, otp, history)
                  );
                }}
              >
                Submit Code
              </button>
            </p>
            <p>
              <button
                className="btn btn-primary mr-2 clearBtn"
                onClick={(e) => setOtp([...otp.map((v) => "")])}
              >
                Clear Code
              </button>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Fa2;
