import React from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import urlConstants from "../config";


import axios from "axios";
const ResetPassword = (props) => {
  
  useEffect(() => {
    let verifyReset = async () => {
      try {
        await axios.post(`${urlConstants.REACT_APP_SERVER_ROOT_URI}/users/verify-reset`, {
          token: props.match.params.token,
        });
      } catch (err) {
        console.log(err);
      }
    };

    verifyReset();
  }, [props.match.params.token]);

  const resetPasswordHandler = async (e) => {
    e.preventDefault();

    if (e.target.newPassword.value === e.target.cnfnewPassword.value) {
      let response = await axios.post(
        `${urlConstants.REACT_APP_SERVER_ROOT_URI}/users/reset-password`,
        {
          token: props.match.params.token,
          password: e.target.newPassword.value,
        }
      );

      if (response.status === 200) {
        toast.success(response.data.message);
      }
    } else {
      toast.error("Error in reset password");
    }
  };

  return (
    <div>
      <div className="main-container">
        <div className="svg-box">
          <svg
            width="112"
            height="24"
            viewBox="0 0 112 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 0H110C111.105 0 112 0.895431 112 2V2C112 3.10457 111.105 4 110 4H0V0Z"
              fill="#FFC4AF"
            />
            <path
              d="M0 20H58C59.1046 20 60 20.8954 60 22V22C60 23.1046 59.1046 24 58 24H0V20Z"
              fill="#F9DCAA"
            />
          </svg>
        </div>
      </div>

      <div className="main-content">
        <div className="h1">
          <h1>Reset password</h1>
          <form className="content" onSubmit={resetPasswordHandler}>
            <div className="new-password-cont">
              <label htmlFor="password">
                New Password <span>*</span>
              </label>
              <input
                type="password"
                required
                name="newPassword"
                placeholder="Enter new password"
              />
            </div>
            <div className="new-password-cont1">
              <label htmlFor="password">
                Confirm New Password <span>*</span>
              </label>
              <input
                type="password"
                required
                name="cnfnewPassword"
                placeholder="Enter new password"
              />
            </div>
            <div className="updatePass-btn-cont">
              <button className="updatePass-btn" type="submit">
                Update Password
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
