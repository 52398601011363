import axios from "axios";
import jwtDefaultConfig from "./jwtDefaultConfig";

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false;

  // ** For Refreshing Token
  subscribers = [];

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };

    // ** Request Interceptor
    axios.interceptors.request.use(
      (config) => {
        // ** Get token from localStorage
        const accessToken = this.getToken();
        if (accessToken) {
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    // ** Add request/response interceptor
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        // ** const { config, response: { status } } = error
        const { config, response } = error;
        const originalRequest = config;
        // ** if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true;

            this.refreshToken().then((r) => {
              this.isAlreadyFetchingAccessToken = false;
              this.setToken(r.data.data.access_token);
              this.setRefreshToken(r.data.data.refresh_token);

              this.onAccessTokenFetched(r.data.accessToken);
            });
          }
          const retryOriginalRequest = new Promise((resolve) => {
            this.addSubscriber((accessToken) => {
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
              resolve(axios(originalRequest));
            });
          });
          return retryOriginalRequest;
        }
        return Promise.reject(error);
      }
    );
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) =>
      callback(accessToken)
    );
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName);
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName);
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value);
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value);
  }

  login(...args) {
    console.log(this.jwtConfig.loginEndpoint, ...args);
    console.log(`login args: ${args} `)
    return axios.post(this.jwtConfig.loginEndpoint, ...args, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
  }

  resend(...args) {
    return axios.post(this.jwtConfig.resendEndPoint, ...args, {
      headers: {
        "Content-type": "application/json",
      },
      withCredentials: true,
    });
  }

  fa2(...args) {
    return axios.post(this.jwtConfig.fa2EndPoint, ...args, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args, {
      headers: {
        "Content-type": "application/json",
      },
      withCredentials: true,
    });
  }

  refreshToken() {
    return axios.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
      withCredentials: true,
      headers: {
        "Content-type": "application/json",
      },
    });
  }

  dashboard() {
    return axios.get(this.jwtConfig.dashboard, {
      withCredentials: true,
    });
  }

  calendarData(...args) {
    return axios.post(this.jwtConfig.calendarEndPoint, ...args, {
      headers: {
        withCredentials: true,
      },
    });
  }
}
