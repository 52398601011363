import useJwt from "../../../auth/jwt/useJwt";

const config = new useJwt();

export const handleLogout = () => {
  return (dispatch) => {
    dispatch({
      type: "LOGOUT",
      [config.storageTokenKeyName]: null,
      [config.storageRefreshTokenKeyName]: null,
    });

    localStorage.pxoveItem("userData");
    localStorage.pxoveItem(config.storageTokenKeyName);
    localStorage.pxoveItem(config.storageRefreshTokenKeyName);
  };
};

export const handleResend2fa = (userId) => async (dispatch) => {
  try {
    const response = await config.jwt.resend({
      userId,
    });

    if (response.data.status) {
      dispatch({
        type: "USER_2fa_Resend",
      });
    }
  } catch (err) {
    dispatch({
      type: "USER_2fa_Resend_failure",
      payload: err.message,
    });
  }
};

export const handle2fa = (userId, code, history) => async (dispatch) => {
  try {
    dispatch({ type: "USER_2fa_REQUEST" });

    const OTP = code.join("");

    const { data } = await config.jwt.fa2({
      userId,
      OTP,
    });

    console.log(data);

    if (data.status) {
      dispatch({
        type: "USER_2fa_SUCCESS",
      });

      history.push("/login");
    } else {
      throw new Error("Code went wrong");
    }
  } catch (err) {
    console.log(err.message);
    dispatch({
      type: "USER_2fa_FAILURE",
      payload: err.message,
    });
  }
};

export const handleLogin = (username, password) => async (dispatch) => {
  try {
    dispatch({ type: "USER_LOGIN_REQUEST" });
    const response = await config.jwt
      .login({ username, password })
      .catch((err) => {
        console.log(err);
      });

    if (response.status === 200 && response.data.status) {
      dispatch({
        type: "USER_LOGIN_SUCCESS",
        err: "",
        username: response.data.data.val.email,
        config,
        [config.jwt.jwtConfig.storageTokenKeyName]:
          response.data.data.val[config.jwt.jwtConfig.storageTokenKeyName],
        [config.jwt.jwtConfig.storageRefreshTokenKeyName]:
          response.data.data.val[config.jwt.jwtConfig.storageRefreshTokenKeyName],
      });

      localStorage.setItem("userData", response.data.data.val.email);

      config.jwt.setToken(response.data.data.val.accessToken);
      config.jwt.setRefreshToken(response.data.data.val.refreshToken);
    } else {
      throw new Error(response.data.message);
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: "USER_LOGIN_FAILURE",
      payload: err.message,
    });
  }
};
export const handleRegister =
  (username, password, history) => async (dispatch) => {
    try {
      dispatch({ type: "USER_REGISTER_REQUEST" });

      const response = await config.jwt.register({ username, password });

      if (response.status) {
        dispatch({
          type: "USER_REGISTER_SUCCESS",
          payload: response.data.data.userId,
        });

        history.push({
          pathname: "/verify-email",
          state: {
            data: response.data.data.userId,
          },
        });
      } else {
        dispatch({
          type: "USER_REGISTER_FAILURE",
          payload: response.data.data.message,
        });
      }
    } catch (err) {
      dispatch({
        type: "USER_REGISTER_FAILURE",
        payload: err,
      });
      console.log(err)
    }
  };

export const handleStripe = (status) => {
  return (dispatch) => {
    dispatch({
      type: "USER_STRIPE",
      payload: status,
    });
  };
};

export const handleLoading = (status) => {
  return (dispatch) => {
    dispatch({
      type: "IS_LOADING",
      payload: status,
    });
  };
};

export const pxoveSelectedProduct = () => async (dispatch) => {
  dispatch({
    type: "CALENDAR_DATA_pxOVE",
  });
};

export const handleErrorMessage = () => async (dispatch) => {
  dispatch({
    type: "USER_REGISTER_MESS_CLEAR",
  });
};

export const handleErrorMessageLogin = () => async (dispatch) => {
  dispatch({
    type: "USER_LOGIN_MESS_CLEAR",
  });
};

export const handleErrorMessageOTP = () => async (dispatch) => {
  dispatch({
    type: "USER_OTP_MESS_CLEAR",
  });
};
