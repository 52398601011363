import axios from "axios";
import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import urlConstants from "../config";


class ProtectedRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      stateVal: false,
    };
  }

  async componentDidMount() {
    try {
      let result = await axios.get(`${urlConstants.REACT_APP_SERVER_ROOT_URI}/subscriptions`);

      let subscriptions = result.data.subscriptions;

      if (result.data.status) {
        for (let val of subscriptions.data) {
          if (val.status === "active") {
            this.setState({ stateVal: true });
          }
        }
      }

      this.setState({ loading: false });
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    const { component: Component, ...props } = this.props;

    const { loading, stateVal } = this.state;
    let isLoggedIn = this.props.isUserLoggedIn();

    return (
      <Route
        {...props}
        render={(props) =>
          isLoggedIn ? (
            loading ? (
              <div className="loadingContainer"></div>
            ) : stateVal ? (
              <Component {...props} />
            ) : (
              <Redirect to="/pay/plans" />
            )
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    );
  }
}

export default ProtectedRoute;
