import axios from "axios";
import moment from "moment-timezone";
import urlConstants from "../config";

const DELAY = 200;
let simulateErrors = false;

export async function requestEventsInRange(calendarId, serviceType) {
  return new Promise(async (resolve, reject) => {
    setTimeout(async () => {
      if (simulateErrors) {
        reject(new Error("error"));
      } else {
        if (serviceType === "google") {
          const response = await axios
            .get(
              `${urlConstants.REACT_APP_SERVER_ROOT_URI}/calendars/events/google`,
              {
                data: calendarId,
              },
              {
                withCredentials: true,
              }
            )
            .then((response) => {
              console.log(`This is the response from events/google: ${response}`)
              console.log(`This is the response from events/google data: ${response.data}`)

              let eventList = response.data.data.items.map((item) => {
                return {
                  id: item.id,
                  calendarId: '0',
                  title: item.summary,
                  start: item.start?.date
                    ? moment(item.start?.date)
                        .tz(moment.tz.guess())
                        .toISOString()
                    : moment.utc(item.start.dateTime).toISOString(),
                  end: item.end?.date
                    ? moment(item.end?.date).tz(moment.tz.guess()).toISOString()
                    : moment.utc(item.end.dateTime).toISOString(),
                  timeZone: moment.tz.guess(),
                  summary: item.summary,
                  category: "time",
                };
              });

              eventList = eventList.filter(
                (v, i, a) =>
                  a.findIndex((v2) =>
                    ["start", "title"].every((k) => v2[k] === v[k])
                  ) === i
              );

              resolve(eventList);
            })
            .catch((err) => {
              reject(new Error("Insufficient Permission for Google!!"));
            });
        } else if (serviceType === "microsoft") {
          await axios
            .get(
              `${urlConstants.REACT_APP_SERVER_ROOT_URI}/calendars/events/microsoft`,
              {
                data: calendarId,
              },
              {
                withCredentials: true,
              }
            )
            .then((response) => {
              let eventList = response.data.value.map((item) => {
                return {
                  id: item.id,
                  calendarId: '0',
                  title: item.subject,
                  start: item?.isAllDay
                    ? moment(item.start.dateTime)
                        .tz(moment.tz.guess())
                        .toISOString()
                    : moment.utc(item.start.dateTime).toISOString(),
                  end: item?.isAllDay
                    ? moment(item.end.dateTime)
                        .tz(moment.tz.guess())
                        .toISOString()
                    : moment.utc(item.end.dateTime).toISOString(),
                  category: "time",
                };
              });
              resolve(eventList);
            })
            .catch((err) => {
              console.log("rejected");
              reject(new Error("Insufficient Permission for Microsoft"));
            });
        } else {
          console.log("we are in the calendar events apple section");

          await axios
            .get(
              `${urlConstants.REACT_APP_SERVER_ROOT_URI}/calendars/events/apple`,
              {
                data: calendarId,
              },
              {
                withCredentials: true,
              }
            )
            .then((response) => {
              let eventList = response.data.data.map((item) => {
                return {
                  id: item.id,
                  title: item.summary,
                  start: item.start.dateTime,
                  end: item.end.dateTime,
                };
              });

              resolve(eventList);
            })
            .catch((err) => {
              console.log(err);
              console.log("rejected");
              reject(new Error("Insufficient Permissions for apple!!"));
            });
        }
      }
    }, DELAY);
  });
}
