import React, { useState } from "react";
import { handleLoading } from "../redux/actions/auth";
import { useDispatch } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import CountryList from '../utils/CountryList'

import { Redirect, useLocation } from "react-router-dom";
import {
  CardElement,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

import NavBar from "./AccountSubComp/Navbar";
import "../css/newSub.css";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#000000",
      color: "#000000",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#000000",
      },
      "::placeholder": {
        color: "#00000",
      },
    },
    invalid: {
      iconColor: "#000000",
      color: "#000000",
    },
  },
};

const CardField = ({ onChange }) => (
  <div className="FormRow">
    <CardElement options={CARD_OPTIONS} onChange={onChange} />
  </div>
);

const SubmitButton = ({ processing, error, children, disabled }) => (
  <button
    className={`SubmitButton ${error ? "SubmitButton--error" : ""}`}
    type="submit"
    id="payButton"
    disabled={processing || disabled}
  >
    {processing ? "Processing..." : children}
  </button>
);

const ErrorMessage = ({ children }) => (
  <div className="ErrorMessage" role="alert">
    <svg width="16" height="16" viewBox="0 0 17 17">
      <path
        fill="#FFF"
        d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
      />
      <path
        fill="#6772e5"
        d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
      />
    </svg>
    {children}
  </div>
);

const SubscribeNew = ({ data }) => {
  const [clientSecret] = useState(data.clientSecret);
  const [subscriptionId] = useState(data.subscriptionId);
  const [paymentIntent, setPaymentIntent] = useState();

  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const [errorNumberMess, setErrorNumberMess] = useState(null);
  const [errorCvvMess, setErrorCvvMess] = useState(null);
  const [errorexpiryMess, setExpiryMess] = useState(null);

  const [cardNumberComplete, setCardNumberComplete] = useState(false);
  const [cardCvvComplete, setCardCvvComplete] = useState(false);
  const [cardExpiryComplete, setExpiryComplete] = useState(false);

  const [processing, setProcessing] = useState(false);
  const [billingDetails, setBillingDetails] = useState({
    email: "",
    phone: "",
    name: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    if (errorNumberMess || errorCvvMess || errorexpiryMess) {
      elements.getElement("cardNumber").focus();
      return;
    }

    if (cardNumberComplete && cardCvvComplete && cardExpiryComplete) {
      setProcessing(true);
    }

    // console.log(CardElement);
    // console.log(elements.getElement(CardElement));

    let { error, paymentIntent } = await stripe.confirmCardPayment(
      clientSecret,
      {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: billingDetails,
        },
      }
    );

    console.log(error, paymentIntent);

    if (error) {
      setErrorNumberMess(error.message);
      return;
    }

    setProcessing(false);
    setPaymentIntent(paymentIntent);
  };

  if (paymentIntent && paymentIntent.status === "succeeded") {
    dispatch(handleLoading(true));
    return <Redirect to={{ pathname: "/dashboard" }} />;
  }

  return (
    <>
      <NavBar />
      <div className="svgDesign">
        <svg
          width="112"
          height="24"
          viewBox="0 0 112 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          id="firstSVG"
        >
          <path
            d="M0 0H110C111.105 0 112 0.895431 112 2C112 3.10457 111.105 4 110 4H0V0Z"
            fill="#FFC4AF"
          />
          <path
            d="M0 20H58C59.1046 20 60 20.8954 60 22C60 23.1046 59.1046 24 58 24H0V20Z"
            fill="#F9DCAA"
          />
        </svg>
      </div>
      <div className="subs-container">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="text-center p-0 mb-4 main-heading">
                Sign up for an account
              </h3>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12 d-flex justify-content-center align-items-center">
                <div className="subBox">
                  <div className="boxHeading">
                    <h3
                      className="text-center p-0 main-heading"
                      id="main-heading"
                    >
                      Payment Method:
                    </h3>
                  </div>
                  <div className="d-flex flex-column mainSection">
                    <form onSubmit={handleSubmit}>
                      <div className="element">
                        <label
                          htmlFor="email"
                          className="elemHeading"
                          id="email"
                        >
                          <h4>
                            Email Address <span>*</span>
                          </h4>
                        </label>
                        <div className="elem">
                          <input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="name@domain.com"
                            autoComplete="email"
                            required
                            value={billingDetails.email}
                            onChange={(e) => {
                              setBillingDetails({
                                ...billingDetails,
                                email: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="row mx-0 d-flex flex-row" id="section2">
                        <div className="col-6 px-0" id="section2a">
                          <div className="element">
                            <label
                              htmlFor="card"
                              className="elemHeading"
                              id="card"
                            >
                              <h4>
                                Credit Card Number <span>*</span>
                              </h4>
                            </label>
                            <div
                              className="elem"
                              style={{ borderBottom: "1px solid rgba(152, 155, 176, 1)" }}
                            >
                              <CardNumberElement
                                options={CARD_OPTIONS}
                                className="stripeInput"
                                onChange={(e) => {
                                  setCardNumberComplete(e.complete);
                                  setErrorNumberMess(e.error);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-6 mx-0 px-0 d-flex flex-row"
                          id="section2b"
                        >
                          <div className="col-6 mx-0 px-0 section2bi">
                            <div className="element exp">
                              <label
                                htmlFor="expDate"
                                className="elemHeading"
                                id="expDate"
                              >
                                <h4>
                                  Expiration Date <span>*</span>
                                </h4>
                              </label>
                              <div
                                className="elem cardExpiry"
                                style={{ borderBottom: "1px solid rgba(152, 155, 176, 1)"}}
                              >
                                <CardExpiryElement
                                className="stripeInput"
                                  options={CARD_OPTIONS}
                                  onChange={(e) => {
                                    setExpiryMess(e.error);
                                    setExpiryComplete(e.complete);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-6 mx-0 px-0 section2bii">
                            <div className="element cvccvv">
                              <label
                                htmlFor="cvv"
                                className="elemHeading"
                                id="cvv"
                              >
                                <h4>
                                  CVC/CVV <span>*</span>
                                </h4>
                              </label>
                              <div
                                className="elem"
                                style={{ borderBottom: "1px solid rgba(152, 155, 176, 1)"}}
                              >
                                <CardCvcElement
                                  className="stripeInput"
                                  options={CARD_OPTIONS}
                                  onChange={(e) => {
                                    setCardCvvComplete(e.complete);
                                    setErrorCvvMess(e.error);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="element">
                        <label
                          htmlFor="chname"
                          className="elemHeading"
                          id="chname"
                        >
                          <h4>
                            Cardholder Name <span>*</span>
                          </h4>
                        </label>
                        <div className="elem">
                          <input
                            type="text"
                            name="chname"
                            id="chname"
                            placeholder="Enter Full Name"
                            required
                            autoComplete="name"
                            value={billingDetails.name}
                            onChange={(e) => {
                              setBillingDetails({
                                ...billingDetails,
                                name: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="row mx-0">
                        <div className="col-6 gl-0 zipCode">
                          <div className="element">
                            <label
                              htmlFor="zip"
                              className="elemHeading"
                              id="zip"
                            >
                              <h4>
                                Zip Code <span>*</span>
                              </h4>
                            </label>
                            <div className="elem">
                              <input
                                type="text"
                                name="zip"
                                id="zip"
                                placeholder="eg. 07621"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-6  cname">
                          <div className="element">
                            <label
                              htmlFor="country"
                              className="elemHeading"
                              id="country"
                            >
                              <h4>
                                Country <span>*</span>
                              </h4>
                            </label>
                            <div className="elem">
                              <select
                                name="country"
                                id="country"
                                className="decorated"
                              >
                                {
                                  CountryList.map((val, key) => {
                                    return val.code === "US" ? <option key={key} value={val.code} selected>
                                    {val.name}
                                  </option> : <option key={key} value={val.code}>
                                    {val.name}
                                  </option>
                                  })
                                }
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      {errorNumberMess && errorCvvMess && errorexpiryMess && (
                        <ErrorMessage>
                          {errorNumberMess.message ||
                            errorCvvMess.message ||
                            errorexpiryMess.message}
                        </ErrorMessage>
                      )}
                      <SubmitButton
                        processing={processing}
                        error={
                          errorNumberMess || errorexpiryMess || errorCvvMess
                        }
                        disabled={!stripe}
                      >
                        Check Out
                      </SubmitButton>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const stripePromise = loadStripe(
  "pk_test_51LASDISBC99v7w0zPaBuoYaGR9QGLoaZ0gk3PUhGKbAgT4Cd0ea5mzG2C8brwzNeflrXDj4BtGe3kFvTbzHN0KWC00EML5CRod"
);

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: "https://fonts.googleapis.com/css?family=Roboto",
    },
  ],
};

const NewSubscribe = () => {
  const location = useLocation();

  return (
    <div className="">
      <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
        <SubscribeNew data={location.state} />
      </Elements>
    </div>
  );
};

export default NewSubscribe;
