import React from 'react'
import '../../css/PriceCalc/PriceMain.css'
import PriceNav from './PriceNav'
import { useState } from "react"

function PriceMain() {
  const plans = [{
    tier: "1",
    users: "1-100",
    price: "6.50"
  },
  {
    tier: "2",
    users: "100-500",
    price: "6.00"
  },
  {
    tier: "3",
    users: "500-1,000",
    price: "5.50"
  },
  {
    tier: "4",
    users: "1,000-5,000",
    price: "5.00"
  },
  {
    tier: "5",
    users: "5,000-10,000",
    price: "4.50"
  },
  {
    tier: "6",
    users: "10,000-50,000",
    price: "4.00"
  },
  {
    tier: "7",
    users: "50,000-250,000",
    price: "3.50"
  },
  {
    tier: "8",
    users: "250,000+",
    price: "3.00"
  },
]

const [plan, setPlan] = useState({
  users: 0,
  price: "",
})

const [active, setActive] = useState(-1)

const handleUserInp = (e) => {
  let user = Number(e.target.value);
  switch (true) {
    case (user < 100):
      setPlan({
        ...plan,
        users: user,
        price: 6.50
      });
      setActive(0)
      break;

    case (user < 500):
      setPlan({
        ...plan,
        users:user,
        price: 6.00
      })
      setActive(1)
      break;

      case (user < 1000):
        setPlan({
          ...plan,
          users:user,
          price: 5.50
        })
        setActive(2)
        break;

      case (user < 5000):
          setPlan({
            ...plan,
            users:user,
            price: 5.00
          })
          setActive(3)
          break;

      case (user < 10000):
        setPlan({
          ...plan,
          users:user,
          price: 4.50
        })
        setActive(4)
        break;

      case (user < 50000):
        setPlan({
          ...plan,
          users:user,
          price: 4.00
        })
        setActive(5)
        break;

        case (user < 250000):
          setPlan({
            ...plan,
            users:user,
            price: 3.50
          })
          setActive(6)
          break;

          case (user >= 250000):
            setPlan({
              ...plan,
              users:user,
              price: 3.00
            })
            setActive(7)
            break;
  } 
}

const handleUserInc = (e) => {
  e.preventDefault();
  let user = Number(plan.users) + 1;
  switch (true) {
    case (user < 100):
      setPlan({
        ...plan,
        users: user,
        price: 6.50
      });
      setActive(0)
      break;

    case (user < 500):
      setPlan({
        ...plan,
        users:user,
        price: 6.00
      })
      setActive(1)
      break;

      case (user < 1000):
        setPlan({
          ...plan,
          users:user,
          price: 5.50
        })
        setActive(2)
        break;

      case (user < 5000):
          setPlan({
            ...plan,
            users:user,
            price: 5.00
          })
          setActive(3)
          break;

      case (user < 10000):
        setPlan({
          ...plan,
          users:user,
          price: 4.50
        })
        setActive(4)
        break;

      case (user < 50000):
        setPlan({
          ...plan,
          users:user,
          price: 4.00
        })
        setActive(5)
        break;

        case (user < 250000):
          setPlan({
            ...plan,
            users:user,
            price: 3.50
          })
          setActive(6)
          break;

          case (user >= 250000):
            setPlan({
              ...plan,
              users:user,
              price: 3.00
            })
            setActive(7)
            break;
  } 
}

const handleUserDec = (e) => {
  e.preventDefault();
  let user = Number(plan.users) - 1;
  switch (true) {
    case (user < 100):
      setPlan({
        ...plan,
        users: user,
        price: 6.50
      });
      setActive(0)
      break;

    case (user < 500):
      setPlan({
        ...plan,
        users:user,
        price: 6.00
      })
      setActive(1)
      break;

      case (user < 1000):
        setPlan({
          ...plan,
          users:user,
          price: 5.50
        })
        setActive(2)
        break;

      case (user < 5000):
          setPlan({
            ...plan,
            users:user,
            price: 5.00
          })
          setActive(3)
          break;

      case (user < 10000):
        setPlan({
          ...plan,
          users:user,
          price: 4.50
        })
        setActive(4)
        break;

      case (user < 50000):
        setPlan({
          ...plan,
          users:user,
          price: 4.00
        })
        setActive(5)
        break;

        case (user < 250000):
          setPlan({
            ...plan,
            users:user,
            price: 3.50
          })
          setActive(6)
          break;

          case (user >= 250000):
            setPlan({
              ...plan,
              users:user,
              price: 3.00
            })
            setActive(7)
            break;
  } 
}
  return (
    <main>
       <PriceNav />
        <section className="price-main-container">
          <svg width="112" height="24" viewBox="0 0 112 24" fill="none" xmlns="http://www.w3.org/2000/svg">
           <path d="M0 0H110C111.105 0 112 0.895431 112 2C112 3.10457 111.105 4 110 4H0V0Z" fill="#FFC4AF"/>
           <path d="M0 20H58C59.1046 20 60 20.8954 60 22C60 23.1046 59.1046 24 58 24H0V20Z" fill="#F9DCAA"/>
          </svg>
          <div className="container">
            <div className="pricing-box">
              <div className="pricing-head">
                <h1>Business Pricing</h1>
              </div>
              <div className="price-body">
                <h2>Please select a tier below to calculate your annual cost per user per month:</h2>
                <div className="price-tabs">
                    <div className="tab tab0">
                        <div className="row w-100">
                          <div className="col-4">
                          <p>Pricing Tier</p>
                          </div>
                          <div className="col-4">
                          <p>Number of Users</p>
                          </div>
                          <div className="col-4">
                          <p>Annual Plan <span id="tab0Head">(price/user/month)</span></p>
                          </div>
                        </div>
                    </div>
                    {
                      plans.map((val, key) => {
                        return <div className="tab w-tab" onClick={(e)=>{
                          e.preventDefault();
                          setPlan({
                            ...plan,
                            users: Number((val.users.replace(/,/g, '').split("-")[0])) || 250000,
                            price: Number(val.price),
                          })
                          setActive(key)
                        }} style={{backgroundColor: active === key ? "rgba(0, 133, 129, 1)" : "white", color: active == key ? "white" : "black"}}>
                        <div className="row w-100">
                          <div className="col-4">
                          <p>Tier {val.tier}</p>
                          </div>
                          <div className="col-4">
                          <p>{(val.users)} users</p>
                          </div>
                          <div className="col-4">
                          <p>${val.price}</p>
                          </div>
                        </div>
                        </div>
                      })
                    }
                </div>
                <div className="calculator-container">
                  <div className="cal-head">
                    <h1>Pricing Calculator:</h1>
                  </div>
                  <div className="calculator-box">
                    <div className="price-range">
                        <h2>Number of users:</h2>
                        <div className="price-range-box">
                            <button className="plus arth-btn" onClick={handleUserInc}>+</button>
                            <input type="text" className='price-input' value={(plan.users)} onChange={handleUserInp} />
                            <button className="minus arth-btn" onClick={handleUserDec} >–</button>
                        </div>
                    </div>
                    <div className="price-display">
                        <h1>${(plan.users * plan.price).toLocaleString("en-US", {minimumFractionDigits:2,maximumFractionDigits:2})}<span id="priceMonth">/month</span></h1>
                        <h3>${(plan.users * plan.price * 12).toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})} billed annually</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    </main>
  )
}

export default PriceMain