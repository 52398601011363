import React from "react";
import axios from "axios";

import "../../css/Accounts/ChangePayment.css";
import { toast } from "react-toastify";
import urlConstants from "../../config";

function ChangePayment(props) {
  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      let updates = await axios.post(
        `${urlConstants.REACT_APP_SERVER_ROOT_URI}/updatePayment`,
        {
          cardNumber: e.target.input_card.value,
          exp: e.target.input_expire.value,
          cvc: e.target.input_cvv.value,
          subscriptionId: props.subsInfo.id,
        }
      );

      if (updates.data.subscription) {
        props.setTrigger(false);
      }
    } catch (err) {
      props.setTrigger(false);
      toast.error(err.message);
    }
  };

  return props.trigger ? (
    <>
    <div className="popup"></div>
      <form className="popup-inner" onSubmit={submitHandler}>
        <div className="popup-header">
          <h1>Change payment</h1>
          <button className="close-btn" onClick={() => props.setTrigger(false)}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.13754 17.9935C6.84334 18.0108 6.554 17.9119 6.33053 17.7175C5.88982 17.269 5.88982 16.5447 6.33053 16.0962L15.9807 6.33372C16.4391 5.89981 17.1583 5.92393 17.5873 6.38764C17.9751 6.80698 17.9977 7.4515 17.6402 7.89757L7.93318 17.7175C7.7126 17.9091 7.4279 18.0078 7.13754 17.9935Z"
                fill="white"
              />
              <path
                d="M16.7765 17.9938C16.4784 17.9925 16.1926 17.8728 15.9809 17.6604L6.33069 7.89783C5.92239 7.41549 5.9779 6.68959 6.45469 6.2765C6.88025 5.90783 7.50785 5.90783 7.93337 6.2765L17.6404 16.039C18.0986 16.473 18.1223 17.2007 17.6933 17.6643C17.6762 17.6827 17.6586 17.7006 17.6404 17.7178C17.4027 17.9269 17.0899 18.0269 16.7765 17.9938Z"
                fill="white"
              />
            </svg>
          </button>
          {props.children}
        </div>
        <div className="popup-cont">
          <div className="creditCard-cont p-content">
            <label htmlFor="text">
              Credit card number <span>*</span>
            </label>
            <input
              type="text"
              placeholder="1234 5678 1234 5678"
              required
              className="input_card"
              name="input_card"
            />
          </div>
          <div className="expiration-cont p-content">
            <label htmlFor="text">
              Expiration Date <span>*</span>
            </label>
            <input
              type="text"
              placeholder="01 / 25"
              required
              className="input_expire"
              name="input_expire"
            />
          </div>
          <div className="cvv-cont p-content">
            <label htmlFor="cvv">
              CVC/CVV <span>*</span>
            </label>
            <input
              type="password"
              placeholder="***"
              required
              className="input_cvv"
              name="input_cvv"
            />
          </div>
          <div className="holder-cont p-content">
            <label htmlFor="text">
              Cardholder Name <span>*</span>
            </label>
            <input
              type="text"
              placeholder="Donna Zitelli"
              required
              className="input_name"
              name="input_name"
            />
          </div>
          <div className="popup-btn p-content" style={{padding:"0px"}}>
          <button className="update-btn" type="submit">
            Update Payment
          </button>
        </div>
        </div>
      </form>
    </>
  ) : (
    ""
  );
}

export default ChangePayment;
