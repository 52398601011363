import React, {useState, useEffect} from "react";
import "../css/Paylog.css";
import axios from "axios";
import urlConstants from "../config";


import { useHistory} from "react-router-dom";

import NavBar from "./AccountSubComp/Navbar";

function PayLog() {
  const history = useHistory();
  const [plan, setPlan] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState(null);

  useEffect(() => {
    if (subscriptionData) {
      history.push("/subscribe", subscriptionData);
    }
  }, [subscriptionData]);

  const createSubscription = async (priceId) => {
    const { data } = await axios.post(`${urlConstants.REACT_APP_SERVER_ROOT_URI}/session`, {
      headers: {
        "Content-type": "application/json",
      },
      data: priceId,
    });

    setSubscriptionData(data);
  };

  const fetchPrices = async () => {
    const { data } = await axios.get(`${urlConstants.REACT_APP_SERVER_ROOT_URI}/prices`, {
      withCredentials: true,
    });
    console.log(data.prices);
    setPlan(data.prices)
  };

  useEffect(() => {
    fetchPrices();
  },[]);
  
  const clickHandler = (e) => {
    let priceId = e.target.id;
    createSubscription(priceId);
  };

  return (
    <>
      <NavBar/>
      <div className="svgDesign">
      <svg
          width="112"
          height="24"
          viewBox="0 0 112 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          id="firstSVG"
        >
          <path
            d="M0 0H110C111.105 0 112 0.895431 112 2C112 3.10457 111.105 4 110 4H0V0Z"
            fill="#FFC4AF"
          />
          <path
            d="M0 20H58C59.1046 20 60 20.8954 60 22C60 23.1046 59.1046 24 58 24H0V20Z"
            fill="#F9DCAA"
          />
        </svg>
      </div>
    <div className="paylog-container">
      <div className="pay-box">
        <svg
          width="112"
          height="24"
          viewBox="0 0 112 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0H110C111.105 0 112 0.895431 112 2C112 3.10457 111.105 4 110 4H0V0Z"
            fill="#FFC4AF"
          />
          <path
            d="M0 20H58C59.1046 20 60 20.8954 60 22C60 23.1046 59.1046 24 58 24H0V20Z"
            fill="#F9DCAA"
          />
        </svg>
        <h3>Sign up for an account</h3>
        <div className="payLog-innerBox">
          <div className="first-box">
            <h3>Please select a plan:</h3>
            <div className="month-cont">
              {plan?.map((price) => {
                return (
                  <div
                    key={price.id}
                    className="month-box-cont"
                    onClick={clickHandler}
                  >
                    <div className="month-box" id={price.id}>
                      {price.lookup_key}
                    </div>
                    <h5>
                      ${price.unit_amount / 100}/{price.recurring.interval},
                      renewed {price.lookup_key}{" "}
                    </h5>
                  </div>
                );
              })}
            </div>
            <div className="code-btn">
              <p>
              If your company has provided you with a code, please enter here to waive fees: 
              </p>
              <div className="continue-btn-pl">
                <input type="text" placeholder="Enter code here" />
                <button>
                  Continue
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.5 17L18.5 12.283L13.5 7"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <line
                      x1="16.5"
                      y1="12"
                      x2="6.5"
                      y2="12"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default PayLog;
