import React from "react";
import axios from "axios";
import { useDispatch } from "react-redux";

import "../../css/Accounts/CancelSubs.css";
import { toast } from "react-toastify";
import { handleStripe } from "../../redux/actions/auth";
import urlConstants from "../../config";

function CancelSubs(props) {
  const dispatch = useDispatch();
  const returnHandler = () => {
    props.setTrigger(true);
  };

  const cancelHandler = async () => {
    try {
      const result = await axios.post(`${urlConstants.REACT_APP_SERVER_ROOT_URI}/deleteSubs`, {
        subscriptionId: props.subsInfo.id,
      });

      if (result.data.deleted) {
        props.setTrigger(false);
        dispatch(handleStripe("false"));
      }
    } catch (err) {
      toast.error(err.message);
      props.setTrigger(false);
    }
  };

  return props.trigger ? (
    <>
    <div className="popup1"></div>
      <div className="popup-inner1">
        <div className="popup-header1">
          <svg
            width="112"
            height="24"
            viewBox="0 0 112 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 0H110C111.105 0 112 0.895431 112 2C112 3.10457 111.105 4 110 4H0V0Z"
              fill="#FFC4AF"
            />
            <path
              d="M0 20H58C59.1046 20 60 20.8954 60 22C60 23.1046 59.1046 24 58 24H0V20Z"
              fill="#F9DCAA"
            />
          </svg>
          <button
            className="close-btn1"
            onClick={() => props.setTrigger(false)}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.13754 17.9935C6.84334 18.0108 6.554 17.9119 6.33053 17.7175C5.88982 17.269 5.88982 16.5447 6.33053 16.0962L15.9807 6.33372C16.4391 5.89981 17.1583 5.92393 17.5873 6.38764C17.9751 6.80698 17.9977 7.4515 17.6402 7.89757L7.93318 17.7175C7.7126 17.9091 7.4279 18.0078 7.13754 17.9935Z"
                fill="#0E0E2C"
              />
              <path
                d="M16.7765 17.9938C16.4784 17.9925 16.1926 17.8728 15.9809 17.6604L6.33069 7.89783C5.92239 7.41549 5.9779 6.68959 6.45469 6.2765C6.88025 5.90783 7.50785 5.90783 7.93337 6.2765L17.6404 16.039C18.0986 16.473 18.1223 17.2007 17.6933 17.6643C17.6762 17.6827 17.6586 17.7006 17.6404 17.7178C17.4027 17.9269 17.0899 18.0269 16.7765 17.9938Z"
                fill="#0E0E2C"
              />
            </svg>
          </button>
          {props.children}
        </div>
        <div className="popup-cont1">
          <h1 id="h1">Are you sure you want to cancel?</h1>
          <p>All of your calendars will be unsynced.</p>
          <div className="popup-btn1">
            <div
              className="update-btn1"
              onClick={() => {
                props.setTrigger(true);
              }}
            >
              Keep me synced
            </div>
            <h2 id="h2" onClick={cancelHandler}>
              Unsync me
            </h2>
          </div>
        </div>
      </div>
    </>
  ) : (
    ""
  );
}

export default CancelSubs;
