const initialState = {
  error: "",
  userData: {},
  tokenRegister: false,
  tokenLogin: false,
  status: "false",
  loading: false,
  tokenResend: false,
};

export const userResendReducer = (state = initialState, action) => {
  switch (action.type) {
    case "USER_2fa_Resend":
      return {
        ...state,
        tokenResend: true,
      };
    case "USER_2fa_Resend_failure":
      return {
        ...state,
        error: action.payload,
      };
    case "USER_2fa_MESS_CLEAR":
      return {
        ...state,
        error: "",
      };
    default:
      return state;
  }
};

export const userRegisterReducer = (state = initialState, action) => {
  switch (action.type) {
    case "USER_REGISTER_REQUEST":
      return state;
    case "USER_REGISTER_SUCCESS":
      return {
        ...state,
        data: action.payload,
        tokenRegister: true,
      };
    case "USER_REGISTER_FAILURE":
      return {
        ...state,
        error: action.payload,
      };
    case "USER_REGISTER_MESS_CLEAR":
      return {
        ...state,
        error: "",
      };
    default:
      return state;
  }
};

export const user2faReducer = (state = initialState, action) => {
  switch (action.type) {
    case "USER_2fa_REQUEST":
      return state;
    case "USER_2fa_SUCCESS":
      return {
        ...state,
        tokenLogin: true,
      };
    case "USER_2fa_FAILURE":
      return {
        ...state,
        error: action.payload,
      };
    case "USER_OTP_MESS_CLEAR":
      return {
        ...state,
        error: "",
      };
    default:
      return state;
  }
};

export const userLoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case "USER_LOGIN_REQUEST":
      return state;
    case "USER_LOGIN_SUCCESS":
      return {
        ...state,
        userData: { username: action.username },
        [action.config.jwt.jwtConfig.storageTokenKeyName]:
          action[action.config.jwt.jwtConfig.storageTokenKeyName],
        [action.config.jwt.jwtConfig.storageRefreshTokenKeyName]:
          action[action.config.jwt.jwtConfig.storageRefreshTokenKeyName],
      };
    case "USER_LOGIN_FAILURE":
      console.log(action.payload);
      return {
        ...state,
        error: action.payload,
      };
    case "LOGOUT":
      const obj = { ...action };
      delete obj.type;
      return { ...state, userData: {}, ...obj };
    case "USER_LOGIN_MESS_CLEAR":
      return {
        ...state,
        error: "",
      };
    default:
      return {
        ...state,
        userData: {},
        tokenRegister: false,
        tokenLogin: false,
      };
  }
};

export const handleStripeReducer = (state = initialState, action) => {
  switch (action.type) {
    case "USER_STRIPE":
      return {
        ...state,
        status: action.payload,
      };
    default:
      return { ...state };
  }
};

export const handleLoadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case "IS_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return { ...state };
  }
};
