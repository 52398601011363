import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import urlConstants from "../../config";


import Spinner from "react-bootstrap/Spinner";

const CallbackGoogleRedirect = () => {
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      let result = await axios.get(`${urlConstants.REACT_APP_SERVER_ROOT_URI}/auth/me/google`, {
        withCredentials: true,
      });
      // if (result) {
      //   setSubsInfo(result.data.subscription);
      // } else {
      //   history.push("/pay/plans");
      // }
      if (result.status == 200) {
        history.push("/dashboard")
      } else {
        history.push("/login");
      }
    }
    
    fetchData();


  }, []);

  // useEffect(async () => {
  //   async function fetchDataHistory() {
  //     await subsInfo;
  //     if (subsInfo) {
  //       if (subsInfo.status === "active") {
  //         history.push("/dashboard");
  //       } else {
  //         history.push("/pay/plans");
  //       }
  //     }
  //   }
  //   fetchDataHistory();
  // }, [subsInfo]);

  return (
    <div>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};

export default CallbackGoogleRedirect;