import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { createSelector } from "reselect";
import { Modal, ModalHeader, ModalBody, ModalTitle } from "react-bootstrap";

import Spinner from "../Spinner";
import { getHashValues } from "../../utils/utils";
import actionCreators from "../../redux/actions/calendar/index";

import "react-bootstrap-tagsinput/dist/index.css";
import "../../css/Privacy.css";
import "../../css/Search.css";
import downArrow from "../../css/images/icon.png";
import urlConstants from "../../config";

const mapStateToProps = () => {
  const getEventArray = createSelector(
    (state) => state.eventsById,
    getHashValues
  );

  return (state) => {
    return {
      events: getEventArray(state),
      weekendsVisible: state.weekendsVisible,
    };
  };
};

const reportNetworkError = (err) => {
  console.log(err);
  alert("This action could not be completed");
};

function Privacy(props) {
  const { open, events, setOpen, serviceType, fetchData, me } = props;

  const [shareUrl, setShareUrl] = useState("");
  const [privacyList, setPrivacyList] = useState([]);
  const [isLoading, setIsLoading] = useState(null);
  const [color, setColor] = useState([]);

  const changeHandler = (e) => {
    e.stopPropagation();
    let arr = [...privacyList];

    arr.map((item) => {
      if (
        item.serviceId ===
        e.target.parentNode.parentNode.parentNode.parentNode.id
      ) {
        item.privacy.map((value) => {
          if (
            value.email === e.target.parentNode.parentNode.id &&
            value.chooseType === "public"
          ) {
            value.chooseType = "private";
          } else if (
            value.email === e.target.parentNode.parentNode.id &&
            value.chooseType === "private"
          ) {
            value.chooseType = "public";
          }
          return value;
        });

        return item;
      }
    });

    setPrivacyList(arr);
    console.log(arr);
  };

  const handleChangeColor = (evt) => {
    evt.stopPropagation();
    let arrColors = [...color];

    arrColors.map((item) => {
      if (
        item.serviceId ===
          evt.target.parentNode.parentNode.parentNode.parentNode.id &&
        item.colorId !== evt.target.value
      ) {
        item.colorId = evt.target.value;
      }
      // console.log(`New item for color state array: ${item} and color: ${evt.target.value}`)
      return item;
    });

    const name = evt.target.name;
    const newValue = evt.target.value;
    // setColor({ [name]: newValue });
    setColor(arrColors);
    submitColor();
  };

  // const handleInput = evt => {
  //   const name = evt.target.name;
  //   const newValue = evt.target.value;
  //   const
  //   setColor({ [name]: newValue });
  // };

  const copyHandler = (e) => {
    let value = e.currentTarget.previousSibling;
    navigator.clipboard.writeText(value.innerHTML);

    toast.success("Copied to Clipboard!!");
  };

  const wait = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  // const checkStatus = async () => {
  //   if (me.response.identities) {
  //       const response = await axios.post(
  //         `${urlConstants.REACT_APP_SERVER_ROOT_URI}/updated`,
  //           {
  //           withCredentials: true,
  //           // 'Access-Control-Allow-Origin': true
  //         }
  //       ).then((res) => {
  //         console.log(res);
  //         toast.success("Back end received the request. Events being synced. We will let you know once it is finished!");
  //         return res;
  //       }).catch((err) => {
  //         console.log(err);
  //       })
  //       // .then(async(res) => {
  //       //   console.log(res)
  //       //   if (res.data.status == 200) {
  //       //     // toast.dismiss();
  //       //       toast.success(`Events were updated according to the privacy settings for: ${listItem.email}`);
  //       //   }
  //       //   if (res.data.status == 202) {
  //       //     // toast.loading("Back end received the request. Events being synced. We will let you know once it is finished!");
  //       //     // toast.success("Back end received the request. Events being synced. We will let you know once it is finished!");
  //       //     await wait(10000);
  //       //     checkStatus();
  //       //   }
  //       // }).catch((err) => {
  //       //   console.log(err)
  //       //   // toast.error('Sync events function failed' + err);

  //       // })

  //     // if (notFinished) {
  //     //   await wait(1000);
  //     //   checkStatus();
  //     // }
  //   }
  // }
  // const checkStatus = async () => {
  //   if (me.response.identities) {

  //       const response = await axios.post(
  //         `${urlConstants.REACT_APP_SERVER_ROOT_URI}/updated`,
  //           {
  //           withCredentials: true,
  //           // 'Access-Control-Allow-Origin': true
  //         }
  //       ).catch((err) => {
  //         console.log(err)
  //         // toast.error('Sync events function failed' + err);

  //       })
  //       return response;
  //       // .then(async(res) => {
  //       //   console.log(res)
  //       //   if (res.data.status == 200) {
  //       //     // toast.dismiss();
  //       //       toast.success(`Events are being updated according to the privacy settings`);
  //       //   }
  //       //   if (res.data.status == 202) {
  //       //     // toast.loading("Back end received the request. Events being synced. We will let you know once it is finished!");
  //       //     // toast.success("Back end received the request. Events being synced. We will let you know once it is finished!");
  //       //     await wait(10000);
  //       //     checkStatus();
  //       //   }
  //       // })
  //       // .catch((err) => {
  //       //   console.log(err)
  //       //   // toast.error('Sync events function failed' + err);

  //       // })
  //     // if (notFinished) {
  //     //   await wait(1000);
  //     //   checkStatus();
  //     // }
  //   }
  // }
  const checkStatus = async (toastId) => {
    if (me.identities) {
      const response = await axios
        .post(`${urlConstants.REACT_APP_SERVER_ROOT_URI}/users/sync-status`, {
          withCredentials: true,
          // 'Access-Control-Allow-Origin': true
        })
        .then(async (res) => {
          console.log(res);
          if (!res.data.isSyncInProgress) {
            // toast.update(toastId, {render: "All Events were updated according to the privacy settings", type: "success", isLoading: false});
            // await wait(8000)
            // toast.dismiss(toastId);
            toast.dismiss(toastId);
            await wait(1000);
            // toast.dismiss(toastId);
            const toastSuccess = toast.success(
              "All Events were updated according to the privacy settings"
            );
            await wait(10000);
            toast.dismiss(toastSuccess);
          }
          if (res.data.isSyncInProgress) {
            // toast.loading("Back end received the request. Events being synced. We will let you know once it is finished!");
            // toast.success("Back end received the request. Events being synced. We will let you know once it is finished!");
            await wait(10000);
            checkStatus();
          }
        })
        .catch((err) => {
          console.log(err);
          // toast.error('Sync events function failed' + err);
        });

      // if (notFinished) {
      //   await wait(1000);
      //   checkStatus();
      // }
    }
  };

  const submitHandler = async () => {
    console.log(privacyList);
    // setIsLoading(true);
    setOpen(false);

    // if (privacyList.length > 0) {
    for (let listItem of privacyList) {
      await axios.post(
        `${urlConstants.REACT_APP_SERVER_ROOT_URI}/users/identity/set-privacy`,
        {
          data: listItem.serviceId,
          priority: listItem.privacy,
          // colorId: listItem.colorId,
        }
      );
    }
    await wait(2000);

    await axios.post(
      `${urlConstants.REACT_APP_SERVER_ROOT_URI}/calendars/sync`,
      {
        // data: listItem.serviceId,
        // events: events
      }
    );
    // .then(async(res) => {
    //   console.log(res);
    //   const toastLoading = toast.loading(`Your request is being processed. Please note that other actions may be temporarily unavailable while the update is in progress. We appreciate your patience and will notify you as soon as the process is complete. Thank you!`)
    //   if (res.data.status == 202) {
    //     // const toastLoading = toast.success(`Back end received the request. Events updating. We will let you know once it is finished!`);
    //     // toast.success(`Back end received the request for: ${listItem.email} . Events will be updated.`);
    //     // await wait(10000).then(() => checkStatus(listItem));
    //     await wait(2000).then(() => checkStatus(toastLoading));
    //     // fetchData();
    //   }
    // }).catch((err) => {
    //   console.log(err)
    //   // toast.error('Sync events function failed' + err);

    // })

    setShareUrl("");

    // }
    // }
  };

  const submitColor = async () => {
    console.log(privacyList);
    console.log(color);

    setIsLoading(true);

    // setOpen(false);

    // if (color.length > 0) {
    for (let colorItem of color) {
      await axios.post(`${urlConstants.REACT_APP_SERVER_ROOT_URI}/setColor`, {
        data: colorItem.serviceId,
        colorId: colorItem.colorId,
      });

      // const response = await axios.post(`${urlConstants.REACT_APP_SERVER_ROOT_URI}/update`, {
      //   data: colorItem.serviceId,
      // });
    }
    toast.success("Colors updated");

    // };
    setIsLoading(false);
    fetchData();
    setOpen(false);

    setShareUrl("");
  };

  const unsyncHandler = async (e) => {
    await axios.post(
      `${urlConstants.REACT_APP_SERVER_ROOT_URI}/users/identity/remove`,
      {
        data: e.target.parentNode.parentNode.id,
        // calendarId: e.target.parentNode.parentNode.id,
        // email: e.target.parentNode.parentNode.children[0].children[0].innerText,
      }
    );

    setOpen(false);
    fetchData();
  };

  useEffect(() => {
    if (me && open) {
      me.identities.forEach(async (item) => {
        if (item.serviceType === props.serviceType) {
          // if (item.serviceType) {

          // await props
          //   .requestEvents(item.serviceId, item.serviceType)
          //   .catch((err) => {
          //     reportNetworkError(err);
          //   });

          props = { ...props, email: item.email };
        }
      });
    }
  }, [open]);

  useEffect(() => {
    async function shareCalendar() {
      if (props.events.length > 0) {
        const resp = await axios.post(
          `${urlConstants.REACT_APP_SERVER_ROOT_URI}/share/calendar`,
          {
            data: props.events,
          }
        );

        let url = `${urlConstants.REACT_APP_UI_ROOT_URI}/share/calendar/${resp.data.token}`;
        setShareUrl(url);
      }
    }
    shareCalendar();
  }, [props.events]);

  useEffect(() => {
    async function fetchPriority() {
      if (me && open) {
        let arr = [];
        let colorsArr = [];

        for (let item of me.identities) {
          // if (serviceType && item.serviceType === serviceType) {
          if (serviceType) {
            await axios
              .post(
                `${urlConstants.REACT_APP_SERVER_ROOT_URI}/users/identity/fetch-privacy`,
                {
                  data: item.serviceId,
                },
                {
                  withCredentials: true,
                }
              )
              .then((priority) => {
                arr = [
                  ...arr,
                  {
                    email: item.email,
                    privacy: priority.data.priority,
                    serviceId: item.serviceId,
                    serviceType: item.serviceType,
                    // colorId: item.colorId,
                  },
                ];
                colorsArr = [
                  ...colorsArr,
                  {
                    email: item.email,
                    serviceId: item.serviceId,
                    serviceType: item.serviceType,
                    colorId: item.colorId,
                  },
                ];
              });
          }
        }
        setPrivacyList(arr);
        setColor(colorsArr);
      }
    }

    fetchPriority();

    return () => setPrivacyList([]), setColor([]);
  }, [open]);

  // useEffect(() => {
  //   async function fetchColor() {
  //     if (me && open) {
  //       let arr = [];

  //       for (let item of me.response.identities) {
  //         if (serviceType && item.serviceType === serviceType) {
  //           await axios
  //             .post(
  //               `${urlConstants.REACT_APP_SERVER_ROOT_URI}/setColor`,
  //               {
  //                 data: item.serviceId,
  //               },
  //               {
  //                 withCredentials: true,
  //               }
  //             )
  //             .then((priority) => {
  //               col = [
  //                 ...bol,
  //                 {
  //                   email: item.email,
  //                   privacy: priority.data.priority,
  //                   serviceId: item.serviceId,
  //                   serviceType: item.serviceType,
  //                   colorId: item.colorId,
  //                 },
  //               ];
  //             });
  //         }
  //       }
  //       setAccountColor(arr);
  //     }
  //   }

  //   fetchPriority();

  //   return () => setPrivacyList([]);
  // }, [open]);

  return (
    <>
      {isLoading ? <Spinner /> : null}

      <Modal
        show={open}
        onHide={() => {
          setShareUrl("");
          setOpen(false);
        }}
      >
        <ModalHeader closeButton>
          <ModalTitle>My Calendar Settings</ModalTitle>
          {/* <button className="gct-btn" onClick={submitHandler}>
            Save Color
          </button> */}
        </ModalHeader>

        <ModalBody>
          <div className='hero-container'>
            <h1 id='hero-h1'>SYNCED ACCOUNTS</h1>
            <div className='accounts-container'>
              <div className='cards'>
                {privacyList.map((element, index) => {
                  if (element.serviceType) {
                    return (
                      <>
                        <form
                          className='card'
                          key={index}
                          id={element.serviceId}
                        >
                          <div className='card-heading'>
                            <h2 className='hero-h2'>{element.email}</h2>
                            <img
                              src={downArrow}
                              alt='downArrow'
                              data-toggle='collapse'
                              data-target={`#content-${index}`}
                            />
                          </div>
                          <div
                            className='card-content collapse'
                            id={`content-${index}`}
                          >
                            <p>Privacy Settings:</p>
                            {/* <div className="option"> */}
                            {element.privacy.map((value, ind) => {
                              return value.email !== element.email ? (
                                <div className='radio-cont' id={value.email}>
                                  <div className='rc-0'>{value.email}</div>
                                  <div className='rc-1'>
                                    <input
                                      type='radio'
                                      name={index + ind}
                                      checked={
                                        value.chooseType === "private"
                                          ? true
                                          : false
                                      }
                                      onChange={changeHandler}
                                    />
                                    <label htmlFor=''>Only Me</label>
                                  </div>
                                  <div className='rc-2'>
                                    <input
                                      type='radio'
                                      name={index + ind}
                                      checked={
                                        value.chooseType === "public"
                                          ? true
                                          : false
                                      }
                                      onChange={changeHandler}
                                    />
                                    <label htmlFor=''>
                                      Public (everyone can see)
                                    </label>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              );
                            })}
                            {/* </div> */}
                          </div>
                          <div
                            className='shareCalendar collapse'
                            id={`content-${index}`}
                          >
                            <div className='shareHeading'>
                              Share the Calendar:
                            </div>

                            <div className='shareSection'>
                              <div className='urlShare'>
                                {shareUrl === "" ? "Loading....." : shareUrl}
                              </div>

                              <span onClick={copyHandler}>
                                <i className='bi bi-clipboard'></i>
                              </span>
                            </div>
                          </div>
                          <div
                            className='color-scheme-cont collapse'
                            id={`content-${index}`}
                          >
                            <p>Color Scheme:</p>
                            <div className='color-btn'>
                              <div className='ck1 ck'>
                                <input
                                  type='radio'
                                  className='ck'
                                  name='colorId'
                                  value='#0ba2dd'
                                  // checked={ element.colorId === 1 ? true : false }
                                  onChange={handleChangeColor}
                                />
                              </div>
                              <div className='ck2 ck'>
                                <input
                                  type='radio'
                                  className='ck'
                                  name='colorId'
                                  value='#f46745'
                                  // checked={ element.colorId === 2 ? true : false }
                                  onChange={handleChangeColor}
                                />
                              </div>
                              <div className='ck3 ck'>
                                <input
                                  type='radio'
                                  className='ck'
                                  name='colorId'
                                  value='#6389b0'
                                  // checked={ element.colorId === 3 ? true : false }
                                  onChange={handleChangeColor}
                                />
                              </div>
                              <div className='ck4 ck'>
                                <input
                                  type='radio'
                                  className='ck'
                                  name='colorId'
                                  value='#1aac57'
                                  // checked={ element.colorId === 3 ? true : false }
                                  onChange={handleChangeColor}
                                />
                              </div>
                              <div className='ck5 ck'>
                                <input
                                  type='radio'
                                  className='ck'
                                  name='colorId'
                                  value='#3a1aac'
                                  // checked={ element.colorId === 3 ? true : false }
                                  onChange={handleChangeColor}
                                />
                              </div>
                              <div className='ck6 ck'>
                                <input
                                  type='radio'
                                  className='ck'
                                  name='colorId'
                                  value='#00fdc6'
                                  // checked={ element.colorId === 3 ? true : false }
                                  onChange={handleChangeColor}
                                />
                              </div>
                              <div className='ck7 ck'>
                                <input
                                  type='radio'
                                  className='ck'
                                  name='colorId'
                                  value='#eeff00'
                                  // checked={ element.colorId === 3 ? true : false }
                                  onChange={handleChangeColor}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className='logout-btn-container collapse'
                            id={`content-${index}`}
                          >
                            <div className='log-btn' onClick={unsyncHandler}>
                              Logout and UnSync
                              <i className='bi bi-box-arrow-right'></i>
                            </div>
                          </div>
                        </form>
                        <br />
                      </>
                    );
                  }
                  // else if (element.serviceType === "microsoft") {
                  //   return (
                  //     <>
                  //       <form
                  //         className="card"
                  //         key={index}
                  //         id={element.serviceId}
                  //       >
                  //         <div className="card-heading">
                  //           <h2 className="hero-h2">{element.email}</h2>
                  //           <img
                  //             src={downArrow}
                  //             alt="downArrow"
                  //             data-toggle="collapse"
                  //             data-target={`#content-${index}`}
                  //           />
                  //         </div>
                  //         <div
                  //           className="card-content collapse"
                  //           id={`content-${index}`}
                  //         >
                  //           <p>Privacy Settings:</p>
                  //           {/* <div className="option"> */}
                  //           {element.privacy.map((value, ind) => {
                  //             return value.email !== element.email ? (
                  //               <div className="radio-cont" id={value.email}>
                  //                 <div className="rc-0">{value.email}</div>
                  //                 <div className="rc-1">
                  //                   <input
                  //                     type="radio"
                  //                     name={index + ind}
                  //                     checked={
                  //                       value.chooseType === "private"
                  //                         ? true
                  //                         : false
                  //                     }
                  //                     onChange={changeHandler}
                  //                   />
                  //                   <label htmlFor="">Only Me</label>
                  //                 </div>
                  //                 <div className="rc-2">
                  //                   <input
                  //                     type="radio"
                  //                     name={index + ind}
                  //                     checked={
                  //                       value.chooseType === "public"
                  //                         ? true
                  //                         : false
                  //                     }
                  //                     onChange={changeHandler}
                  //                   />
                  //                   <label htmlFor="">
                  //                     Public (everyone can see)
                  //                   </label>
                  //                 </div>
                  //               </div>
                  //             ) : (
                  //               <></>
                  //             );
                  //           })}
                  //         </div>

                  //         <div
                  //           className="shareCalendar collapse"
                  //           id={`content-${index}`}
                  //         >
                  //           <div className="shareHeading">
                  //             Share the Calendar:
                  //           </div>
                  //           <div className="shareSection">
                  //             <div className="urlShare">
                  //               {shareUrl === "" ? "Loading....." : shareUrl}
                  //             </div>

                  //             <span onClick={copyHandler}>
                  //               <i className="bi bi-clipboard"></i>
                  //             </span>
                  //           </div>
                  //         </div>
                  //         <div
                  //           className="color-scheme-cont collapse"
                  //           id={`content-${index}`}
                  //         >
                  //           <p>Color Scheme:</p>
                  //           <div className="color-btn">
                  //             <div className="ck1 ck">
                  //               <input
                  //                 type="radio"
                  //                 className="ck"
                  //                 name={`${index}checkbox`}
                  //               />
                  //             </div>
                  //             <div className="ck2 ck">
                  //               <input
                  //                 type="radio"
                  //                 className="ck"
                  //                 name={`${index}checkbox`}
                  //               />
                  //             </div>
                  //             <div className="ck3 ck">
                  //               <input
                  //                 type="radio"
                  //                 className="ck"
                  //                 name={`${index}checkbox`}
                  //               />
                  //             </div>
                  //           </div>
                  //         </div>
                  //         <div
                  //           className="logout-btn-container collapse"
                  //           id={`content-${index}`}
                  //         >
                  //           <div className="log-btn" onClick={unsyncHandler}>
                  //             Logout and UnSync
                  //             <i className="bi bi-box-arrow-right"></i>
                  //           </div>
                  //         </div>
                  //       </form>
                  //       <br />
                  //     </>
                  //   );
                  // } else {
                  //   return (
                  //     <>
                  //       <form
                  //         className="card"
                  //         key={index}
                  //         id={element.serviceId}
                  //       >
                  //         <div className="card-heading">
                  //           <h2 className="hero-h2">{element.email}</h2>
                  //           <img
                  //             src={downArrow}
                  //             alt="downArrow"
                  //             data-toggle="collapse"
                  //             data-target={`#content-${index}`}
                  //           />
                  //         </div>
                  //         <div
                  //           className="card-content collapse"
                  //           id={`content-${index}`}
                  //         >
                  //           <p>Privacy Settings:</p>
                  //           {element.privacy.map((value, ind) => {
                  //             return value.email !== element.email ? (
                  //               <div className="radio-cont" id={value.email}>
                  //                 <div className="rc-0">{value.email}</div>
                  //                 <div className="rc-1">
                  //                   <input
                  //                     type="radio"
                  //                     name={index + ind}
                  //                     checked={
                  //                       value.chooseType === "private"
                  //                         ? true
                  //                         : false
                  //                     }
                  //                     onChange={changeHandler}
                  //                   />
                  //                   <label htmlFor="">Only Me</label>
                  //                 </div>
                  //                 <div className="rc-2">
                  //                   <input
                  //                     type="radio"
                  //                     name={index + ind}
                  //                     checked={
                  //                       value.chooseType === "public"
                  //                         ? true
                  //                         : false
                  //                     }
                  //                     onChange={changeHandler}
                  //                   />
                  //                   <label htmlFor="">
                  //                     Public (everyone can see)
                  //                   </label>
                  //                 </div>
                  //               </div>
                  //             ) : (
                  //               <></>
                  //             );
                  //           })}
                  //         </div>

                  //         <div
                  //           className="shareCalendar collapse"
                  //           id={`content-${index}`}
                  //         >
                  //           <div className="shareHeading">
                  //             Share the Calendar:
                  //           </div>
                  //           <div className="shareSection">
                  //             <div className="urlShare">
                  //               {shareUrl === "" ? "Loading....." : shareUrl}
                  //             </div>

                  //             <span onClick={copyHandler}>
                  //               <i className="bi bi-clipboard"></i>
                  //             </span>
                  //           </div>
                  //         </div>
                  //         <div
                  //           className="color-scheme-cont collapse"
                  //           id={`content-${index}`}
                  //         >
                  //           <p>Color Scheme:</p>
                  //           <div className="color-btn">
                  //             <div className="ck1 ck">
                  //               <input
                  //                 type="radio"
                  //                 className="ck"
                  //                 name={`${index}checkbox`}
                  //               />
                  //             </div>
                  //             <div className="ck2 ck">
                  //               <input
                  //                 type="radio"
                  //                 className="ck"
                  //                 name={`${index}checkbox`}
                  //               />
                  //             </div>
                  //             <div className="ck3 ck">
                  //               <input
                  //                 type="radio"
                  //                 className="ck"
                  //                 name={`${index}checkbox`}
                  //               />
                  //             </div>
                  //           </div>
                  //         </div>
                  //         <div
                  //           className="logout-btn-container collapse"
                  //           id={`content-${index}`}
                  //         >
                  //           <div className="log-btn" onClick={unsyncHandler}>
                  //             Logout and UnSync
                  //             <i className="bi bi-box-arrow-right"></i>
                  //           </div>
                  //         </div>
                  //       </form>
                  //       <br />
                  //     </>
                  //   );
                  // }
                })}
              </div>
            </div>
          </div>
          <div className='save-btn-container'>
            <button
              className='btn1 sbtn'
              onClick={() => {
                setShareUrl("");
                setOpen(false);
              }}
            >
              Close without saving
            </button>
            <button className='btn2 sbtn' onClick={submitHandler}>
              Save privacy settings
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default connect(mapStateToProps, actionCreators)(Privacy);
