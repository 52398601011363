import { requestEventsInRange } from "../../../utils/requests";

export default {
  toggleWeekends() {
    return {
      type: "TOGGLE_WEEKENDS",
    };
  },

  requestEvents(calendarId, serviceType) {
    return async (dispatch) => {
      dispatch({
        type: "RECIEVE_EVENTS_CLEANUP",
      });
  
      return requestEventsInRange(calendarId, serviceType).then(
        (plainEventObjects) => {

          dispatch({
            type: "RECEIVE_EVENTS",
            plainEventObjects,
          });
        }
      );
    };
  },
};
