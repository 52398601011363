import React from 'react';

import { useHistory } from "react-router-dom";
import axios from "axios";
import urlConstants from "../config";


import { Row, Col, Form, FormGroup, Input } from "reactstrap";
import { toast } from "react-toastify";

const LoginApple = () => {
  const history = useHistory();

  let submitHandler = (e) => {
    e.preventDefault();

    axios
      .post(
        `${urlConstants.REACT_APP_SERVER_ROOT_URI}/users/identity/add/apple`,
        {
          username: e.target.email.value,
          password: e.target.password.value,
        },
        {
          headers: {
            "Content-type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.status) {
          toast.success("Apple calendar integrated");
          history.push("/dashboard");
        } else {
          toast.error(response.data.message);
          e.target.email.value = "";
          e.target.password.value = "";
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="login_bigwrapper">
      <div className="auth-wrapper auth-v2">
        <div id="container">
          <div id="fir-cont">
            <div>
              <svg
                width="112"
                height="4"
                viewBox="0 0 112 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 0H110C111.105 0 112 0.895431 112 2C112 3.10457 111.105 4 110 4H0V0Z"
                  fill="#FFC4AF"
                />
              </svg>
              <br />
              <svg
                width="60"
                height="4"
                viewBox="0 0 60 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 0H58C59.1046 0 60 0.895431 60 2C60 3.10457 59.1046 4 58 4H0V0Z"
                  fill="#F9DCAA"
                />
              </svg>
            </div>
            <Row className="auth-inner">
              <Col>
                <Col>
                  <Form
                    id="f1"
                    className="auth-login-form"
                    onSubmit={submitHandler}
                  >
                    <div class="cont-cred">
                      <FormGroup id="fg-email">
                        <div class="svg-cls">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="3"
                              y="5"
                              width="18"
                              height="14"
                              rx="3"
                              stroke="#DDE3EB"
                              strokeWidth="2"
                            />
                            <path
                              d="M2.83333 6L10.5988 13.6243C11.3768 14.3881 12.6232 14.3881 13.4012 13.6243L21.1667 6"
                              stroke="#DDE3EB"
                              strokeWidth="2"
                            />
                          </svg>
                        </div>
                        <div style={{ display: "inline-block" }}>
                          <Input
                            type="email"
                            id="login-email"
                            className="input-lo"
                            placeholder="Enter email address"
                            name="email"
                          />
                        </div>
                        <hr />
                      </FormGroup>
                      <br />
                      <FormGroup id="fg-pass">
                        <div class="svg-cls">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="3"
                              y="9.36816"
                              width="18"
                              height="12.7368"
                              rx="3"
                              stroke="#DDE3EB"
                              strokeWidth="2"
                            />
                            <path
                              d="M7 6C7 3.79086 8.79086 2 11 2H13C15.2091 2 17 3.79086 17 6V9.47368H7V6Z"
                              stroke="#DDE3EB"
                              strokeWidth="2"
                            />
                            <ellipse
                              cx="8"
                              cy="15.7372"
                              rx="1"
                              ry="1.05263"
                              fill="#DDE3EB"
                            />
                            <ellipse
                              cx="12"
                              cy="15.7372"
                              rx="1"
                              ry="1.05263"
                              fill="#DDE3EB"
                            />
                            <ellipse
                              cx="16"
                              cy="15.7372"
                              rx="1"
                              ry="1.05263"
                              fill="#DDE3EB"
                            />
                          </svg>
                        </div>
                        <Input
                          type="password"
                          id="login-password"
                          placeholder="Enter password"
                          name="password"
                        />
                        <hr />
                        <br />
                      </FormGroup>
                    </div>
                    <button type="submit" id="log-button">
                      Sign in
                    </button>
                  </Form>
                </Col>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginApple;
