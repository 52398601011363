import axios from "axios";
import React from "react";
import { toast } from "react-toastify";
import urlConstants from "../config";



const ForgetPassword = () => {
  const forgetHandler = async (e) => {
    e.preventDefault();
    const response = await axios.post(
      `${urlConstants.REACT_APP_SERVER_ROOT_URI}/users/forgot-password`,
      {
        email: e.target.newemail.value,
      }
    );
    
    console.log(response.data.message);
    toast.success(response.data.message);
  };

  return (
    <div>
      <div className="main-container">
        <div className="svg-box">
          <svg
            width="112"
            height="24"
            viewBox="0 0 112 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 0H110C111.105 0 112 0.895431 112 2V2C112 3.10457 111.105 4 110 4H0V0Z"
              fill="#FFC4AF"
            />
            <path
              d="M0 20H58C59.1046 20 60 20.8954 60 22V22C60 23.1046 59.1046 24 58 24H0V20Z"
              fill="#F9DCAA"
            />
          </svg>
        </div>
      </div>
      <div className="main-content">
        <div className="h1">
          <h1>Forgot password</h1>
          <form className="content" onSubmit={forgetHandler}>
            <div className="new-password-cont">
              <label htmlFor="email">
                Email <span>*</span>
              </label>
              <input
                type="email"
                required
                name="newemail"
                placeholder="Enter Email"
              />
            </div>
            <div className="updatePass-btn-cont">
              <button className="updatePass-btn" type="submit">
                Forgot Password
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
