import React, { useState, useEffect } from "react";

import { Link, useHistory } from "react-router-dom";

import { Row, Col, Form, FormGroup, Input } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  handleErrorMessageLogin,
  handleLogin,
} from "../redux/actions/auth/index";
import EmailNotFound from "./Popups/EmailNotFound";
import InvalidPassword from "./Popups/IncorrectPassword";

import "../css/login.css";

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [displayError, setDisplayError] = useState("none");
  const [invalidError, setInvalidError] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passError, setPassError] = useState(false);

  let user = useSelector((state) => state.userLoginReducer);

  useEffect(() => {
    if (user.userData?.username) {
      history.push("/dashboard");
    }
  }, [user.userData, history]);

  useEffect(() => {
    if (user.error === "Username or password is not valid.") {
      setDisplayError("flex");
      setInvalidError("Email Address not Found.");
      setTimeout(() => {
        setEmailError(true);
      }, 1000);
    } else if (
      user.error === "Password provided by the user is not correct!!"
    ) {
      setDisplayError("flex");
      setInvalidError("Password is incorrect.");
      setTimeout(() => {
        setPassError(true);
      }, 1000);
    } else if (user.error === "Email is not verified") {
      setDisplayError("flex");
      setInvalidError("Email is not verified");
      setTimeout(() => {
        setInvalidError("");
        setDisplayError("none");
      }, 2000);
    }

    dispatch(handleErrorMessageLogin());
  }, [user.error, dispatch]);

  return (
    <div className="login_bigwrapper">
      <EmailNotFound trigger={emailError} setTrigger={setEmailError} />
      <InvalidPassword trigger={passError} setTrigger={setPassError} />
      <div className="auth-wrapper auth-v2">
        <div id="login_container">
          <div id="fir-cont">
            <div className="design">
              <svg
                width="112"
                height="4"
                viewBox="0 0 112 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 0H110C111.105 0 112 0.895431 112 2C112 3.10457 111.105 4 110 4H0V0Z"
                  fill="#FFC4AF"
                />
              </svg>
              <br />
              <svg
                width="60"
                height="4"
                viewBox="0 0 60 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 0H58C59.1046 0 60 0.895431 60 2C60 3.10457 59.1046 4 58 4H0V0Z"
                  fill="#F9DCAA"
                />
              </svg>
            </div>
            <Row className="auth-inner m-0">
              <Col>
                <Form
                  id="f1"
                  className="auth-login-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    dispatch(
                      handleLogin(e.target.email.value, e.target.password.value)
                    );
                  }}
                >
                  <div
                    className="invalidError"
                    style={{
                      display: `${displayError}`,
                      justifyContent: "center",
                    }}
                  >
                    {invalidError}
                  </div>
                  <div className="cont-cred">
                    <FormGroup id="fg-email">
                      <div
                        style={{ height: "56px" }}
                        className="d-flex align-items-center fg-email-div"
                      >
                        <div style={{ marginLeft: "10px" }} className="svg-cls">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="3"
                              y="5"
                              width="18"
                              height="14"
                              rx="3"
                              stroke="black"
                              strokeWidth="2"
                            />
                            <path
                              d="M2.83333 6L10.5988 13.6243C11.3768 14.3881 12.6232 14.3881 13.4012 13.6243L21.1667 6"
                              stroke="black"
                              strokeWidth="2"
                            />
                          </svg>
                        </div>
                        <Input
                          style={{backgroundColor: "transparent !important" }}
                          type="email"
                          id="login-email"
                          className="input-lo"
                          placeholder="Enter email address"
                          name="email"
                          required
                        />
                      </div>
                      {/* <hr /> */}
                    </FormGroup>
                    <div
                      style={{ height: "16px", margin: 0, padding: 0 }}
                    ></div>
                    {/* <br /> */}
                    <FormGroup id="fg-pass">
                      <div
                        style={{ height: "56px" }}
                        className="d-flex flex-row align-items-center fg-email-div "
                      >
                        <div style={{ marginLeft: "10px" }} className="svg-cls">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="3"
                              y="9.36816"
                              width="18"
                              height="12.7368"
                              rx="3"
                              stroke="black"
                              strokeWidth="2"
                            />
                            <path
                              d="M7 6C7 3.79086 8.79086 2 11 2H13C15.2091 2 17 3.79086 17 6V9.47368H7V6Z"
                              stroke="black"
                              strokeWidth="2"
                            />
                            <ellipse
                              cx="8"
                              cy="15.7372"
                              rx="1"
                              ry="1.05263"
                              fill="black"
                            />
                            <ellipse
                              cx="12"
                              cy="15.7372"
                              rx="1"
                              ry="1.05263"
                              fill="black"
                            />
                            <ellipse
                              cx="16"
                              cy="15.7372"
                              rx="1"
                              ry="1.05263"
                              fill="black"
                            />
                          </svg>
                        </div>
                        <Input
                          type="password"
                          id="login-password"
                          placeholder="Enter password"
                          name="password"
                          required
                        />
                      </div>
                      {/* <hr /> */}
                    </FormGroup>
                    <div className="d-flex justify-content-center">
                      <button type="submit" id="login-button">
                        Log in
                      </button>
                    </div>
                    <p
                      style={{ margin: "16px 0px 0px 0px" }}
                      className="d-flex justify-content-center"
                    >
                      <Link className="s-pas-container" to="/forgetPassword">
                        <span id="s-pas">Forgot Password?</span>
                      </Link>
                    </p>
                  </div>
                </Form>
              </Col>
            </Row>
            <div id="displayOnSm">
              <Link className="container-fluid btn" to="/register">
                New to SyncroCal? Sign Up.
              </Link>
            </div>
          </div>
          <div id="sec-half">
            <svg
              width="136"
              height="116"
              viewBox="0 0 136 116"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.15"
                d="M108.236 53.1391L176 -57.4693L171.904 -60L104.052 50.7829C101.525 49.7357 98.7357 49.1249 95.816 49.1249C84.0063 49.1249 74.4626 58.7241 74.4626 70.5048C74.4626 82.3292 84.0498 91.8848 95.816 91.8848C107.626 91.8848 117.169 82.2856 117.169 70.5048C117.169 63.3927 113.639 57.0224 108.236 53.1391Z"
                fill="white"
              />
              <path
                opacity="0.15"
                d="M44.0643 58.0798L142.616 -26.0069L139.502 -29.6874L40.8134 54.5381C38.7333 52.7562 36.2699 51.3077 33.4938 50.3995C22.265 46.7256 10.2296 52.8837 6.59543 64.0849C2.94776 75.3276 9.1156 87.3955 20.3029 91.0558C31.5317 94.7297 43.5671 88.5716 47.2013 77.3704C49.3953 70.6081 48.0042 63.4531 44.0643 58.0798Z"
                fill="white"
              />
            </svg>
            <div id="sec-container">
              <p className="new-p-login">New to SyncroCal?</p>
              <Link to="/register">
                <button id="reg-but" href="/register">
                  Sign up
                </button>
              </Link>
            </div>
            <svg
              id="sv2"
              width="113"
              height="106"
              viewBox="0 0 113 106"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.15"
                d="M17.9049 41.6735L-9.52228 132.253L-6.27214 133.325L21.1847 42.6065C23.1013 42.9028 25.122 42.8428 27.127 42.3257C35.2368 40.2343 40.1322 31.7247 38.0972 23.3554C36.0546 14.955 27.8204 9.86442 19.7405 11.9482C11.6308 14.0396 6.73531 22.5492 8.77035 30.9185C9.99891 35.9711 13.5233 39.8717 17.9049 41.6735Z"
                fill="white"
              />
              <path
                opacity="0.15"
                d="M50.8642 51.8023L-13.5808 119.076L-11.1601 121.574L53.373 54.1904C54.9489 55.3652 56.775 56.2736 58.789 56.7692C66.9354 58.7737 75.0548 53.5569 76.9401 45.1666C78.8325 36.7452 73.7484 28.3125 65.6321 26.3155C57.4858 24.311 49.3663 29.5277 47.481 37.918C46.3428 42.9833 47.7582 48.1194 50.8642 51.8023Z"
                fill="white"
              />
              <path
                opacity="0.15"
                d="M66.9504 87.8593L-20.9472 114.219L-19.9891 117.616L68.0368 91.2046C68.871 93.0219 70.0489 94.7324 71.5841 96.1768C77.7939 102.019 87.348 101.543 92.9148 95.1654C98.5022 88.7637 97.9764 78.8474 91.7895 73.0267C85.5798 67.1843 76.0256 67.66 70.4588 74.0381C67.0982 77.8885 65.944 83.0837 66.9504 87.8593Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
