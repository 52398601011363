import { combineReducers } from "redux";

import {
  userRegisterReducer,
  userLoginReducer,
  user2faReducer,
  userResendReducer,
  handleStripeReducer,
  handleLoadingReducer,
} from "../reducers/auth/index";
import { weekendsVisible, eventsById } from "../reducers/calendar/index";

const rootReducer = combineReducers({
  userRegisterReducer,
  userLoginReducer,
  userResendReducer,
  user2faReducer,
  weekendsVisible,
  eventsById,
  handleStripeReducer,
  handleLoadingReducer,
});

export default rootReducer;
