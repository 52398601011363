import urlConstants from "../../config";
const jwtDefaultConfig = {
  loginEndpoint: `${urlConstants.REACT_APP_SERVER_ROOT_URI}/users/login`,
  registerEndpoint: `${urlConstants.REACT_APP_SERVER_ROOT_URI}/users/register`,
  refreshEndpoint: `${urlConstants.REACT_APP_SERVER_ROOT_URI}/users/token`,
  logoutEndpoint: `${urlConstants.REACT_APP_SERVER_ROOT_URI}/users/logout`,
  fa2EndPoint: `${urlConstants.REACT_APP_SERVER_ROOT_URI}/users/verify-email`,
  dashboard: `${urlConstants.REACT_APP_SERVER_ROOT_URI}/dashboard`,
  tokenType: "Bearer",
  storageTokenKeyName: "accessToken",
  storageRefreshTokenKeyName: "refreshToken",
  calendarEndPoint: `${urlConstants.REACT_APP_SERVER_ROOT_URI}/calendar/events`,
  resendEndPoint: `${urlConstants.REACT_APP_SERVER_ROOT_URI}/users/resend-otp`,
};
export default jwtDefaultConfig;
