import { hashById } from "../../../utils/utils";

const initialState = {
  priority: "",
};

export const weekendsVisible = (weekendsVisible = true, action) => {
  switch (action.type) {
    case "TOGGLE_WEEKENDS":
      return !weekendsVisible;

    default:
      return weekendsVisible;
  }
};

export const eventsById = (eventsById = {}, action) => {
  switch (action.type) {
    case "RECEIVE_EVENTS": {
      return hashById(action.plainEventObjects);
    }
    case "RECIEVE_EVENTS_CLEANUP":
      return [];

    default:
      return eventsById;
  }
};

export const priorityHandler = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PRIORITY":
      return {
        ...state,
        priority: action.payload,
      };
  }
};
