import React from 'react';
import { useHistory } from 'react-router-dom';
import '../../css/Accounts/Navbar.css';

function NavBar(props) {
  const history = useHistory();

  const clickHandler = () => {
    props.backTo === 'account'
      ? history.push(`/me/${props.backTo}`)
      : history.push('/dashboard');
  };

  const onLogoClick = () => {
    history.push('/dashboard');
  };
  return (
    <nav>
      <div className='nav-container'>
        <div onClick={onLogoClick} className='nav-text'>
          <svg
            width='158'
            height='42'
            viewBox='0 0 158 42'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g
              clipPath='url(#clip0_1724_2573)'
              filter='url(#filter0_d_1724_2573)'
            >
              <path
                d='M119.767 4.77591C121.698 4.77591 123.455 5.87126 124.377 7.76987C124.766 8.57312 125.588 9.06968 126.467 9.06968H126.683C128.44 9.06968 129.564 7.18568 128.772 5.60838C127.245 2.57061 124.089 0.482147 119.738 0.482147C113.024 0.482147 108.831 5.38931 108.831 13.4365C108.831 21.6005 113.528 25.865 119.681 25.865C123.873 25.865 127.014 23.7327 128.7 20.2714C129.463 18.7087 128.311 16.8686 126.582 16.8686C125.645 16.8686 124.795 17.4235 124.435 18.2998C123.571 20.3737 121.943 21.5274 119.551 21.5274C115.733 21.5274 113.831 18.4167 113.831 13.1444C113.802 7.95973 115.704 4.77591 119.767 4.77591Z'
                fill='white'
              />
              <path
                d='M138.958 6.96661C135.198 6.96661 133.08 8.12038 132.071 9.98978C131.293 11.421 132.331 13.159 133.944 13.159H134.06C134.866 13.159 135.587 12.677 135.961 11.9468C136.408 11.0705 137.258 10.5448 138.756 10.5448C140.557 10.5448 141.681 11.2312 141.681 12.3703C141.681 13.5825 141.047 14.0499 138.756 14.3712C134.132 15.0138 130.775 15.5834 130.775 20.6658C130.775 23.5283 132.604 25.4999 136.566 25.4999C138.901 25.4999 140.73 24.682 141.825 23.4553V23.4699C141.883 24.3461 142.618 25.0326 143.497 25.0326H144.707C145.629 25.0326 146.378 24.2731 146.378 23.3384V13.9038C146.364 9.46401 144.462 6.96661 138.958 6.96661ZM141.739 18.3436C141.71 20.4175 139.938 22.17 137.575 22.17C136.134 22.17 135.356 21.5274 135.356 20.1984C135.356 18.6941 136.206 17.9493 138.814 17.4381C140.514 17.1168 141.321 16.8686 141.782 16.4012L141.739 18.3436Z'
                fill='white'
              />
              <path
                d='M151.709 0.978699C150.441 0.978699 149.418 2.01563 149.418 3.30084V22.7104C149.418 23.9956 150.441 25.0326 151.709 25.0326C152.977 25.0326 154 23.9956 154 22.7104V3.31544C154 2.03023 152.977 0.978699 151.709 0.978699Z'
                fill='white'
              />
              <path
                d='M19.1858 8.39787C18.2637 8.39787 17.4425 7.81368 17.0823 6.95201C16.4628 5.49154 15.0652 4.77591 12.7599 4.77591C10.008 4.77591 8.55285 5.78364 8.55285 7.5654C8.55285 9.06968 9.57581 9.93136 12.198 10.574C14.8491 11.2166 17.4281 11.8592 19.157 12.6478C21.2029 13.5825 22.831 15.043 22.831 18.4167C22.831 23.6451 18.9409 25.9819 13.4659 25.9819C8.98508 25.9819 5.55602 24.4192 4.18727 21.2354C3.53892 19.7165 4.64833 18.0223 6.27641 18.0223H6.49253C7.40022 18.0223 8.22147 18.5627 8.56726 19.4098C9.23002 21.0163 11.0166 21.9656 13.5091 21.9656C16.4051 21.9656 18.0764 20.7096 18.0764 18.5919C18.0764 17.1899 17.3704 16.226 14.4312 15.5834C11.5353 14.9554 9.66225 14.5318 7.99094 13.7724C5.48398 12.6332 4.07201 10.9099 4.07201 7.90131C4.07201 3.63675 6.93917 0.584381 12.6591 0.584381C17.0247 0.584381 19.9927 2.35154 21.3182 5.09722C22.053 6.6161 20.9436 8.39787 19.2723 8.39787H19.1858V8.39787Z'
                fill='white'
              />
              <path
                d='M35.654 22.8419C35.02 24.4192 33.1038 25.3539 30.957 25.3539C27.3118 25.3539 24.5887 22.8419 24.5887 18.9716V9.72689C24.5887 8.51471 25.5541 7.53619 26.7499 7.53619H27.0381C28.2339 7.53619 29.1993 8.51471 29.1993 9.72689V18.2998C29.1993 20.4905 30.1934 21.6005 32.1385 21.6005C34.2276 21.6005 35.6684 20.1984 35.6684 18.2998V9.72689C35.6684 8.51471 36.6337 7.53619 37.8296 7.53619H38.1177C39.3136 7.53619 40.2789 8.51471 40.2789 9.72689V25.646C40.2789 30.6261 36.749 33.5325 32.2825 33.5325C28.3492 33.5325 25.9143 31.8383 25.0066 28.9758C24.5599 27.5738 25.6261 26.1571 27.0669 26.1571C27.9602 26.1571 28.7958 26.6975 29.1128 27.5592C29.5595 28.8006 30.6977 29.5308 32.4986 29.5308C34.1556 29.5308 35.6828 28.0557 35.6828 25.7336V22.8419H35.654Z'
                fill='white'
              />
              <path
                d='M56.07 25.164C54.7877 25.164 53.7503 24.1125 53.7503 22.8127V14.488C53.7503 11.7715 52.9723 11.0121 50.7823 11.0121C48.3762 11.0121 47.2091 12.3703 47.2091 15.0576V22.8273C47.2091 24.1125 46.1862 25.1494 44.9183 25.1494C43.6504 25.1494 42.6274 24.1125 42.6274 22.8273V9.7561C42.6274 8.52931 43.6072 7.5362 44.8174 7.5362C46.0277 7.5362 47.0074 8.52931 47.0074 9.7561V10.1212C48.0736 8.14959 49.8314 7.06885 52.6265 7.06885C55.9547 7.06885 58.3896 9.1135 58.3896 12.9399V22.8273C58.3896 24.1125 57.3523 25.164 56.07 25.164Z'
                fill='white'
              />
              <path
                d='M73.4746 18.7964C75.1892 18.7964 76.2409 20.7242 75.29 22.1701C73.878 24.3315 71.4143 25.6314 68.5183 25.6314C63.7781 25.6314 60.522 22.4475 60.522 16.1383C60.522 9.82913 63.7781 7.06885 68.648 7.06885C71.8033 7.06885 74.2526 8.33945 75.5493 10.5301C76.4138 11.9906 75.3765 13.8454 73.6907 13.8454H73.4602C72.639 13.8454 71.9042 13.3635 71.5296 12.6186C70.9821 11.5379 69.9159 10.9391 68.6192 10.9391C66.6741 10.9391 65.0893 12.1951 65.0893 16.0215C65.0893 19.8625 66.6741 21.7173 68.4895 21.7173C69.8583 21.7173 70.9965 21.1185 71.544 20.0086C71.9042 19.2783 72.639 18.7964 73.4746 18.7964Z'
                fill='white'
              />
              <path
                d='M78.1283 22.8419V9.65387C78.1283 8.4855 79.0648 7.5362 80.2174 7.5362H80.3615C81.5141 7.5362 82.4506 8.4855 82.4506 9.65387C83.9635 7.28792 85.2602 7.34634 86.7154 7.06885H86.9603C87.6519 7.06885 88.1994 7.62382 88.1994 8.32485V10.4717C88.1994 11.1728 87.6375 11.7277 86.9459 11.7277H86.9315C84.1075 11.7277 82.7244 13.159 82.7244 15.9923V22.8419C82.7244 24.1271 81.7014 25.164 80.4336 25.164C79.1513 25.164 78.1283 24.1271 78.1283 22.8419Z'
                fill='white'
              />
              <path
                d='M88.4587 16.3574C88.4587 10.7638 91.8878 7.06885 97.406 7.06885C102.852 7.06885 106.238 10.72 106.238 16.3574C106.238 21.951 102.809 25.646 97.406 25.646C91.7869 25.6314 88.4587 21.8341 88.4587 16.3574ZM101.613 16.3574C101.613 12.7793 100.201 11.0121 97.406 11.0121C94.6109 11.0121 93.1989 12.7647 93.1989 16.3574C93.1989 19.9501 94.6109 21.7319 97.406 21.7319C100.201 21.7319 101.613 19.9355 101.613 16.3574Z'
                fill='white'
              />
            </g>
            <defs>
              <filter
                id='filter0_d_1724_2573'
                x='0'
                y='0.482147'
                width='158'
                height='41.0357'
                filterUnits='userSpaceOnUse'
                colorInterpolationFilters='sRGB'
              >
                <feFlood floodOpacity='0' result='BackgroundImageFix' />
                <feColorMatrix
                  in='SourceAlpha'
                  type='matrix'
                  values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                  result='hardAlpha'
                />
                <feOffset dy='4' />
                <feGaussianBlur stdDeviation='2' />
                <feComposite in2='hardAlpha' operator='out' />
                <feColorMatrix
                  type='matrix'
                  values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0'
                />
                <feBlend
                  mode='normal'
                  in2='BackgroundImageFix'
                  result='effect1_dropShadow_1724_2573'
                />
                <feBlend
                  mode='normal'
                  in='SourceGraphic'
                  in2='effect1_dropShadow_1724_2573'
                  result='shape'
                />
              </filter>
              <clipPath id='clip0_1724_2573'>
                <rect
                  width='150'
                  height='33.0357'
                  fill='white'
                  transform='translate(4 0.482147)'
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div className='nav-btn'>
          <svg
            width='204'
            height='96'
            viewBox='0 0 204 96'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g opacity='0.25' clipPath='url(#clip0_1568_2549)'>
              <path
                d='M93.559 59.6236L110.494 -12.3285L108.501 -12.8581L91.5661 59.094C85.5041 58.0592 79.5222 61.7818 77.9072 67.8596C76.2022 74.276 80.0217 80.8591 86.4389 82.5643C92.8561 84.2696 99.4396 80.4508 101.145 74.0343C102.785 67.942 99.4106 61.6905 93.559 59.6236Z'
                fill='white'
              />
              <path
                d='M177.108 27.764C173.102 26.6996 169.014 27.7895 166.121 30.3275L121.096 -16.1336L119.309 -15.0297L164.684 31.8016C163.639 33.0815 162.852 34.6003 162.402 36.2941C160.697 42.7106 164.516 49.2937 170.933 50.9989C177.351 52.7041 183.934 48.8853 185.639 42.4688C187.344 36.0523 183.525 29.4692 177.108 27.764Z'
                fill='white'
              />
              <path
                d='M170.766 -1.52718C174.103 4.22358 181.457 6.17768 187.208 2.84174C192.959 -0.494195 194.913 -7.84725 191.576 -13.598C188.24 -19.3488 180.886 -21.3029 175.135 -17.9669C172.31 -16.3282 170.426 -13.7354 169.588 -10.822L133.097 -18.8329L132.568 -16.8402L169.198 -8.79222C168.957 -6.36015 169.454 -3.81714 170.766 -1.52718Z'
                fill='white'
              />
              <path
                d='M138.024 58.98L116.969 -17.555L115.203 -16.2108L136.036 59.4757C135.327 59.7353 134.627 60.0401 133.946 60.4351C128.195 63.7711 126.241 71.1241 129.578 76.8749C132.915 82.6256 140.268 84.5797 146.02 81.2438C151.771 77.9079 153.725 70.5548 150.388 64.8041C147.792 60.3596 142.823 58.1856 138.024 58.98Z'
                fill='white'
              />
              <path
                d='M97.2984 -14.2963L52.5082 36.2563C48.7517 33.21 43.3383 32.625 38.8989 35.2001C33.1477 38.536 31.1939 45.8891 34.5304 51.6398C37.867 57.3906 45.2209 59.3447 50.972 56.0087C56.7232 52.6728 58.677 45.3197 55.3405 39.569C54.9453 38.888 54.4892 38.2761 54.0025 37.6987L98.7622 -12.8193L97.2984 -14.2963Z'
                fill='white'
              />
              <path
                d='M82.6002 -12.4319L83.2649 -14.7727L42.3606 -2.5586C41.0888 -6.20333 38.0815 -9.17851 34.0757 -10.2429C27.6585 -11.9481 21.0697 -8.10944 19.3647 -1.69296C17.6597 4.72352 21.4792 11.3066 27.8964 13.0118C34.3136 14.717 40.8971 10.8983 42.6021 4.48178C43.0522 2.78799 43.1224 1.07859 42.8513 -0.550838L82.6002 -12.4319Z'
                fill='white'
              />
            </g>
            <defs>
              <clipPath id='clip0_1568_2549'>
                <rect
                  width='172.039'
                  height='123.463'
                  fill='white'
                  transform='translate(39.8853 -63.0527) rotate(18.5054)'
                />
              </clipPath>
            </defs>
          </svg>
          <button className='btn-navbar' onClick={clickHandler}>
            Back to {props.backTo || 'dashboard'}
          </button>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
